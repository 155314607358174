import axios from 'axios';

import { MACHINE_CUSTOMER_COUNTS } from './CopyMachinesDialog';

export const checkMachineCounts = async (copyDone, machineIds) => {
  if (copyDone || machineIds.length === 0) {
    return;
  }
  const response = await axios.get(MACHINE_CUSTOMER_COUNTS, {
    headers: {
      Accept: 'application/json',
    },
    params: {
      machine_ids: machineIds,
    },
  });
  const dupMachineIdsCount = response.data.machine_ids.length;
  return dupMachineIdsCount;
};
