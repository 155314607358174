import { ThemeProvider, createTheme } from '@mui/material/styles';
import { fireEvent, render, screen } from '@testing-library/react';
import React from 'react';
import { describe, expect, test, vi } from 'vitest';

import translations from '../../translations';
import PasswordInput from './PasswordInput';

const theme = createTheme();

describe('PasswordInput', () => {
  beforeAll(() => {
    translations.init();
  });

  test('renders password input and toggle button', () => {
    render(
      <ThemeProvider theme={theme}>
        <PasswordInput />
      </ThemeProvider>,
    );

    expect(screen.getByLabelText('Password')).toBeInTheDocument();
    expect(
      screen.getByTestId('toggle-password-visibility'),
    ).toBeInTheDocument();
  });

  test('toggles password visibility', () => {
    render(
      <ThemeProvider theme={theme}>
        <PasswordInput />
      </ThemeProvider>,
    );

    const passwordInput = screen.getByLabelText('Password');
    const toggleButton = screen.getByTestId('toggle-password-visibility');

    // Password should be of type password initially
    expect(passwordInput).toHaveAttribute('type', 'password');

    // Click the toggle button
    fireEvent.click(toggleButton);

    // Password should now be of type text
    expect(passwordInput).toHaveAttribute('type', 'text');

    // Click the toggle button again
    fireEvent.click(toggleButton);

    // Password should now be of type password again
    expect(passwordInput).toHaveAttribute('type', 'password');
  });

  test('prevents default behavior on mouse down', () => {
    render(
      <ThemeProvider theme={theme}>
        <PasswordInput />
      </ThemeProvider>,
    );

    const toggleButton = screen.getByTestId('toggle-password-visibility');

    const handleMouseDown = vi.fn((event) => event.preventDefault());
    toggleButton.onmousedown = handleMouseDown;

    fireEvent.mouseDown(toggleButton);

    expect(handleMouseDown).toHaveBeenCalled();
  });
});
