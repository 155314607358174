import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getCsrfToken } from '../utils/csrf';

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: window.location.origin,
    credentials: 'include',
    prepareHeaders: (headers) => {
      const csrfToken = getCsrfToken();
      if (csrfToken) {
        headers.set('X-CSRF-Token', csrfToken);
      }
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
      return headers;
    },
  }),
  endpoints: () => ({}), // Initial empty endpoints
  reducerPath: 'api',
});

export default apiSlice;
