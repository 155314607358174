import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux';
import SelectionPopover from './selection-popover';
import WindowFunction from './window-function';
import WindowSize from './window-size';

const containerSx = () => ({
  alignItems: 'center',
  border: '1px solid #E8E8E8',
  borderRadius: 30,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  height: 37,
  justifyContent: 'space-between',
  paddingLeft: '20px',
  paddingRight: '20px',
  width: 350,
});

const titleSx = () => ({
  fontFamily: 'Avenir Next',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 19.12,
});

const selectionStyle = {
  color: '#1C5BFE',
};

const chevronSx = () => ({
  color: '#1A191980',
  fontSize: 10,
});

function SampleRateDropdown() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const windowSize = useSelector(
    (state: RootState) => state.options.windowSize,
  );

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box onClick={handleClick} sx={containerSx}>
        <Typography sx={titleSx}>
          {t('sample-rate-title')}
          <span style={selectionStyle}>
            <WindowSize />
            {windowSize !== '1s' && (
              <>
                &nbsp;-&nbsp;
                <WindowFunction />
              </>
            )}
          </span>
        </Typography>
        <Box sx={chevronSx}>
          <i className="fa fa-chevron-down"></i>
        </Box>
      </Box>
      <SelectionPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
}

export default SampleRateDropdown;
