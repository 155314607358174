import redux from '../redux';

describe('redux slice: shortcut', () => {
  test('it should support the set choices method', () => {
    expect(redux.store.dispatch(redux.shortcut.setChoices([]))).toStrictEqual({
      payload: [],
      type: 'shortcut/setChoices',
    });
  });
});
