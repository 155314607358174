import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';

enum Operators {
  eq = 'eq', // Equal to
  gt = 'gt', // Greater than
  gte = 'gte', // Greater than or equal to
  ilike = 'ilike', // Like (case insensitive)
  isNotNull = 'isNotNull', // Is not null
  isNull = 'isNull', // Is null
  // notBetween = "notBetween", // Not between two values
  like = 'like', // Like (case sensitive)
  lt = 'lt', // Less than
  lte = 'lte', // Less than or equal to
  ne = 'ne', // Not equal to
  notIlike = 'notIlike', // Not like (case insensitive)
}

interface Filter<T> {
  column: keyof T;
  op: `${Operators}`;
  value: string;
}

export interface SortParam<T> {
  column: keyof T;
  sortAscending: boolean;
}

export type DataLoadProps = {
  paginationModel: GridPaginationModel;
  sortModel: GridSortModel;
};

export enum RowsPerPage {
  DEFAULT = 100,
  MAX = 500,
  ZERO = 0,
}

export enum Page {
  DEFAULT = 1,
}

export interface SearchParams<T> {
  filters?: Filter<T>[];
  page?: number;
  perPage?: number;
  projection?: (keyof T)[];
  sortBy?: SortParam<T>[];
}
