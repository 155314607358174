import { cleanup, fireEvent, render, screen } from '@testing-library/react';
import i18next from 'i18next';
import * as React from 'react';
import { Provider } from 'react-redux';

import redux from '../../redux';
import translations from '../../translations';
import ExportCsvButton from './index';

translations.init();

function TestComponent() {
  return (
    <Provider store={redux.store}>
      <ExportCsvButton />
    </Provider>
  );
}

describe('export csv button', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the export csv button', () => {
    render(<TestComponent />);
    const button = screen.getByText(i18next.t('export-csv'));
    expect(button).toBeDefined();
    const spy = vi.spyOn(redux.modal, 'open');
    fireEvent.click(button);
    expect(spy).toHaveBeenCalledTimes(1);
  });
});
