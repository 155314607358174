import { cleanup, fireEvent, render, screen } from '@testing-library/react';
import * as React from 'react';
import { Provider } from 'react-redux';

import redux from '../../../redux';
import translations from '../../../translations';
import RadioButton from './radio-button';

translations.init();

function TestComponent() {
  return (
    <Provider store={redux.store}>
      <RadioButton label="window-size-all" value="all" />
    </Provider>
  );
}

describe('radio button', () => {
  afterEach(() => {
    cleanup();
  });

  test('radio button should be displayed with the proper value and respond to click events', () => {
    // render the component
    render(<TestComponent />);

    // get reference to the radio button
    const button: HTMLInputElement = screen.getByRole('radio', { name: 'All' });

    // ensure that the reference exists
    expect(button).toBeDefined();

    // emulate user action
    fireEvent.click(button);

    // run tests
    expect(button.checked).toStrictEqual(true);
  });
});
