import * as Yup from 'yup';

export const emailValidationSchema = Yup.string()
  .email('Invalid email address')
  .required('Email is required');

export const ValidateEmail = (email: null | string | undefined): boolean => {
  try {
    emailValidationSchema.validateSync(email);
    return true;
  } catch (error) {
    return false;
  }
};

export default { ValidateEmail };
