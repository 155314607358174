import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import redux, { RootState } from '../../redux';

const checkboxSx = () => ({
  '&.Mui-checked': {
    color: '#1C5BFE',
  },
  marginBottom: '-8px',
  marginLeft: '-8px',
  marginTop: '-8px',
});

const labelSx = () => ({
  color: '#444C5B',
  fontFamily: 'Avenir Next',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '19.12px',
});

function EmailOption() {
  const { t } = useTranslation();
  const sendEmail = useSelector((state: RootState) => state.options.sendEmail);
  const dispatch = useDispatch();

  return (
    <FormControlLabel
      control={<Checkbox checked={sendEmail} sx={checkboxSx} />}
      label={<Typography sx={labelSx}>{t('send-email')}</Typography>}
      onChange={() => dispatch(redux.options.toggleSendEmail())}
      value="send-email"
    />
  );
}

export default EmailOption;
