import { Components } from '@mui/material';

import { colors } from '../colors';

const MuiLink: Components['MuiLink'] = {
  defaultProps: {
    typography: 'text-xs-medium',
    underline: 'hover',
  },
  styleOverrides: {
    root: {
      '&:hover': {
        color: colors['gray-700'],
      },
      color: colors['gray-500'],
    },
  },
};

export default MuiLink;
