import React from 'react';
import PropTypes from 'prop-types';

const ConnectionIcon = ({ width = 16, height = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M12.044 9.85a.949.949 0 0 1-.77-.398c-.055-.075-1.23-1.645-3.298-1.645-2.062 0-3.193 1.566-3.24 1.633a.942.942 0 0 1-1.333.223 1.002 1.002 0 0 1-.225-1.367c.069-.1 1.724-2.459 4.798-2.459 3.063 0 4.767 2.347 4.838 2.447.313.438.22 1.055-.206 1.377a.935.935 0 0 1-.564.19zm3-2.318a.945.945 0 0 1-.772-.402c-.103-.142-2.335-3.16-6.314-3.16S1.825 6.99 1.736 7.118a.94.94 0 0 1-1.334.229 1.002 1.002 0 0 1-.227-1.37C.287 5.816 2.965 2 7.957 2c4.982 0 7.742 3.803 7.858 3.965a1.005 1.005 0 0 1-.207 1.377.93.93 0 0 1-.564.19zm-5.1 4.394c0 1.144-.9 2.074-2.01 2.074-1.112 0-2.015-.93-2.015-2.074 0-1.143.903-2.071 2.014-2.071 1.111 0 2.011.928 2.011 2.07z"
      />
    </svg>
  );
};

ConnectionIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ConnectionIcon;
