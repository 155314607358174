import { cleanup, fireEvent, render, screen } from '@testing-library/react';
import * as React from 'react';
import { Provider } from 'react-redux';

import redux from '../../../redux';
import translations from '../../../translations';
import SaveButton from './save-button';

translations.init();

type TestComponentProps = {
  setAnchorEl: (anchorEl: HTMLDivElement | null) => void;
};

function TestComponent({ setAnchorEl }: Readonly<TestComponentProps>) {
  return (
    <Provider store={redux.store}>
      <SaveButton setAnchorEl={setAnchorEl} />
    </Provider>
  );
}

describe('save button', () => {
  afterEach(() => {
    cleanup();
  });

  test('save button should close the popover upon button click', () => {
    // setup mocked functions
    const setAnchorEl = vi.fn();
    const spyOnSetWindowSize = vi.spyOn(redux.options, 'setWindowSize');
    const spyOnSetWindowFunction = vi.spyOn(redux.options, 'setWindowFunction');

    // render the component
    render(<TestComponent setAnchorEl={setAnchorEl} />);

    // get reference to the save button
    const button = screen.getByText('Save');

    // ensure that the reference exists
    expect(button).toBeDefined();

    // emulate user action
    fireEvent.click(button);

    // run tests
    expect(setAnchorEl).toHaveBeenCalledTimes(1);
    expect(spyOnSetWindowSize).toHaveBeenCalledTimes(1);
    expect(spyOnSetWindowFunction).toHaveBeenCalledTimes(1);
  });
});
