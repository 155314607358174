import { TextField } from '@mui/material';
import { styled } from '@mui/system';

export const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '36px',
  marginTop: '36px',
});

export const Input = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  width: '100%',
}));
