import { Button } from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import redux, { RootState } from '../../redux';

const sx = () => ({
  '&:hover': {
    backgroundColor: '#1C5BFE',
  },
  backgroundColor: '#1C5BFE',
  borderRadius: '8px',
  color: '#FFFFFF',
  fontFamily: 'Avenir Next',
  fontSize: 14,
  fontWeight: 600,
  height: 35,
  textTransform: 'none',
  width: 126,
});

function ExportButton() {
  const { t } = useTranslation();
  const startDate = useSelector((state: RootState) => state.range.startDate);
  const endDate = useSelector((state: RootState) => state.range.endDate);
  const isLoading = useSelector((state: RootState) => state.api.isLoading);
  const dispatch: typeof redux.store.dispatch = useDispatch();

  const requestExport = () => {
    dispatch(redux.modal.close());
    dispatch(redux.api.requestExport());
  };

  return (
    <Button
      disabled={
        isLoading || dayjs(endDate).diff(dayjs(startDate), 'days') > 365
      }
      onClick={requestExport}
      sx={sx}
    >
      {t('export')}
    </Button>
  );
}

export default ExportButton;
