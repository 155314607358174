import { colors } from '../colors';

const button = {
  'button-danger-active-bg': colors['red-200'],
  'button-danger-bg': colors['transparent'],
  'button-danger-color': colors['red-450'],
  'button-danger-hover-bg': colors['red-100'],
  'button-disabled-color': colors['gray-500'],
  'button-primary-active-bg': colors['darkBlue-200'],
  'button-primary-bg': colors['transparent'],
  'button-primary-color': colors['blue-500'],
  'button-primary-hover-bg': colors['darkBlue-100'],
};

const checkbox = {
  'checkbox-checked-bg-color': colors['darkBlue-100'],
  'checkbox-checked-border-color': colors['darkBlue-200'],
  'checkbox-disabled-bg-color': colors['gray-500'],
  'checkbox-disabled-icon-color': colors['gray-500'],
  'checkbox-focus-bg-color': colors['dark-300'],
  'checkbox-hover-bg-color': colors['dark-300'],
  'checkbox-icon-color': colors['blue-700'],
  'checkbox-unchecked-bg-color': colors['dark-200'],
  'checkbox-unchecked-border-color': colors['dark-300'],
};

const input = {
  'input-border-focus-color': colors['blue-500'],
  'input-color': colors['gray-700'],
  'input-disabled-color': colors['gray-500'],
  'input-error-color': colors['red-400'],
  'input-error-icon-color': colors['red-500'],
  'input-error-text-color': colors['red-500'],
  'input-icon-color': colors['gray-700'],
  'input-placeholder-color': colors['gray-700'],
  'input-primary-border-color': colors['darkBlue-200'],
  'input-primary-label-color': colors['gray-700'],
  'input-secondary-border-color': colors['gray-400'],
  'input-secondary-label-color': colors['gray-450'],
};

const select = {
  'select-bg-color': colors['darkBlue-100'],
  'select-color': colors['gray-700'],
  'select-disabled-border-color': colors['darkBlue-200'],
  'select-disabled-color': colors['gray-500'],
  'select-error-border-color': colors['red-400'],
  'select-focus-border-color': colors['blue-500'],
  'select-hover-bg-color': colors['darkBlue-200'],
  'select-option-bg-color': colors['darkBlue-100'],
  'select-option-color': colors['gray-700'],
  'select-state-bg-color': colors['white'],
};

export const lightMode = {
  ...button,
  ...checkbox,
  ...input,
  ...select,
};
