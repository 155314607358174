import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import redux from '../../../export-csv/redux';

function BannerManager() {
  const dispatch = useDispatch();

  useEffect(() => {
    const bannerContainer =
      document.getElementsByClassName('alert-box-wrapper')[0];

    const bannerExists = document.getElementsByClassName('alert-box')[0];

    if (!bannerExists) {
      const bannerClose = document.createElement('a');
      bannerClose.href = '#';
      bannerClose.className = 'close';
      bannerClose.textContent = 'x';

      const banner = document.createElement('div');
      banner.className = 'alert-box success';
      banner.setAttribute('data-alert', 'true');

      const bannerContent = document.createElement('span');
      bannerContent.textContent = 'test';

      bannerClose.onclick = () => {
        banner.style.display = 'none';
        banner.className = 'alert-box';
        bannerContent.textContent = '';
        dispatch(redux.banner.hide());
      };

      banner.appendChild(bannerContent);
      banner.appendChild(bannerClose);
      bannerContainer?.append(banner);

      banner.style.display = 'none';
    }
  }, []);

  return null;
}

export default BannerManager;
