import React, { MouseEventHandler, ReactNode } from 'react';

interface RadioGroupOptionProps {
  additionalClassNames?: string[];
  checked: boolean;
  children: ReactNode;
  onClick: MouseEventHandler<HTMLSpanElement>;
}

const RadioGroupOption: React.FC<RadioGroupOptionProps> = ({
  additionalClassNames = [],
  checked,
  children,
  onClick,
}) => (
  <span
    className={[...additionalClassNames, 'radio'].join(' ')}
    onClick={onClick}
  >
    <label className={`${checked ? 'checked' : ''}`}>{children}</label>
  </span>
);

export default RadioGroupOption;
