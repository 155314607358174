import { faker } from '@faker-js/faker';

import translateColumnName from './translate-column-name';

describe('translate column name', () => {
  it('should return created at', () => {
    expect(translateColumnName('createdAt')).toStrictEqual('created_at');
  });

  it('should return status', () => {
    expect(translateColumnName('status')).toStrictEqual('status');
  });

  it('should return the same value', () => {
    const word = faker.word.words(1);
    expect(translateColumnName(word)).toStrictEqual(word);
  });
});
