import { cleanup, render, screen } from '@testing-library/react';
import * as React from 'react';

import translations from '../../translations';
import Next from './next';

translations.init();

function TestComponent() {
  return <Next />;
}

describe('next icon', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the next icon', () => {
    render(<TestComponent />);
    const svg = screen.getByTestId('next');

    expect(svg).toBeDefined();
    expect(svg.firstChild.nodeName.toLowerCase()).toBe('path');
  });
});
