import { cleanup, render, screen } from '@testing-library/react';
import i18next from 'i18next';
import * as React from 'react';

import translations from '../../translations';
import Title from './index';

translations.init();

function TestComponent() {
  return <Title />;
}

describe('title', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the title, properly translated', () => {
    render(<TestComponent />);
    const button = screen.getByText(i18next.t('files-and-exports-uppercase'));
    expect(button).toBeDefined();
  });
});
