import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import redux from '../../redux';

type BannerManagerProps = {
  machineName: string;
};

function BannerManager({ machineName }: BannerManagerProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    const bannerContainer =
      document.getElementsByClassName('alert-box-wrapper')[0];

    const bannerExists = document.getElementsByClassName('alert-box')[0];

    if (!bannerExists) {
      const banner = document.createElement('div');
      banner.className = 'alert-box success';
      banner.setAttribute('data-alert', 'true');

      bannerContainer?.append(banner);

      banner.style.display = 'none';
    }
  }, []);

  useEffect(() => {
    dispatch(redux.banner.setMachineName(machineName));
  }, [machineName]);

  return null;
}

export default BannerManager;
