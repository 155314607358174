import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en';
import * as React from 'react';
import { Provider } from 'react-redux';

import MuiXLicense from '../licensing/MuiXLicense';
import redux from './redux';
import translations from './translations';
import SampleRateDropdown from './views/sample-rate-dropdown';
import SyncManager from './views/sync-manager';

translations.init();

type ExportCSVContainerProps = {
  samplesManagedMachinePath: string;
};

const SampleRateSelector = (props: ExportCSVContainerProps) => {
  return (
    <>
      <Provider store={redux.store}>
        <LocalizationProvider adapterLocale="en" dateAdapter={AdapterDayjs}>
          <>
            <SampleRateDropdown />
            <SyncManager {...props} />
          </>
        </LocalizationProvider>
      </Provider>
      <MuiXLicense />
    </>
  );
};

export default SampleRateSelector;
