import { faker } from '@faker-js/faker';

import redux from '../../redux';

describe('redux slice: banner', () => {
  test('it should support the set host method', () => {
    const host = faker.internet.url();
    expect(redux.store.dispatch(redux.api.setHost(host))).toStrictEqual({
      payload: host,
      type: 'api/setHost',
    });
  });
});
