import axios from 'axios';
import { beforeEach, describe, expect, it, vi } from 'vitest';

import { CUSTOMER_MANAGED_MACHINES } from './CopyMachinesDialog';
import { handleCopyMachines } from './handleCopyMachines';

vi.mock('axios');

describe('handleCopyMachines', () => {
  const selectedCustomer = { id: 1, label: 'Customer 1' };
  const machineIds = [1, 2, 3];
  const setNewMachines = vi.fn();
  const setErrorMessage = vi.fn();
  const setSelectedCustomer = vi.fn();
  const setCopyDone = vi.fn();

  beforeEach(() => {
    vi.clearAllMocks();
  });

  it('should call axios with correct parameters and set new machines', async () => {
    vi.spyOn(axios, 'post').mockImplementation(async () => ({
      data: {
        managed_machines_created: machineIds,
      },
    }));

    await handleCopyMachines(
      selectedCustomer,
      machineIds,
      setNewMachines,
      setErrorMessage,
      setSelectedCustomer,
      setCopyDone,
    );

    expect(axios.post).toHaveBeenCalledWith(
      CUSTOMER_MANAGED_MACHINES(selectedCustomer.id),
      { managed_machine_ids: machineIds },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    expect(setNewMachines).toHaveBeenCalledWith(machineIds);
    expect(setSelectedCustomer).toHaveBeenCalledWith(null);
    expect(setCopyDone).toHaveBeenCalledWith(true);
  });

  it('should set error message on failure', async () => {
    const error = new Error('Request failed');

    vi.spyOn(axios, 'post').mockRejectedValueOnce(error);

    await handleCopyMachines(
      selectedCustomer,
      machineIds,
      setNewMachines,
      setErrorMessage,
      setSelectedCustomer,
      setCopyDone,
    );

    expect(setErrorMessage).toHaveBeenCalledWith({
      message: error.message,
      severity: 'error',
    });
    expect(setSelectedCustomer).toHaveBeenCalledWith(null);
    expect(setCopyDone).toHaveBeenCalledWith(true);
  });
});
