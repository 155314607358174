import dataLoadToRoute from './data-load-to-route';
import routeToDataLoad from './route-to-data-load';
import translateColumnName from './translate-column-name';

const utils = {
  dataLoadToRoute,
  routeToDataLoad,
  translateColumnName,
};

export default utils;
