import * as React from 'react';
import { useTranslation } from 'react-i18next';

type StatusProps = {
  value: string;
};

function Status({ value }: Readonly<StatusProps>) {
  const { t } = useTranslation();
  return <span data-testid="status">{t(`status.${value}`)}</span>;
}

export default Status;
