import { SensorTypeSetting } from '@interfaces/index';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ValidBooleanValue {
  label: string;
  value: boolean;
}

export interface AlertRuleForm {
  validBooleanValues: ValidBooleanValue[];
  validSensorTypeSettings: SensorTypeSetting[];
}

const initialState: AlertRuleForm = {
  validBooleanValues: [],
  validSensorTypeSettings: [],
};

export const alertRuleFormSlice = createSlice({
  initialState,
  name: 'alertRuleForm',
  reducers: {
    setValidBooleanValues: (
      state,
      action: PayloadAction<ValidBooleanValue[]>,
    ) => {
      state.validBooleanValues = action.payload;
    },
    setValidSensorTypeSettings: (
      state,
      action: PayloadAction<SensorTypeSetting[]>,
    ) => {
      state.validSensorTypeSettings = action.payload;
    },
  },
});

export const { setValidBooleanValues, setValidSensorTypeSettings } =
  alertRuleFormSlice.actions;

export default alertRuleFormSlice.reducer;
