import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import redux from '../../redux';

type HostManagerProps = {
  host: string;
};

function HostManager({ host }: HostManagerProps) {
  const dispatch: typeof redux.store.dispatch = useDispatch();

  useEffect(() => {
    dispatch(redux.api.setHost(host));
  }, [host]);

  return null;
}

export default HostManager;
