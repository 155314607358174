import axios from 'axios';

import { getCsrfToken } from '../../../utils/csrf';
import { CUSTOMER_MANAGED_MACHINES } from './CopyMachinesDialog';

export const handleCopyMachines = async (
  selectedCustomer,
  machineIds,
  setNewMachines,
  setErrorMessage,
  setSelectedCustomer,
  setCopyDone,
) => {
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    const csrfToken = getCsrfToken();
    if (csrfToken) {
      headers['X-CSRF-Token'] = csrfToken;
    }

    const response = await axios.post(
      CUSTOMER_MANAGED_MACHINES(selectedCustomer.id),
      { managed_machine_ids: machineIds },
      {
        headers,
      },
    );
    setNewMachines(response.data.managed_machines_created);
  } catch (error) {
    setErrorMessage({ message: error.message, severity: 'error' });
  }

  setSelectedCustomer(null);
  setCopyDone(true);
};
