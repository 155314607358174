import { Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';

interface IfConditionTextProps {
  i18nKey: string;
  values?: Record<string, boolean | number | string | undefined>;
}

const IfConditionText: React.FC<IfConditionTextProps> = ({
  i18nKey,
  values,
}) => (
  <Typography variant="text-xs">
    <Trans
      components={{
        1: <Typography className={'sensor'} variant="text-xs" />,
      }}
      i18nKey={i18nKey}
      ns={'alertRuleSensorTypes'}
      values={values}
    />
  </Typography>
);

export default IfConditionText;
