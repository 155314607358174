const updateHiddenField = (
  fieldId: string,
  value: boolean | null | number | object | string,
) => {
  const element = document.getElementById(fieldId) as HTMLInputElement | null;
  if (element) {
    element.value = JSON.stringify(value);
  }
};

export default updateHiddenField;
