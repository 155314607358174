import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const ArrowLeftIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 17">
    <g id="arrow-left">
      <path
        d="M12.6663 8.62382H3.33301M3.33301 8.62382L7.99967 13.2905M3.33301 8.62382L7.99967 3.95715"
        id="Icon"
        stroke="#7C828C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
);

export default ArrowLeftIcon;
