import { GridColDef } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import * as React from 'react';

import DownloadButton from '../views/download-button';
import Status from '../views/status';

const columns: GridColDef[] = [
  { field: 'name', flex: 1, headerName: 'columns.file', sortable: false },
  {
    field: 'dateRange',
    flex: 1,
    headerName: 'columns.date-range',
    sortable: false,
    valueGetter: (_value, row) => {
      return `${dayjs(row.dateStart).format('MMMM D, YYYY')} - ${dayjs(
        row.dateEnd,
      ).format('MMMM D, YYYY')}`;
    },
  },
  {
    field: 'createdAt',
    flex: 1,
    headerName: 'columns.run-time',
  },
  {
    field: 'userFullName',
    flex: 1,
    headerName: 'columns.user',
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'columns.status',
    renderCell: (params) => {
      return <Status value={params.value} />;
    },
  },
  {
    align: 'center',
    field: 'id',
    headerAlign: 'center',
    headerName: 'columns.download',
    renderCell: (params) => {
      return (
        <DownloadButton
          active={params.row.status === 'done'}
          id={params.value}
        />
      );
    },
    sortable: false,
  },
];

export default columns;
