import React from 'react';
import PropTypes from 'prop-types';

const NewIcon = ({ width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9" stroke="#444C5B" strokeWidth="2" />
      <rect x="6" y="9" width="8" height="2" fill="#444C5B" />
      <rect
        x="9"
        y="14"
        width="8"
        height="2"
        transform="rotate(-90 9 14)"
        fill="#444C5B"
      />
    </svg>
  );
};

NewIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default NewIcon;
