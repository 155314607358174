// Alert Types
export const ALERT_TYPE_THRESHOLD = 'threshold';
export const ALERT_TYPE_CONNECTION = 'connection';
export const ALERT_TYPE_POWER = 'power';
export const ALERT_TYPE_ERROR = 'error';

// Delivery Types
export const DELIVERY_TYPE_EMAIL = 1;
export const DELIVERY_TYPE_SMS = 2;
export const DELIVERY_TYPE_APP = 3;
export const DELIVERY_TYPE_VOICE_CALL = 4;

export const DEFAULT_ESCALATION_DELAY = 60;

export const ALERT_SENSOR_TYPE_CODES = {
  ALL_MINUS_THRESHOLD: [
    'connection',
    'gateway_connection',
    'gateway_cell',
    'gateway_power',
    'battery',
    'thermocouple',
  ],
  BATTERY: 'battery',
  BOOLEAN: ['dry_contact_closed'],
  CONNECTION: ['connection', 'gateway_connection', 'gateway_cell'],
  CONNECTION_NO_CELL: ['connection', 'gateway_connection'],
  ERROR: 'thermocouple',
  GATEWAY_CELL: 'gateway_cell',
  GATEWAY_CONNECTION: 'gateway_connection',
  GATEWAY_POWER: 'gateway_power',
  POWER: ['gateway_power', 'battery'],
};

// Alert Delay Ranges
export const DEFAULT_ALERT_DELAY_IN_MINUTES = 60;
export const DEFAULT_CONNECTION_SET_DELAY_IN_MINUTES = 60;
export const DEFAULT_GATEWAY_CELL_SET_DELAY_IN_MINUTES = 30;
export const DEFAULT_GATEWAY_CONNECTION_DELAY_IN_MINUTES = 60;
export const DEFAULT_GATEWAY_POWER_DELAY_IN_MINUTES = 20;
export const DEFAULT_DRY_CONTACT_CLOSED_DELAY_IN_SECONDS = 30;

export const ALERT_DELAY_RANGES = {
  co2: {
    minutes: {
      default: DEFAULT_ALERT_DELAY_IN_MINUTES,
      max: 1440,
      min: 1,
    },
  },
  connection: {
    minutes: {
      default: DEFAULT_CONNECTION_SET_DELAY_IN_MINUTES,
      max: 1440,
      min: 20,
    },
  },
  default: {
    minutes: {
      default: DEFAULT_ALERT_DELAY_IN_MINUTES,
      max: 1440,
      min: 1,
    },
  },
  dry_contact_closed: {
    minutes: {
      default: 30,
      max: 60,
      min: 0,
    },
    seconds: {
      default: DEFAULT_DRY_CONTACT_CLOSED_DELAY_IN_SECONDS,
      max: 45,
      min: 15,
      step: 15,
    },
  },
  gateway_cell: {
    minutes: {
      default: DEFAULT_GATEWAY_CELL_SET_DELAY_IN_MINUTES,
      max: 60,
      min: 20,
    },
  },
  gateway_connection: {
    minutes: {
      default: DEFAULT_GATEWAY_CONNECTION_DELAY_IN_MINUTES,
      max: 60,
      min: 20,
    },
  },
  gateway_power: {
    minutes: {
      default: DEFAULT_GATEWAY_POWER_DELAY_IN_MINUTES,
      max: 60,
      min: 1,
    },
  },
  hall_max: {
    minutes: {
      default: DEFAULT_ALERT_DELAY_IN_MINUTES,
      max: 1440,
      min: 1,
    },
  },
  humidity: {
    minutes: {
      default: DEFAULT_ALERT_DELAY_IN_MINUTES,
      max: 1440,
      min: 1,
    },
  },
  light: {
    minutes: {
      default: DEFAULT_ALERT_DELAY_IN_MINUTES,
      max: 1440,
      min: 1,
    },
  },
  o2: {
    minutes: {
      default: DEFAULT_ALERT_DELAY_IN_MINUTES,
      max: 1440,
      min: 1,
    },
  },
  pressure: {
    minutes: {
      default: DEFAULT_ALERT_DELAY_IN_MINUTES,
      max: 1440,
      min: 1,
    },
  },
  speed: {
    minutes: {
      default: DEFAULT_ALERT_DELAY_IN_MINUTES,
      max: 5000,
      min: 0,
    },
  },
  temperature: {
    minutes: {
      default: DEFAULT_ALERT_DELAY_IN_MINUTES,
      max: 1440,
      min: 1,
    },
  },
};
export type AlertDelayRangeKey = keyof typeof ALERT_DELAY_RANGES;
