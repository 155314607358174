import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const PressureIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M15.372 4.887a7.968 7.968 0 0 0-1.715-2.543A7.967 7.967 0 0 0 8 0a7.956 7.956 0 0 0-5.656 2.343A7.982 7.982 0 0 0 0 8a7.962 7.962 0 0 0 2.343 5.657A7.969 7.969 0 0 0 8 16a7.974 7.974 0 0 0 5.657-2.343A7.982 7.982 0 0 0 16 8c0-1.08-.211-2.127-.628-3.113zm-4.268-2.649l-.819 1.608-.265-.136.819-1.607.265.135zM9.88 1.731l.284.093-.558 1.715-.283-.092.557-1.716zm-1.003-.217l.294.047-.282 1.782-.294-.047.282-1.782zm-1.14-.485h.528v2.24h-.529v-2.24zm-.613.485l.283 1.782-.294.047L6.83 1.56l.294-.047zm-1.004.218l.558 1.715-.283.092-.558-1.715.283-.092zm-.957.371l.819 1.607-.266.136-.819-1.608.266-.135zm-.888.516l1.06 1.46-.24.175-1.06-1.46.24-.175zM1.021 8.264v-.528h2.24v.528h-2.24zm2.275-.858l-1.781-.282.046-.294 1.781.282-.046.294zm.151-.728L1.732 6.12l.092-.283 1.715.557-.092.284zm.263-.696l-1.607-.82.135-.265 1.607.82-.135.265zm.369-.647l-1.46-1.06.176-.241 1.459 1.06-.175.241zm-.81-1.856l.21-.21 1.275 1.275-.21.21L3.268 3.48zm1.449 1.579l.373-.373 2.612 2.611a.717.717 0 1 1-.373.374L4.718 5.058zm6.188-.804l-.24-.176 1.06-1.459.24.175-1.06 1.46zm.34.29l1.276-1.276.21.21-1.275 1.276-.21-.21zm.501.55l1.46-1.06.175.241-1.459 1.06-.176-.24zm.409.622l1.608-.819.135.266-1.607.819-.136-.266zm.306.678l1.716-.557.092.283-1.716.558-.092-.284zm.196.718l1.782-.282.046.294-1.78.282-.048-.294zm.075 1.152v-.528h2.24v.528h-2.24z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default PressureIcon;
