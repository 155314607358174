import { Components } from '@mui/material';

const MuiIconButton: Components['MuiIconButton'] = {
  defaultProps: {
    disableFocusRipple: true,
    disableRipple: true,
  },
};

export default MuiIconButton;
