// replacement for deprecated String.substr
const substr = (s: string, start: number, length?: number) => {
  const size = s.length;
  let intStart = start;
  if (intStart === -Infinity) {
    intStart = 0;
  } else if (intStart < 0) {
    intStart = Math.max(size + intStart, 0);
  } else {
    intStart = Math.min(intStart, size);
  }
  let intLength = length ?? size;
  intLength = Math.max(Math.min(intLength, size), 0);
  let intEnd = Math.min(intStart + intLength, size);
  return s.substring(intStart, intEnd);
};

const getJsonFromUrl = (query = '') => {
  const result = {};

  const process = (part: string) => {
    part = part.split('+').join(' '); // + to space, regexp-free version
    const eq = part.indexOf('=');
    let key = eq > -1 ? substr(part, 0, eq) : part;
    const val = eq > -1 ? decodeURIComponent(substr(part, eq + 1)) : '';
    const from = key.indexOf('[');
    if (from === -1) {
      result[decodeURIComponent(key)] = val;
    } else {
      const to = key.indexOf(']', from);
      const index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) {
        result[key] = [];
      }
      if (!index) {
        result[key].push(val);
      } else {
        result[key][index] = val;
      }
    }
  };

  query.split('&').forEach((part: string | undefined) => {
    if (!part) {
      return;
    }
    process(part);
  });

  return result;
};

export default getJsonFromUrl;
