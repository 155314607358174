import { render, screen } from '@testing-library/react';
import React from 'react';
import { Provider } from 'react-redux';
import { beforeAll, describe, expect, it } from 'vitest';

import alertRuleFactory from '../../../factories/alertRuleFactory';
import sensorTypeSettingFactory from '../../../factories/sensorTypeSettingFactory';
import { setAlertRule } from '../../../features/alertRules';
import translations from '../../../translations';
import store from '../store';
import SensorTypeSettingsGroup from './SensorTypeSettingsGroup';

describe('SensorTypeSettingsGroup', () => {
  beforeAll(() => {
    translations.init();
  });

  const setup = () => {
    render(
      <Provider store={store}>
        <SensorTypeSettingsGroup />
      </Provider>,
    );
  };

  it('renders BatterySensorTypeSettingForm for battery typeCode', () => {
    const customAlertRule = alertRuleFactory.batteryAlert().build();
    store.dispatch(setAlertRule(customAlertRule));
    setup();

    expect(screen.getByText('Battery')).toBeInTheDocument();
  });

  it('renders BooleanSensorTypeSettingForm for boolean typeCode', () => {
    const customAlertRule = alertRuleFactory.booleanAlert().build();
    store.dispatch(setAlertRule(customAlertRule));
    setup();

    expect(screen.getByText(/is in/i)).toBeInTheDocument(); // Specific to boolean form
  });

  it('renders SensorTypeSettingForm for non-threshold sensor types', () => {
    const customAlertRule = alertRuleFactory.gatewayConnectionAlert().build();
    store.dispatch(setAlertRule(customAlertRule));
    setup();

    expect(screen.getByText(/Gateway/i)).toBeInTheDocument();
  });

  it('renders ThresholdSensorTypeSettingForm for threshold sensor types', () => {
    const customAlertRule = alertRuleFactory.build();
    store.dispatch(setAlertRule(customAlertRule));
    setup();

    expect(screen.getByText(/for longer than/i)).toBeInTheDocument();
  });

  it('renders multiple sensor type forms correctly', () => {
    const customAlertRule = alertRuleFactory.build({
      sensorTypeSettings: [
        sensorTypeSettingFactory.build(),
        sensorTypeSettingFactory.battery().build(),
      ],
    });

    store.dispatch(setAlertRule(customAlertRule));
    setup();

    expect(screen.getAllByText(/Battery/i)[0]).toBeInTheDocument();
    expect(screen.getByText(/Temperature/i)).toBeInTheDocument();
  });
});
