import IframeBridge, {
  IframeGuestToHostCommandPayload,
} from '@elemental/iframe-bridge';

import redux from '../redux';

const onStartupError = (
  dispatch: typeof redux.store.dispatch,
  errorMessage: string,
) => {
  dispatch(
    redux.guestApp.startupError(
      `Unexpected error while loading the application: ${errorMessage}`,
    ),
  );
};

const WebAppMessaging = {
  adapter: IframeBridge.HostAdapter({
    baseURL: window.location.href,
  }),
  onGuestMessageReceived: (
    dispatch: typeof redux.store.dispatch,
    payload: IframeGuestToHostCommandPayload,
  ) => {
    const { message } = payload;
    const { cmd } = message;
    console.log(`received command ${cmd} from guest app`);
  },
  onStartupError,
};

export default WebAppMessaging;
