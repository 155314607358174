import { RadioGroup, Stack } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import redux, { RootState } from '../../../redux';
import RadioButton from './radio-button';

function WindowSizeRadioGroup() {
  const windowSize = useSelector((state: RootState) => state.form.windowSize);
  const dispatch = useDispatch();

  return (
    <RadioGroup
      aria-labelledby="window-size-radio-group"
      name="window-size-radio-group"
      onChange={(e) => dispatch(redux.form.setWindowSize(e.target.value))}
      value={windowSize}
    >
      <Stack direction="row">
        <Stack direction="column" flexGrow={1} gap="14px" pl="10px">
          <RadioButton label="window-size-all" value="1s" />
          <RadioButton label="window-size-15m" value="15m" />
          <RadioButton label="window-size-1d" value="1d" />
        </Stack>
        <Stack direction="column" flexGrow={1} gap="14px">
          <RadioButton label="window-size-1m" value="1m" />
          <RadioButton label="window-size-1h" value="1h" />
        </Stack>
      </Stack>
    </RadioGroup>
  );
}

export default WindowSizeRadioGroup;
