import { render, screen } from '@testing-library/react';
import React from 'react';
import { beforeEach, describe, it, vi } from 'vitest';

import alertRuleFactory from '../../factories/alertRuleFactory';
import sensorTypeSettingFactory from '../../factories/sensorTypeSettingFactory';
import { ValidBooleanValue } from '../../features/alertRules';
import { AlertRule, SensorTypeSetting } from '../../interfaces';
import SensorSettingsContainer from './SensorSettingsContainer';
import store from './store';

describe('SensorSettingsContainer', () => {
  let mockAlertRule: AlertRule;
  let mockValidBooleanValues: ValidBooleanValue[];
  let mockValidSensorTypeSettings: SensorTypeSetting[];
  let onSpy = vi.fn();
  let offSpy = vi.fn();

  beforeEach(() => {
    // Mock jQuery
    global.$ = vi.fn(() => ({
      off: offSpy,
      on: onSpy,
    }));

    mockAlertRule = alertRuleFactory.build();
    mockValidBooleanValues = [{ label: 'Alarm', value: false }];
    mockValidSensorTypeSettings = [
      ...mockAlertRule.sensorTypeSettings,
      sensorTypeSettingFactory.boolean().build(),
      sensorTypeSettingFactory.gatewayConnection().build(),
      sensorTypeSettingFactory.battery().build(),
    ];
  });

  const setup = () =>
    render(
      <SensorSettingsContainer
        alertRule={mockAlertRule}
        validBooleanValues={mockValidBooleanValues}
        validSensorTypeSettings={mockValidSensorTypeSettings}
      />,
    );

  it('renders the component and its children correctly', () => {
    setup();

    expect(screen.getByText('Settings')).toBeInTheDocument();
    expect(screen.getByTestId('sensor-type-toggle-group')).toBeInTheDocument();
    expect(
      screen.getByTestId('sensor-type-settings-group'),
    ).toBeInTheDocument();
  });

  it('dispatches actions when alertRule, validBooleanValues, or validSensorTypeSettings are passed as props', () => {
    const dispatchSpy = vi.spyOn(store, 'dispatch');

    setup();

    expect(dispatchSpy).toHaveBeenCalledWith({
      payload: mockAlertRule,
      type: 'alertRule/setAlertRule',
    });

    expect(dispatchSpy).toHaveBeenCalledWith({
      payload: mockValidBooleanValues,
      type: 'alertRuleForm/setValidBooleanValues',
    });

    expect(dispatchSpy).toHaveBeenCalledWith({
      payload: mockValidSensorTypeSettings,
      type: 'alertRuleForm/setValidSensorTypeSettings',
    });
  });

  it('registers and cleans up the alertRuleFormLoaded event listener', () => {
    const { unmount } = setup();

    expect(onSpy).toHaveBeenCalledWith(
      'alertRuleFormLoaded',
      expect.any(Function),
    );

    unmount();

    expect(offSpy).toHaveBeenCalledWith(
      'alertRuleFormLoaded',
      expect.any(Function),
    );
  });
});
