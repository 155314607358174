import { createSlice } from '@reduxjs/toolkit';

type RangeState = {
  endDate: null | string;
  error: boolean;
  startDate: null | string;
};

const initialState: RangeState = {
  endDate: null,
  error: false,
  startDate: null,
};

const slice = createSlice({
  initialState,
  name: 'range',
  reducers: {
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setRangeError: (state, action) => {
      state.error = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
  },
});

const range = {
  reducer: slice.reducer,
  setEndDate: slice.actions.setEndDate,
  setRangeError: slice.actions.setRangeError,
  setStartDate: slice.actions.setStartDate,
};

export default range;
