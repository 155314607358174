import { FormControlLabel, Radio, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type RadioButtonProps = {
  label: string;
  value: string;
};

const radioSx = () => ({
  '&.Mui-checked': {
    color: '#1C5BFE',
  },
  marginBottom: '-8px',
  marginLeft: '-8px',
  marginRight: '14px',
  marginTop: '-8px',
});

const labelSx = () => ({
  color: '#444C5B',
  fontFamily: 'Avenir Next',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '19.12px',
});

function RadioButton({ label, value }: Readonly<RadioButtonProps>) {
  const { t } = useTranslation();
  return (
    <FormControlLabel
      control={<Radio data-testid="radio" sx={radioSx} />}
      label={<Typography sx={labelSx}>{t(label)}</Typography>}
      value={value}
    />
  );
}

export default RadioButton;
