import { fireEvent, render, screen } from '@testing-library/react';
import React from 'react';
import { describe, expect, it, vi } from 'vitest';

import SelectInput from './SelectInput';

describe('SelectInput', () => {
  const mockOnChange = vi.fn();
  const mockOptions = [
    { key: '1', label: 'Option 1', value: '1' },
    { key: '2', label: 'Option 2', value: '2' },
    { key: '3', label: 'Option 3', value: '3' },
  ];
  const mockValue = '1';

  const setup = (props = {}) => {
    render(
      <SelectInput
        dataTestId="select-input"
        onChange={mockOnChange}
        options={mockOptions}
        value={mockValue}
        {...props}
      />,
    );
  };

  it('renders the select input with the correct value', () => {
    setup();
    const selectInput = screen.getByTestId('select-input') as HTMLSelectElement;

    expect(selectInput).toBeInTheDocument();
    expect(selectInput.value).toBe(mockValue);
  });

  it('renders the correct number of options', () => {
    setup();
    const selectInput = screen.getByTestId('select-input') as HTMLSelectElement;

    expect(selectInput.options.length).toBe(mockOptions.length);
  });

  it('renders the correct option labels and values', () => {
    setup();
    mockOptions.forEach((option) => {
      const optionElement = screen.getByText(option.label) as HTMLOptionElement;

      expect(optionElement).toBeInTheDocument();
      expect(optionElement.value).toBe(option.value.toString());
    });
  });

  it('calls onChange when a different option is selected', () => {
    setup();
    const selectInput = screen.getByTestId('select-input') as HTMLSelectElement;

    fireEvent.change(selectInput, { target: { value: '2' } });

    expect(mockOnChange).toHaveBeenCalledTimes(1);
  });

  it('applies the custom styles correctly', () => {
    setup();
    const selectInput = screen.getByTestId('select-input');

    expect(selectInput).toHaveStyle({
      paddingBottom: '0',
      paddingRight: '20px',
      paddingTop: '0',
    });
  });
});
