import { Box, styled } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import redux, { RootState } from '../../redux';
import utils from '../../utils';

dayjs.extend(utc);
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(advancedFormat);
import Icons from '../icons';

const sx = {
  '& .MuiDataGrid-cell': {
    color: '#1A1919',
    fontFamily: 'Avenir Next',
    fontSize: '13px',
    fontWeight: 400,
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: 'rgba(28, 91, 254, 0.1)',
    color: '#1A1919',
    fontFamily: 'Avenir Next',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '17.76px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row.even': {
    backgroundColor: 'rgba(249, 249, 249, 1)',
  },
  '& .MuiDataGrid-row.odd': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  '& .MuiDataGrid-row:hover': {
    '& .MuiDataGrid-cell': {
      color: '#1C5BFE',
      fontWeight: 500,
    },
    backgroundColor: 'rgba(245, 247, 255, 1)',
  },
  '& .MuiTablePagination-displayedRows': {
    color: 'rgba(68, 76, 91, 1)',
    fontFamily: 'Avenir Next',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
  '.MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted)': {
    '.MuiDataGrid-sortIcon': {
      opacity: '0.5',
    },
  },
  '.MuiDataGrid-iconButtonContainer': {
    visibility: 'visible',
  },
  border: 0,
};

const StyledGridOverlay = styled('div')(() => ({
  alignItems: 'center',
  backgroundColor: 'rgb(250, 250, 250)',
  color: 'rgb(74, 72, 72)',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Avenir Next',
  fontSize: '13px',
  height: '100%',
  justifyContent: 'flex-start',
  lineHeight: '14.95px',
}));

function NoRowsOverlay() {
  const { t } = useTranslation();
  return (
    <StyledGridOverlay>
      <Box sx={{ marginTop: '15px' }}>{t('no-data-available-in-table')}</Box>
    </StyledGridOverlay>
  );
}

type TableProps = {
  active: boolean;
};

const Table = ({ active }: TableProps) => {
  const { t } = useTranslation();
  const isBanner = useSelector((state: RootState) => !state.banner.hidden);
  const isLoading = useSelector((state: RootState) => state.api.isLoading);
  const columns = useSelector((state: RootState) => state.data.columns);
  const paginationModel = useSelector(
    (state: RootState) => state.data.paginationModel,
  );
  const rowCount = useSelector((state: RootState) => state.data.rowCount);
  const sortModel = useSelector((state: RootState) => state.data.sortModel);
  const records = useSelector((state: RootState) => state.data.records);
  const timezone = useSelector((state: RootState) => state.data.userTimeZone);
  const dispatch: typeof redux.store.dispatch = useDispatch();

  useEffect(() => {
    if (active) {
      const dataLoad = utils.routeToDataLoad({
        paginationModel,
        sortModel,
      });
      dispatch(redux.data.setPaginationModel(dataLoad.paginationModel));
      dispatch(redux.data.setSortModel(dataLoad.sortModel));
      dispatch(redux.api.getPaginatedReports());
    }
  }, [active]);

  // this is required to avoid rendering the table when the selected tab is not the current one
  if (!active) {
    return null;
  }

  return (
    <div
      data-testid="container"
      style={{
        height: isBanner ? 'calc(100vh - 304px)' : 'calc(100vh - 232px)',
        marginBottom: -40,
        width: '100%',
      }}
    >
      <DataGridPro
        columns={columns.map((column) => ({
          ...column,
          headerName:
            column.field === 'createdAt'
              ? `${t(column.headerName)} (${dayjs().tz(timezone).format('z')})`
              : t(column.headerName),
        }))}
        disableColumnMenu
        disableColumnPinning
        disableColumnReorder
        disableRowSelectionOnClick
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        loading={isLoading}
        onPaginationModelChange={(paginationModel) => {
          dispatch(redux.data.setPaginationModel(paginationModel));

          utils.dataLoadToRoute({
            paginationModel,
            sortModel,
          });

          dispatch(redux.api.getPaginatedReports());
        }}
        onSortModelChange={(e) => {
          const newSortModel = e;

          if (newSortModel.length === 0) {
            const sortOption = { ...sortModel[0] };

            if (sortOption.sort === 'asc') {
              sortOption.sort = 'desc';
            } else {
              sortOption.sort = 'asc';
            }

            newSortModel.push(sortOption);
          }

          dispatch(redux.data.setSortModel(newSortModel));

          utils.dataLoadToRoute({
            paginationModel,
            sortModel: newSortModel,
          });
          // write more dispatch statements here to update sorting
          // in the get paginated reports api request
          dispatch(redux.api.getPaginatedReports());
        }}
        pageSizeOptions={[]}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={rowCount}
        rows={records}
        slots={{
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          columnSortedAscendingIcon: ({ sortingOrder, ...other }) => (
            <Icons.SortUp {...other} />
          ),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          columnSortedDescendingIcon: ({ sortingOrder, ...other }) => (
            <Icons.SortDown {...other} />
          ),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          columnUnsortedIcon: ({ sortingOrder, ...other }) => (
            <Icons.Unsorted {...other} />
          ),
          noRowsOverlay: NoRowsOverlay,
        }}
        sortModel={sortModel}
        sortingMode="server"
        sx={sx}
      />
    </div>
  );
};

export default Table;
