import React from 'react';
import PropTypes from 'prop-types';

const ErrorIcon = ({ width = 18, height = 18 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M9 18A9 9 0 1 0 9 0a9 9 0 0 0 0 18zm1.002-7.714H7.998l-.284-6.429h2.572l-.284 6.429zM7.714 12.86c0-.185.03-.357.092-.514a1.176 1.176 0 0 1 .66-.679c.157-.064.334-.097.53-.097s.374.033.533.097a1.168 1.168 0 0 1 .665.68c.061.156.092.328.092.513 0 .186-.03.356-.092.51a1.168 1.168 0 0 1-.665.674c-.159.066-.337.098-.533.098s-.373-.032-.53-.097a1.176 1.176 0 0 1-.66-.675 1.377 1.377 0 0 1-.092-.51z"
      />
    </svg>
  );
};

ErrorIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ErrorIcon;
