import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface AlertDialogProps {
  dataTestId?: string;
  message: string;
  onClose: () => void;
  open: boolean;
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  dataTestId,
  message,
  onClose,
  open,
}) => {
  const { t } = useTranslation('alertRuleSensorTypes');

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      data-testid={dataTestId}
      onClose={onClose}
      open={open}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button data-testid="alert-dialog-close-button" onClick={onClose}>
          {t('general.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
