import { cleanup, fireEvent, render, screen } from '@testing-library/react';
import * as React from 'react';
import { Provider } from 'react-redux';

import redux from '../../redux';
import translations from '../../translations';
import CancelButton from './cancel-button';

translations.init();

function TestComponent() {
  return (
    <Provider store={redux.store}>
      <CancelButton />
    </Provider>
  );
}

describe('cancel button', () => {
  afterEach(() => {
    cleanup();
  });

  test('cancel button should close the modal upon button click', () => {
    render(<TestComponent />);
    const button = screen.getByText('Cancel');
    expect(button).toBeDefined();
    const spy = vi.spyOn(redux.modal, 'close');
    fireEvent.click(button);
    expect(spy).toHaveBeenCalledTimes(1);
  });
});
