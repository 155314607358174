import { faker } from '@faker-js/faker';

import redux from '../redux';

describe('redux slice: options', () => {
  const testData = faker.word.words(1);

  test('it should support the set window function method', () => {
    expect(
      redux.store.dispatch(redux.options.setWindowFunction(testData)),
    ).toStrictEqual({
      payload: testData,
      type: 'options/setWindowFunction',
    });
  });

  test('it should support the set window size method', () => {
    expect(
      redux.store.dispatch(redux.options.setWindowSize(testData)),
    ).toStrictEqual({
      payload: testData,
      type: 'options/setWindowSize',
    });
  });

  test('it should support the toggle send email method', () => {
    expect(redux.store.dispatch(redux.options.toggleSendEmail())).toStrictEqual(
      {
        payload: undefined,
        type: 'options/toggleSendEmail',
      },
    );
  });
});
