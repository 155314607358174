import { createTheme } from '@mui/material/styles';

import {
  MuiAlert,
  MuiButton,
  MuiFormHelperText,
  MuiIconButton,
  MuiLink,
  MuiTextField,
} from './mui';
import { MuiToggleButton } from './muiLegacy';
import { palette } from './palette';
import space from './space';
import typography from './typography';

const emTheme = createTheme({
  breakpoints: {
    values: {
      lg: 1280, // Not used in this case, same as md
      md: 1280, // Desktop start
      sm: 744, // Tablet start
      xl: 1920, // Large desktop or beyond
      xs: 0, // Mobile
    },
  },
  components: {
    MuiAlert,
    MuiButton,
    MuiFormHelperText,
    MuiIconButton,
    MuiLink,
    MuiTextField,
    MuiToggleButton,
  },
  palette,
  spacing: (factor: number) => space[factor],
  typography: {
    ...typography,
    body1: {
      MozOsxFontSmoothing: 'auto',
      WebkitFontSmoothing: 'auto',
      lineHeight: 1.15,
    },
    fontFamily: ['Avenir Next', 'Montserrat', 'Sans-Serif'].join(','),
  },
});

export default emTheme;
