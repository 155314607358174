import { SensorTypeSetting } from '@interfaces/sensorTypeSetting';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  setSensorTypeSettings,
  setSensorTypes,
} from '../../../features/alertRules';
import typography from '../../../theme/typography';
import sensorTypeIcons, { SensorTypeKey } from '../../icons/sensorTypes';
import AlertDialog from '../partials/AlertDialog';
import { RootState } from '../store';

const SensorTypeButton: React.FC<{ sensorTypeSetting: SensorTypeSetting }> = ({
  sensorTypeSetting,
}) => {
  const IconComponent =
    sensorTypeIcons[sensorTypeSetting.typeCode as SensorTypeKey];

  return (
    <ToggleButton
      aria-label={sensorTypeSetting.name}
      data-testid={`sensor-type-button-${sensorTypeSetting.typeCode}`}
      key={sensorTypeSetting.id}
      sx={{
        backgroundColor: '#F2F2F2',
        ...typography['text-xs-bold'],
        fontSize: '13px',
      }}
      value={sensorTypeSetting.typeCode}
    >
      {IconComponent && (
        <IconComponent
          sx={{ height: '16px', marginRight: '10px', width: '16px' }}
        />
      )}
      {sensorTypeSetting.name}{' '}
      {sensorTypeSetting.unit ? ` (${sensorTypeSetting.unit})` : ''}
    </ToggleButton>
  );
};

const SensorTypesSelection: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('alertRuleSensorTypes');
  const selectedSensorTypes = useSelector(
    (state: RootState) => state.alertRule.sensorTypes,
  );
  const existingSensorTypeSettings = useSelector(
    (state: RootState) => state.alertRule.sensorTypeSettings,
  );
  const validSensorTypeSettings = useSelector(
    (state: RootState) => state.alertRuleForm.validSensorTypeSettings,
  );

  const [open, setOpen] = useState(false);

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleSensorTypeChange = (
    _event: React.MouseEvent<HTMLElement>,
    newSelectedSensorTypes: string[],
  ) => {
    if (newSelectedSensorTypes.length === 0) {
      setOpen(true); // Prevent deselecting the last option by showing a dialog
    } else {
      dispatch(setSensorTypes(newSelectedSensorTypes));

      // Update sensor type settings based on the selected types
      const updatedSettings = existingSensorTypeSettings
        .filter((setting) => newSelectedSensorTypes.includes(setting.typeCode)) // Keep only settings for selected types
        .concat(
          newSelectedSensorTypes
            .filter(
              (typeCode) =>
                !existingSensorTypeSettings.some(
                  (setting) => setting.typeCode === typeCode,
                ),
            ) // Add settings for newly selected types
            .map(
              (typeCode) =>
                validSensorTypeSettings.find(
                  (setting) => setting.typeCode === typeCode,
                )!,
            ),
        );

      dispatch(setSensorTypeSettings(updatedSettings));
    }
  };

  useEffect(() => {
    if (validSensorTypeSettings.length > 0) {
      // Filter selectedSensorTypes to keep only those that exist in validSensorTypeSettings
      const validSelectedSensorTypes = selectedSensorTypes.filter((typeCode) =>
        validSensorTypeSettings.some(
          (setting) => setting.typeCode === typeCode,
        ),
      );

      if (validSelectedSensorTypes.length === 0) {
        // No common sensor types, reset to default
        const defaultSensorType = validSensorTypeSettings[0].typeCode;
        dispatch(setSensorTypes([defaultSensorType]));

        const defaultSetting = validSensorTypeSettings.find(
          (setting) => setting.typeCode === defaultSensorType,
        );
        if (defaultSetting) {
          dispatch(setSensorTypeSettings([defaultSetting]));
        }
      } else if (
        validSelectedSensorTypes.length !== selectedSensorTypes.length
      ) {
        // Some selectedSensorTypes were invalid, update to only valid ones
        dispatch(setSensorTypes(validSelectedSensorTypes));

        const updatedSettings = validSelectedSensorTypes
          .map((typeCode) =>
            validSensorTypeSettings.find(
              (setting) => setting.typeCode === typeCode,
            ),
          )
          .filter(Boolean);

        dispatch(setSensorTypeSettings(updatedSettings as SensorTypeSetting[]));
      }
    }
  }, [selectedSensorTypes, validSensorTypeSettings, dispatch]);

  return (
    <Box>
      <ToggleButtonGroup
        aria-label="sensor type selection"
        data-testid="sensor-type-toggle-group"
        exclusive={false}
        onChange={handleSensorTypeChange}
        sx={{ display: 'flex', gap: '12px' }}
        value={selectedSensorTypes || []}
      >
        {validSensorTypeSettings.map((sensorTypeSetting) => (
          <SensorTypeButton
            key={sensorTypeSetting.id}
            sensorTypeSetting={sensorTypeSetting}
          />
        ))}
      </ToggleButtonGroup>
      <AlertDialog
        dataTestId="sensor-type-alert-dialog"
        message={t('errors.atLeastOneMustBeSelected')}
        onClose={handleDialogClose}
        open={open}
      />
    </Box>
  );
};

export default SensorTypesSelection;
