import { FormHelperText } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux';

const messageStyles = {
  fontFamily: 'Avenir Next',
  fontSize: '14px',
  fontWeight: '500',
};

function ValidationMessage() {
  const { t } = useTranslation();
  const rangeError = useSelector((state: RootState) => state.range.error);

  if (rangeError) {
    return (
      <FormHelperText error style={messageStyles}>
        {t('daterange-error')}
      </FormHelperText>
    );
  }
  return null;
}

export default ValidationMessage;
