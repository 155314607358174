import { RadioGroup, Stack } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import redux, { RootState } from '../../../redux';
import RadioButton from './radio-button';

function WindowFunctionRadioGroup() {
  const windowFunction = useSelector(
    (state: RootState) => state.form.windowFunction,
  );
  const dispatch = useDispatch();

  return (
    <RadioGroup
      aria-labelledby="window-function-radio-group"
      name="window-function-radio-group"
      onChange={(e) => dispatch(redux.form.setWindowFunction(e.target.value))}
      value={windowFunction}
    >
      <Stack direction="row">
        <Stack direction="column" flexGrow={1} gap="14px" pl="10px">
          <RadioButton label="window-function-first" value="first" />
          <RadioButton label="window-function-average" value="mean" />
          <RadioButton label="window-function-maximum" value="max" />
        </Stack>
        <Stack direction="column" flexGrow={1} gap="14px">
          <RadioButton label="window-function-last" value="last" />
          <RadioButton label="window-function-minimum" value="min" />
        </Stack>
      </Stack>
    </RadioGroup>
  );
}

export default WindowFunctionRadioGroup;
