import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';

const setContent = createAsyncThunk(
  'banner/set-content',
  async (data: string) => {
    const banner = document.getElementsByClassName('alert-box')[0];

    if (banner) {
      banner.innerHTML = '';

      const bannerClose = document.createElement('a');
      bannerClose.href = '#';
      bannerClose.className = 'close';
      bannerClose.textContent = 'x';

      const bannerContent = document.createElement('span');
      bannerContent.textContent = data;

      bannerClose.onclick = () => {
        banner.style.display = 'none';
        banner.className = 'alert-box';
        bannerContent.textContent = '';
      };
      
      const reportsLink = document.createElement('a');
      
      reportsLink.href = '/reports?tab=file-and-exports-section';
      reportsLink.textContent = i18next.t('reports-uppercase');
      bannerContent.innerHTML = bannerContent.innerHTML.replace(
        '{{reports}}',
        reportsLink.outerHTML,
      );

      banner.appendChild(bannerContent);
      banner.appendChild(bannerClose);
    }

    return data;
  },
);

export default setContent;
