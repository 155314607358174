import { configureStore } from '@reduxjs/toolkit';

import apiSlice from '../../api/apiSlice';
import { authReducer } from '../../features/auth';
import { notificationReducer } from '../../features/notifications';

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    notification: notificationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
