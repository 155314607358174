import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { addNotificationMessage } from '../features/notifications';
import '../styles/index.css';
import emTheme from '../theme/emTheme';
import translations from '../translations';
import AppRoutes from './AppRoutes';
import store from './login/store';

translations.init();

interface AppProps {
  alert?: string;
  notice?: string;
}

const InitializeNotifications: React.FC<{
  alert?: string;
  notice?: string;
}> = ({ alert, notice }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (alert) {
      dispatch(addNotificationMessage({ message: alert, severity: 'error' }));
    }
    if (notice) {
      dispatch(addNotificationMessage({ message: notice, severity: 'info' }));
    }
  }, [alert, notice, dispatch]);

  return null;
};

const App: React.FC<AppProps> = ({ alert, notice }) => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={emTheme}>
          <CssBaseline />
          <InitializeNotifications alert={alert} notice={notice} />
          <AppRoutes />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
