import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SensorTypeSetting } from '../../../interfaces';
import DelayInputField from './DelayInputField';
import IfConditionText from './IfConditionText';
import SensorLimitField from './SensorLimitField';
import useSensorTypeSetting from './useSensorTypeSetting';
import useSetDelayField from './useSetDelayField';

interface SensorTypeSettingFormProps {
  setting: SensorTypeSetting;
}

const ThresholdSensorTypeSettingForm: React.FC<SensorTypeSettingFormProps> = ({
  setting,
}) => {
  const { t } = useTranslation('alertRuleSensorTypes');

  const { maxDelay, minDelay, showSetDelay } = useSetDelayField(setting);

  const { handleValueChange } = useSensorTypeSetting(setting);

  return (
    <Grid
      alignItems="center"
      container
      direction="row"
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item md={3} sm={6} xs={12}>
        <IfConditionText
          i18nKey={'messages.generic.if'}
          values={{ alert_type: setting.name }}
        />
      </Grid>
      <SensorLimitField
        color="#F42626"
        dataTestId={`higher-limit-field-${setting.typeCode}`}
        label={t('messages.alertConditions.isAbove')}
        onChange={(value) =>
          handleValueChange('higherLimit', parseFloat(value))
        }
        unit={setting.unit}
        value={setting.higherLimit || ''}
      />
      <SensorLimitField
        color="#1446AE"
        dataTestId={`lower-limit-field-${setting.typeCode}`}
        label={t('messages.alertConditions.isBelow')}
        onChange={(value) => handleValueChange('lowerLimit', parseFloat(value))}
        unit={setting.unit}
        value={setting.lowerLimit || ''}
      />
      {showSetDelay && (
        <Grid
          alignItems="center"
          container
          gap={'5px'}
          item
          md={'auto'}
          sm={6}
          xs={12}
        >
          <Typography variant="text-xs">
            {t('messages.alertConditions.forLongerThan')}
          </Typography>
          <DelayInputField
            dataTestId={`set-delay-field-${setting.typeCode}`}
            max={maxDelay}
            min={minDelay}
            onChange={(e) =>
              handleValueChange('setDelay', parseFloat(e.target.value))
            }
            value={setting.setDelay || 0}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ThresholdSensorTypeSettingForm;
