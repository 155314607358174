import { useDispatch } from 'react-redux';

import { updateSensorTypeSetting } from '../../../features/alertRules';
import { SensorTypeSetting } from '../../../interfaces';

const useSensorTypeSetting = (setting: SensorTypeSetting) => {
  const dispatch = useDispatch();

  const handleValueChange = (key: keyof SensorTypeSetting, value: any) => {
    dispatch(
      updateSensorTypeSetting({ key, typeCode: setting.typeCode, value }),
    );
  };

  return { handleValueChange };
};

export default useSensorTypeSetting;
