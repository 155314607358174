import redux from '../redux';

describe('redux', () => {
  test('it should have proper object properties', () => {
    expect(redux).toHaveProperty('api');
    expect(redux).toHaveProperty('data');
    expect(redux).toHaveProperty('store');
    expect(redux.store).toHaveProperty('getState');
    expect(redux.store).toHaveProperty('dispatch');
  });
});
