import { Controller } from 'stimulus';

const ALERT_REPORT = 'AlertReport';
const DATA_HISTORY_REPORT = 'DataHistoryReport';

export default class extends Controller {
	static targets = ['name', 'type', 'reportTypeModal'];

	initialize = () => {
		// Need to re-load previouslySelectedType since the page
		// will be re-loaded when a type selection is made
		this.typeTargets.some((typeTarget) => {
			if (typeTarget.checked) {
				this.previouslySelectedType = typeTarget.value;
				return true;
			}
			return false;
		});
		// selectedType does not need to persist therefore only initializing it here
		this.selectedType = '';
	};

	set previouslySelectedType(type) {
		this.data.set('previously-selected-type', type);
	}

	get previouslySelectedType() {
		return this.data.get('previously-selected-type');
	}

	selectReportType(e) {
		this.previouslySelectedType = this.selectedType;
		const name = this.nameTarget.value;
		const paramString = new URLSearchParams({ name: name }).toString();
		const url = `${this.newReportUrl()}?${paramString}`;
		// e.target.id is empty for the modal button
		const $targetButton = e.target.id
			? $(`label[for='${e.target.id}']`)
			: $(e.target).closest('a');
		const previousHtml = $targetButton.html();
		$targetButton.html(
			`<i class='fa fa-spinner fa-pulse fa-fw'/> ${I18n.t(
				'reports.index.loading',
			)}`,
		);
		$.ajax({
			cache: false,
			url: url,
			success: (html) => {
				let template = document.createElement('template');
				template.innerHTML = html.trim();
				this.element.parentElement.replaceChild(
					template.content.firstChild,
					this.element,
				);
				// Only needed for the non-stimulus page
				this.setupJs(this.selectedType);
			},
			complete: () => {
				$targetButton.html(previousHtml);
			},
		});
	}

	// TODO Wondering if there's a way to do this without explicit URLs, but...
	// TODO Do this with a structure
	newReportUrl() {
		switch (this.selectedType) {
			case ALERT_REPORT:
				return Routes.new_alert_report_path();
			case DATA_HISTORY_REPORT:
				return Routes.new_data_history_report_path();
		}
	}

	// We only need this for the non-stimulus page, would be nice to get rid of...
	// TODO Do this with a structure
	setupJs() {
		switch (this.selectedType) {
			case DATA_HISTORY_REPORT:
				new DataHistoryReport();
				break;
		}
	}

	handleTypeClick(e) {
		this.selectedType = e.target.value;
		// Only show the modal when another type has been previously selected
		if (this.previouslySelectedType) {
			if (this.previouslySelectedType !== this.selectedType) {
				this.reportTypeModalTarget.classList.add('show');
			}
		} else {
			this.selectReportType(e);
		}
	}

	cancelTypeChange(e) {
		this.typeTargets.forEach((typeTarget) => {
			typeTarget.checked = typeTarget.value === this.previouslySelectedType;
		});
		this.reportTypeModalTarget.classList.remove('show');
	}
}
