import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import redux, { RootState } from '../../redux';
import BannerManager from '../banner-manager';
import Table from '../table';
import Title from '../title';

type TabContentProps = {
  active: boolean;
  host: string;
  timezone: string;
};

const TabContent = (props: TabContentProps) => {
  const { active, host, timezone } = props;
  const dispatch: typeof redux.store.dispatch = useDispatch();
  const currentHost = useSelector((state: RootState) => state.api.host);
  useEffect(() => {
    dispatch(redux.data.setUserTimeZone(timezone));
    dispatch(redux.api.setHost(host));
  }, []);
  return (
    <>
      <BannerManager />
      <Title />
      <Table active={active && !!currentHost} />
    </>
  );
};

export default TabContent;
