import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const ErrorIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <circle
      cx="12"
      cy="12"
      fill="none"
      r="8"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      clipRule="evenodd"
      d="M12.0001 13.7545C11.4478 13.7545 11.0001 13.3068 11.0001 12.7545V8.25012C11.0001 7.69784 11.4478 7.25012 12.0001 7.25012C12.5524 7.25012 13.0001 7.69784 13.0001 8.25012V12.7545C13.0001 13.3068 12.5524 13.7545 12.0001 13.7545Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <circle cx="12" cy="16" fill="currentColor" r="1" />
  </SvgIcon>
);

export default ErrorIcon;
