const parseSubdomains = (hostname: string) => {
  const subdomains = hostname.split('.').slice(0, -2); // Exclude TLD and domain
  let individual, branch, channel, component;

  switch (subdomains.length) {
    case 1:
      component = subdomains[0];
      break;
    case 2:
      [channel, component] = subdomains;
      break;
    case 3:
      [branch, channel, component] = subdomains;
      break;
    case 4:
      [individual, branch, channel, component] = subdomains;
      break;
    default:
      // If there are more than four subdomains, ignore the extras and take only the last four
      [individual, branch, channel, component] = subdomains.slice(-4);
      break;
  }

  return { branch, channel, component, individual };
};

export default parseSubdomains;
