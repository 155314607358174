import { Components } from '@mui/material';

import { colors } from '../../colors';
import typography from '../../typography';
import CustomFormHelperText from './CustomFormHelperText';

export const MuiFormHelperText: Components['MuiFormHelperText'] = {
  defaultProps: {
    component: CustomFormHelperText,
  },
  styleOverrides: {
    root: {
      ...typography['text-xxs'],
      color: colors['red-500'],
      marginTop: '8px',
    },
  },
};
