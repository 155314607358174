import { faker } from '@faker-js/faker';

import redux from '../redux';

describe('redux slice: data', () => {
  test('it should support the set columns method', () => {
    expect(redux.store.dispatch(redux.data.setColumns([]))).toStrictEqual({
      payload: [],
      type: 'data/setColumns',
    });
  });

  test('it should support the set records method', () => {
    expect(redux.store.dispatch(redux.data.setRecords([]))).toStrictEqual({
      payload: [],
      type: 'data/setRecords',
    });
  });

  test('it should support the set sort model method', () => {
    expect(redux.store.dispatch(redux.data.setSortModel([]))).toStrictEqual({
      payload: [],
      type: 'data/setSortModel',
    });
  });

  test('it should support the set row count method', () => {
    const rowCount = faker.number.int();
    expect(
      redux.store.dispatch(redux.data.setRowCount(rowCount)),
    ).toStrictEqual({
      payload: rowCount,
      type: 'data/setRowCount',
    });
  });

  test('it should support the set pagination model method', () => {
    expect(
      redux.store.dispatch(
        redux.data.setPaginationModel({ page: 0, pageSize: 100 }),
      ),
    ).toStrictEqual({
      payload: { page: 0, pageSize: 100 },
      type: 'data/setPaginationModel',
    });
  });
});
