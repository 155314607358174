import { Box, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Routes } from '../../routes';
import { colors } from '../../theme/colors';
import EmLogo from '../icons/EmLogo';
import NotificationMessages from '../notification-messages/NotificationMessages';
import EmailForm from './EmailForm';
import EmailPasswordForm from './EmailPasswordForm';
import { RootState } from './store';

const getBackgroundImageUrl = () => {
  // Access the data attribute for the background image URL
  const loginPageElement = document.getElementById('login-page');
  return loginPageElement ? loginPageElement.dataset.backgroundImageUrl : '';
};

const LoginPage: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();
  const [isSignIn, setIsSignIn] = useState(false);
  const [email, setEmail] = useState('');
  const { t } = useTranslation('login');

  const handleEmailSubmit = async (values: { email: string }) => {
    setEmail(values.email);
    setIsSignIn(true);
  };

  const handleBack = () => {
    setIsSignIn(false);
  };

  React.useEffect(() => {
    if (user) {
      navigate(Routes.HOME);
    }
  }, [user, navigate]);

  return (
    <Box data-testid="login-page" display="flex" height="100vh" width="100vw">
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: colors.white,
          boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          position: 'relative',
          width: '420px',
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            alignItems: 'flex-start',
            padding: '100px 72px 0 72px',
            width: '100%',
          }}
        >
          <EmLogo
            data-testid="logo"
            sx={{
              alignSelf: 'center',
              height: 'auto',
              marginBottom: '96px',
              width: '100%',
            }}
            titleAccess={t('elementalMachines')}
          />
          <Typography
            color={colors.black}
            data-testid="sign-in-title"
            variant="text-xl-demi-bold"
          >
            {t('signIn')}
          </Typography>
          {!isSignIn ? (
            <EmailForm onSubmit={handleEmailSubmit} />
          ) : (
            <EmailPasswordForm email={email} onBack={handleBack} />
          )}
          <NotificationMessages width={'100%'} />
        </Box>

        <Box
          sx={{
            color: colors['gray-500'],
            display: 'inline-flex',
            gap: '11px',
            marginBottom: '51px',
          }}
        >
          <Link
            data-testid="terms-link"
            href="https://elementalmachines.com/terms-and-conditions"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('termsOfService')}
          </Link>
          <Link
            data-testid="privacy-link"
            href="https://elementalmachines.com/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('privacyPolicy')}
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${getBackgroundImageUrl()})`,
          backgroundPosition: 'top right',
          backgroundSize: 'cover',
          flexGrow: 1,
          position: 'relative',
          zIndex: 1,
        }}
      />
    </Box>
  );
};

export default LoginPage;
