const fontStyles = {
  body: 'normal',
};

const fontWeights = {
  bold: 700,
  demibold: 600,
  medium: 500,
  normal: 400,
};

const fonts = {
  bold: 'Avenir Next Bold',
  demibold: 'Avenir Next Demi Bold',
  medium: 'Avenir Next Medium',
  normal: 'Avenir Next Regular',
};

// Custom typography options
const typography = {
  'text-l': {
    fontFamily: fonts.normal,
    fontSize: '24px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.normal,
    lineHeight: '40px',
  },
  'text-l-bold': {
    fontFamily: fonts.bold,
    fontSize: '24px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.bold,
    lineHeight: '40px',
  },
  'text-l-demi-bold': {
    fontFamily: fonts.demibold,
    fontSize: '24px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.demibold,
    lineHeight: '40px',
  },
  'text-l-medium': {
    fontFamily: fonts.medium,
    fontSize: '24px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.medium,
    lineHeight: '40px',
  },
  'text-m': {
    fontFamily: fonts.normal,
    fontSize: '18px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.normal,
    lineHeight: '24px',
  },
  'text-m-bold': {
    fontFamily: fonts.bold,
    fontSize: '18px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.bold,
    lineHeight: '24px',
  },
  'text-m-demi-bold': {
    fontFamily: fonts.demibold,
    fontSize: '18px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.demibold,
    lineHeight: '24px',
  },
  'text-m-medium': {
    fontFamily: fonts.medium,
    fontSize: '18px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.medium,
    lineHeight: '24px',
  },
  'text-s': {
    fontFamily: fonts.normal,
    fontSize: '16px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.normal,
    lineHeight: '20px',
  },
  'text-s-bold': {
    fontFamily: fonts.bold,
    fontSize: '16px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.bold,
    lineHeight: '20px',
  },
  'text-s-demi-bold': {
    fontFamily: fonts.demibold,
    fontSize: '16px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.demibold,
    lineHeight: '20px',
  },
  'text-s-medium': {
    fontFamily: fonts.medium,
    fontSize: '16px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.medium,
    lineHeight: '20px',
  },
  'text-xl': {
    fontFamily: fonts.normal,
    fontSize: '32px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.normal,
    lineHeight: '40px',
  },
  'text-xl-bold': {
    fontFamily: fonts.bold,
    fontSize: '32px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.bold,
    lineHeight: '40px',
  },
  'text-xl-demi-bold': {
    fontFamily: fonts.demibold,
    fontSize: '32px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.demibold,
    lineHeight: '40px',
  },
  'text-xl-medium': {
    fontFamily: fonts.medium,
    fontSize: '32px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.medium,
    lineHeight: '40px',
  },
  'text-xs': {
    fontFamily: fonts.normal,
    fontSize: '14px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.normal,
    lineHeight: '18px',
  },
  'text-xs-bold': {
    fontFamily: fonts.bold,
    fontSize: '14px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.bold,
    lineHeight: '18px',
  },
  'text-xs-bold-caps': {
    fontFamily: fonts.bold,
    fontSize: '14px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.bold,
    lineHeight: '18px',
    textTransform: 'uppercase' as const,
  },
  'text-xs-caps': {
    fontFamily: fonts.normal,
    fontSize: '14px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.normal,
    lineHeight: '18px',
    textTransform: 'uppercase' as const,
  },
  'text-xs-demi-bold': {
    fontFamily: fonts.demibold,
    fontSize: '14px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.demibold,
    lineHeight: '18px',
  },
  'text-xs-demi-bold-caps': {
    fontFamily: fonts.demibold,
    fontSize: '14px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.demibold,
    lineHeight: '18px',
    textTransform: 'uppercase' as const,
  },
  'text-xs-medium': {
    fontFamily: fonts.medium,
    fontSize: '14px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.medium,
    lineHeight: '18px',
  },
  'text-xs-medium-caps': {
    fontFamily: fonts.medium,
    fontSize: '14px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.medium,
    lineHeight: '18px',
    textTransform: 'uppercase' as const,
  },
  'text-xxl': {
    fontFamily: fonts.normal,
    fontSize: '40px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.normal,
    lineHeight: '48px',
  },
  'text-xxl-bold': {
    fontFamily: fonts.bold,
    fontSize: '40px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.bold,
    lineHeight: '48px',
  },
  'text-xxl-demi-bold': {
    fontFamily: fonts.demibold,
    fontSize: '40px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.demibold,
    lineHeight: '48px',
  },
  'text-xxl-medium': {
    fontFamily: fonts.medium,
    fontSize: '40px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.medium,
    lineHeight: '48px',
  },
  'text-xxs': {
    fontFamily: fonts.normal,
    fontSize: '12px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.normal,
    lineHeight: '16px',
  },
  'text-xxs-bold': {
    fontFamily: fonts.bold,
    fontSize: '12px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.bold,
    lineHeight: '16px',
  },
  'text-xxs-bold-caps': {
    fontFamily: fonts.bold,
    fontSize: '12px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.bold,
    lineHeight: '16px',
    textTransform: 'uppercase' as const,
  },
  'text-xxs-caps': {
    fontFamily: fonts.normal,
    fontSize: '12px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.normal,
    lineHeight: '16px',
    textTransform: 'uppercase' as const,
  },
  'text-xxs-demi-bold': {
    fontFamily: fonts.demibold,
    fontSize: '12px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.demibold,
    lineHeight: '16px',
  },
  'text-xxs-demi-bold-caps': {
    fontFamily: fonts.demibold,
    fontSize: '12px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.demibold,
    lineHeight: '16px',
    textTransform: 'uppercase' as const,
  },
  'text-xxs-medium': {
    fontFamily: fonts.medium,
    fontSize: '12px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.medium,
    lineHeight: '16px',
  },
  'text-xxs-medium-caps': {
    fontFamily: fonts.medium,
    fontSize: '12px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.medium,
    lineHeight: '16px',
    textTransform: 'uppercase' as const,
  },
  'text-xxxl': {
    fontFamily: fonts.normal,
    fontSize: '56px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.normal,
    lineHeight: '64px',
  },
  'text-xxxl-bold': {
    fontFamily: fonts.bold,
    fontSize: '56px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.bold,
    lineHeight: '64px',
  },
  'text-xxxl-demi-bold': {
    fontFamily: fonts.demibold,
    fontSize: '56px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.demibold,
    lineHeight: '64px',
  },
  'text-xxxl-medium': {
    fontFamily: fonts.medium,
    fontSize: '56px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.medium,
    lineHeight: '64px',
  },
  'text-xxxs-bold-caps': {
    fontFamily: fonts.bold,
    fontSize: '10px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.bold,
    lineHeight: '12px',
    textTransform: 'uppercase' as const,
  },
  'text-xxxs-caps': {
    fontFamily: fonts.normal,
    fontSize: '10px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.normal,
    lineHeight: '12px',
    textTransform: 'uppercase' as const,
  },
  'text-xxxs-demi-bold-caps': {
    fontFamily: fonts.demibold,
    fontSize: '10px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.demibold,
    lineHeight: '12px',
    textTransform: 'uppercase' as const,
  },
  'text-xxxs-medium-caps': {
    fontFamily: fonts.medium,
    fontSize: '10px',
    fontStyle: fontStyles.body,
    fontWeight: fontWeights.medium,
    lineHeight: '12px',
    textTransform: 'uppercase' as const,
  },
};

export default typography;
