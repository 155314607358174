import Download from './download';
import Next from './next';
import Previous from './previous';
import SortDown from './sort-down';
import SortUp from './sort-up';
import Unsorted from './unsorted';

const Icons = {
  Download,
  Next,
  Previous,
  SortDown,
  SortUp,
  Unsorted,
};

export default Icons;
