import { faker } from '@faker-js/faker';

import redux from '../index';

describe('redux slice: banner', () => {
  beforeEach(() => {
    const banner = document.createElement('div');
    banner.className = 'alert-box';
    const span = document.createElement('span');
    banner.appendChild(span);
    document.body.appendChild(banner);
  });

  test('it should support the show method', () => {
    expect(redux.store.dispatch(redux.banner.show())).toStrictEqual({
      payload: undefined,
      type: 'banner/show',
    });
  });

  test('it should support the hide method', () => {
    expect(redux.store.dispatch(redux.banner.hide())).toStrictEqual({
      payload: undefined,
      type: 'banner/hide',
    });
  });

  test('it should support the set severity method', () => {
    expect(
      redux.store.dispatch(redux.banner.setSeverity('success')),
    ).toStrictEqual({
      payload: 'success',
      type: 'banner/setSeverity',
    });
  });

  test('it should support the set machine name method', () => {
    const machineName = faker.word.words(3);

    expect(
      redux.store.dispatch(redux.banner.setMachineName(machineName)),
    ).toStrictEqual({
      payload: machineName,
      type: 'banner/setMachineName',
    });
  });

  test('it should support the set content method', async () => {
    const text = faker.word.words(3);

    await redux.store.dispatch(redux.banner.setContent(text));

    setTimeout(async () => {
      expect(redux.store.getState().banner.content).toEqual(text);
    }, 500);
  });
});
