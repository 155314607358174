import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const ShowPasswordIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6C8.04003 6 5.01115 8.27449 2.4755 11.3974C2.19014 11.7488 2.19014 12.2512 2.4755 12.6026C5.01115 15.7255 8.04003 18 12 18C15.96 18 18.9889 15.7255 21.5245 12.6026C21.8099 12.2512 21.8099 11.7488 21.5245 11.3974C18.9889 8.27449 15.96 6 12 6Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        d="M14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5C13.3807 9.5 14.5 10.6193 14.5 12Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
    </svg>
  </SvgIcon>
);

export default ShowPasswordIcon;
