const blue = {
  'blue-100': '#EDF8FE',
  'blue-200': '#E4EDF8',
  'blue-300': '#D2E7FC',
  'blue-400': '#ADD2F9',
  'blue-450': '#67A1F4',
  'blue-500': '#1C5BFE', // Elemental blue
  'blue-700': '#003AE7',
};

const darkBlue = {
  'darkBlue-100': '#F3F5FA',
  'darkBlue-200': '#E7EBF4',
  'darkBlue-300': '#CFD7E9',
  'darkBlue-400': '#B7C3DE',
  'darkBlue-450': '#869BC8',
  'darkBlue-500': '#5672B2',
  'darkBlue-700': '#0E3691',
};

const dark = {
  'dark-100': '#F4F4F4',
  'dark-200': '#E8E8E8',
  'dark-300': '#D1D1D1',
  'dark-400': '#BABABA',
  'dark-450': '#8C8C8C',
  'dark-500': '#5F5E5E',
  'dark-700': '#1A1919',
};

const gray = {
  'gray-100': '#ECEDEF',
  'gray-200': '#DADBDE',
  'gray-300': '#C7C9CE',
  'gray-400': '#B4B7BD',
  'gray-450': '#A1A5AD',
  'gray-500': '#7C828C',
  'gray-700': '#444C5B',
};

const yellow = {
  'yellow-100': '#FEF6E4',
  'yellow-200': '#FBDC9D',
  'yellow-300': '#F5B632',
  'yellow-400': '#E99E00',
  'yellow-450': '#D7960D',
  'yellow-500': '#C18300',
  'yellow-700': '#885E06',
};

const red = {
  'red-100': '#FCF3F2',
  'red-200': '#FCECEB',
  'red-300': '#F6BFBE',
  'red-400': '#EF6572',
  'red-450': '#F04F5E',
  'red-500': '#F1273A',
  'red-700': '#E90808',
};

const green = {
  'green-100': '#EAF8F1',
  'green-200': '#E0F4EA',
  'green-300': '#99DBB8',
  'green-400': '#5DC68F',
  'green-450': '#1EBD6A',
  'green-500': '#1B9957',
  'green-700': '#0C8345',
};

const purple = {
  'purple-100': '#F0F0FC',
  'purple-200': '#E4E2FC',
  'purple-300': '#B1B2F7',
  'purple-400': '#4649EE',
  'purple-450': '#2528D7',
  'purple-500': '#0E11AD',
  'purple-700': '#07097B',
};

const lightBlue = {
  'lightBlue-100': '#E5F4FD',
  'lightBlue-200': '#CAEAFA',
  'lightBlue-300': '#96D5F4',
  'lightBlue-400': '#4AB7EC',
  'lightBlue-450': '#1E9BD7',
  'lightBlue-500': '#1485BB',
  'lightBlue-700': '#01618F',
};

const orange = {
  'orange-100': '#FFEDE3',
  'orange-200': '#FFC6A5',
  'orange-300': '#FFA168',
  'orange-400': '#F57E37',
  'orange-450': '#EA7129',
  'orange-500': '#DA5B0F',
  'orange-700': '#B44808',
};

export const alphas = {
  'white-alpha-10': 'rgba(255, 255, 255, 0.1)',
  'white-alpha-20': 'rgba(255, 255, 255, 0.2)',
  'white-alpha-30': 'rgba(255, 255, 255, 0.3)',
  'white-alpha-40': 'rgba(255, 255, 255, 0.4)',
  'white-alpha-60': 'rgba(255, 255, 255, 0.6)',
  'white-alpha-80': 'rgba(255, 255, 255, 0.8)',
};

export const palettes = {
  ...blue,
  ...darkBlue,
  ...dark,
  ...gray,
  ...yellow,
  ...red,
  ...green,
  ...purple,
  ...lightBlue,
  ...orange,
};

export const colors = {
  ...alphas,
  ...palettes,
  black: 'rgba(0, 0, 0, 1)',
  transparent: 'transparent',
  white: 'rgba(255, 255, 255, 1)',
};
