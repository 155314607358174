import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { cleanup, render, screen } from '@testing-library/react';
import * as React from 'react';
import { Provider } from 'react-redux';

import MuiXLicense from '../../../licensing/MuiXLicense';
import redux from '../../redux';
import translations from '../../translations';
import ExportCsvModal from './index';

translations.init();

function TestComponent() {
  return (
    <>
      <Provider store={redux.store}>
        <LocalizationProvider adapterLocale="en" dateAdapter={AdapterDayjs}>
          <ExportCsvModal />
        </LocalizationProvider>
      </Provider>
      <MuiXLicense />
    </>
  );
}

describe('export csv modal', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the export csv modal', () => {
    redux.store.dispatch(redux.modal.open());
    render(<TestComponent />);
    const modal = screen.getByTestId('export-csv-modal');
    expect(modal).toBeDefined();
  });
});
