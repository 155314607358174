import { createSlice } from '@reduxjs/toolkit';

import asyncThunks from './async-thunks';
import extraReducers from './extra-reducers';
import { ExportState } from './types';

const initialState: ExportState = {
  host: '',
  isError: false,
  isLoading: false,
};

const slice = createSlice({
  extraReducers: (builder) => {
    extraReducers.requestExport(builder);
  },
  initialState,
  name: 'api',
  reducers: {
    setHost: (state, action) => {
      state.host = action.payload;
    },
  },
});

const api = {
  reducer: slice.reducer,
  requestExport: asyncThunks.requestExport,
  setHost: slice.actions.setHost,
};

export default api;
