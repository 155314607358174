import { createSlice } from '@reduxjs/toolkit';

import asyncThunks from './async-thunks';
import extraReducers from './extra-reducers';
import { BannerState } from './types';

const initialState: BannerState = {
  content: '',
  hidden: true,
  machineName: '',
  severity: 'success',
};

const slice = createSlice({
  extraReducers: (builder) => {
    extraReducers.setContent(builder);
  },
  initialState,
  name: 'banner',
  reducers: {
    hide: (state) => {
      state.hidden = true;
      const banner = document.getElementsByClassName(
        'alert-box',
      )[0] as HTMLDivElement;

      if (banner) {
        banner.style.display = 'none';
      }
    },
    setMachineName: (state, action) => {
      state.machineName = action.payload;
    },
    setSeverity: (state, action) => {
      state.severity = action.payload;
      const banner = document.getElementsByClassName(
        'alert-box',
      )[0] as HTMLDivElement;

      if (banner) {
        banner.className = `alert-box ${action.payload}`;
      }
    },
    show: (state) => {
      state.hidden = false;
      const banner = document.getElementsByClassName(
        'alert-box',
      )[0] as HTMLDivElement;
      if (banner) {
        banner.style.display = 'flex';
      }
    },
  },
});

const banner = {
  hide: slice.actions.hide,
  reducer: slice.reducer,
  setContent: asyncThunks.setContent,
  setMachineName: slice.actions.setMachineName,
  setSeverity: slice.actions.setSeverity,
  show: slice.actions.show,
};

export default banner;
