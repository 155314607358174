import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import SelectCustomer from './SelectCustomer';

const CopyMachinesDialogContent = ({
  copyDone,
  errorMessage,
  machineIds,
  newMachines,
  selectedCustomer,
  setErrorMessage,
  setSelectedCustomer,
  t,
}) => {
  const renderContent = () => {
    if (copyDone) {
      return <p>{t('done', { count: newMachines.length })}</p>;
    }

    if (selectedCustomer) {
      return (
        <p>
          {t('confirmation', {
            count: machineIds.length,
            customerNameAndId: selectedCustomer.label,
          })}
        </p>
      );
    }

    return (
      <SelectCustomer
        setErrorMessage={setErrorMessage}
        setSelectedCustomer={setSelectedCustomer}
        t={t}
      />
    );
  };

  return (
    <>
      {renderContent()}
      {errorMessage && (
        <Alert severity={errorMessage.severity} sx={{ mt: 3 }}>
          {errorMessage.message}
        </Alert>
      )}
    </>
  );
};

CopyMachinesDialogContent.propTypes = {
  copyDone: PropTypes.bool.isRequired,
  errorMessage: PropTypes.object,
  machineIds: PropTypes.array.isRequired,
  newMachines: PropTypes.array.isRequired,
  selectedCustomer: PropTypes.object,
  setErrorMessage: PropTypes.func.isRequired,
  setSelectedCustomer: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default CopyMachinesDialogContent;
