import { Popover } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../redux';
import PopoverContent from './popover-content';

type SelectionPopoverProps = {
  anchorEl: HTMLDivElement;
  setAnchorEl: (anchorEl: HTMLDivElement | null) => void;
};

const sx = () => ({
  border: '1px solid #E8E8E8',
  borderRadius: '8px',
  boxShadow: '0px 2px 6px 0px #1A19191A',
});

function SelectionPopover({
  anchorEl,
  setAnchorEl,
}: Readonly<SelectionPopoverProps>) {
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const windowSize = useSelector((state: RootState) => state.form.windowSize);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 47,
      }}
      id={id}
      onClose={handleClose}
      open={open}
      slotProps={{
        paper: {
          sx,
        },
      }}
    >
      <PopoverContent setAnchorEl={setAnchorEl} windowSize={windowSize} />
    </Popover>
  );
}

export default SelectionPopover;
