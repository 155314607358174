import { faker } from '@faker-js/faker';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { render, screen } from '@testing-library/react';
import React from 'react';
import { describe, expect, test, vi } from 'vitest';

import { addNotificationMessage } from '../features/notifications';
import App from './App';
import store, { RootState } from './login/store';

const createMockDispatch = () =>
  vi.fn() as unknown as ThunkDispatch<RootState, undefined, Action>;

vi.mock('./AppRoutes', () => ({
  __esModule: true,
  default: () => <div>AppRoutes</div>,
}));

describe('App', () => {
  test('dispatches error notification if alert is provided', () => {
    store.dispatch = createMockDispatch();
    const alertMessage = faker.lorem.sentence();

    render(<App alert={alertMessage} />);

    expect(store.dispatch).toHaveBeenCalledWith(
      addNotificationMessage({
        message: alertMessage,
        severity: 'error',
      }),
    );
  });

  test('dispatches info notification if notice is provided', () => {
    store.dispatch = createMockDispatch();
    const noticeMessage = faker.lorem.sentence();

    render(<App notice={noticeMessage} />);

    expect(store.dispatch).toHaveBeenCalledWith(
      addNotificationMessage({ message: noticeMessage, severity: 'info' }),
    );
  });

  test('renders AppRoutes', () => {
    render(<App />);
    expect(screen.getByText('AppRoutes')).toBeInTheDocument();
  });
});
