import { Grid } from '@mui/material';
import React from 'react';

import { SensorTypeSetting } from '../../../interfaces';
import { ALERT_SENSOR_TYPE_CODES, ALERT_TYPE_CONNECTION } from '../constants';
import DelayInputField from './DelayInputField';
import IfConditionText from './IfConditionText';
import useSensorTypeSetting from './useSensorTypeSetting';
import useSetDelayField from './useSetDelayField';

const typeCodeMapping: Record<
  string,
  { withDelay: string; withoutDelay: string }
> = {
  [ALERT_SENSOR_TYPE_CODES.ERROR]: {
    withDelay: 'messages.tempProbe.ifDisconnectedMoreThan',
    withoutDelay: 'messages.tempProbe.ifDisconnected',
  },
  [ALERT_SENSOR_TYPE_CODES.GATEWAY_CELL]: {
    withDelay: 'messages.gateway.ifSwitchToCellMoreThan',
    withoutDelay: 'messages.gateway.ifSwitchToCell',
  },
  [ALERT_SENSOR_TYPE_CODES.GATEWAY_CONNECTION]: {
    withDelay: 'messages.connection.ifLostMoreThan',
    withoutDelay: 'messages.connection.ifLost',
  },
  [ALERT_SENSOR_TYPE_CODES.GATEWAY_POWER]: {
    withDelay: 'messages.gateway.ifSwitchToBatteryMoreThan',
    withoutDelay: 'messages.gateway.ifSwitchToBattery',
  },
  [ALERT_TYPE_CONNECTION]: {
    withDelay: 'messages.connection.ifLostMoreThan',
    withoutDelay: 'messages.connection.ifLost',
  },
};

const getIfI18nKey = (typeCode: string, showSetDelay: boolean) => {
  const defaultKey = 'messages.generic.if';
  const i18nKeys = typeCodeMapping[typeCode];

  if (i18nKeys) {
    return showSetDelay ? i18nKeys.withDelay : i18nKeys.withoutDelay;
  }

  return defaultKey;
};

interface SensorTypeSettingFormProps {
  setting: SensorTypeSetting;
}

const SensorTypeSettingForm: React.FC<SensorTypeSettingFormProps> = ({
  setting,
}) => {
  const { maxDelay, minDelay, showSetDelay } = useSetDelayField(setting);

  const { handleValueChange } = useSensorTypeSetting(setting);

  const ifI18nKey = getIfI18nKey(setting.typeCode, showSetDelay);

  return (
    <Grid alignItems="center" container direction="row" gap={'5px'} spacing={2}>
      <Grid item md={'auto'} sm={6} xs={12}>
        <IfConditionText
          i18nKey={ifI18nKey}
          values={{ alert_type: setting.name }}
        />
      </Grid>
      {showSetDelay && (
        <Grid
          alignItems="center"
          container
          gap={'5px'}
          item
          md={'auto'}
          sm={6}
          xs={12}
        >
          <DelayInputField
            dataTestId={`set-delay-field-${setting.typeCode}`}
            max={maxDelay}
            min={minDelay}
            onChange={(e) =>
              handleValueChange('setDelay', parseFloat(e.target.value))
            }
            value={setting.setDelay || 0}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SensorTypeSettingForm;
