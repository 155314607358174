import { describe, expect, it, vi } from 'vitest';

import parseSubdomains from '../../../utils/parseSubdomains';
import isPkiChannel from './isPkiChannel';

// Mock the parseSubdomains function
vi.mock('../../../utils/parseSubdomains');

describe('isPkiChannel', () => {
  it('should return true when the channel is "pki"', () => {
    Object.defineProperty(window, 'location', {
      value: {
        hostname: 'pki.dashboard.example.com',
      },
      writable: true,
    });

    vi.mocked(parseSubdomains).mockReturnValue({
      branch: undefined,
      channel: 'pki',
      component: 'dashboard',
      individual: undefined,
    });

    const result = isPkiChannel();
    expect(result).toBe(true);
  });

  it('should return false when the channel is not "pki"', () => {
    // Mock window.location.hostname
    Object.defineProperty(window, 'location', {
      value: {
        hostname: 'alpha.dashboard.example.com',
      },
      writable: true,
    });

    // Mock parseSubdomains return value
    vi.mocked(parseSubdomains).mockReturnValue({
      branch: undefined,
      channel: 'alpha',
      component: 'dashboard',
      individual: undefined,
    });

    const result = isPkiChannel();
    expect(result).toBe(false);
  });

  it('should return false when there is no channel', () => {
    // Mock window.location.hostname
    Object.defineProperty(window, 'location', {
      value: {
        hostname: 'dashboard.example.com',
      },
      writable: true,
    });

    // Mock parseSubdomains return value
    vi.mocked(parseSubdomains).mockReturnValue({
      branch: undefined,
      channel: undefined,
      component: 'dashboard',
      individual: undefined,
    });

    const result = isPkiChannel();
    expect(result).toBe(false);
  });

  it('should handle complex subdomain scenarios correctly', () => {
    // Mock window.location.hostname
    Object.defineProperty(window, 'location', {
      value: {
        hostname: 'alpha-09.pki.dashboard.elementaldev.io',
      },
      writable: true,
    });

    // Mock parseSubdomains return value
    vi.mocked(parseSubdomains).mockReturnValue({
      branch: 'alpha-09',
      channel: 'pki',
      component: 'dashboard',
      individual: undefined,
    });

    const result = isPkiChannel();
    expect(result).toBe(true);
  });
});
