import { faker } from '@faker-js/faker';

import redux from '../redux';

describe('redux slice: form', () => {
  const testData = faker.word.words(1);

  test('it should support the set window function method', () => {
    expect(
      redux.store.dispatch(redux.form.setWindowFunction(testData)),
    ).toStrictEqual({
      payload: testData,
      type: 'form/setWindowFunction',
    });
  });

  test('it should support the set window size method', () => {
    expect(
      redux.store.dispatch(redux.form.setWindowSize(testData)),
    ).toStrictEqual({
      payload: testData,
      type: 'form/setWindowSize',
    });
  });
});
