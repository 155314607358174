import { Button } from '@mui/material';
import dayjs from 'dayjs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import redux from '../../redux';

type ShortcutProps = {
  item: string;
};

const sx = () => ({
  color: '#444C5B',
  fontFamily: 'Avenir Next',
  fontSize: 14,
  fontWeight: 500,
  height: '24px',
  letterSpacing: 0.15000000596046448,
  lineHeight: '24px',
  minWidth: 0,
  padding: 0,
  textTransform: 'none',
});

function Shortcut({ item }: Readonly<ShortcutProps>) {
  const { t } = useTranslation();
  const dispatch: typeof redux.store.dispatch = useDispatch();

  // complexity warning disabled in order to support the switch statement
  // eslint-disable-next-line complexity
  const handleShortcutClick = () => {
    let startDate = dayjs().startOf('day');
    let endDate = dayjs().endOf('day');

    switch (item) {
      case 'yesterday':
        startDate = startDate.subtract(1, 'days');
        endDate = endDate.subtract(1, 'days');
        break;
      case '7d':
        startDate = startDate.add(1, 'days').subtract(1, 'week');
        break;
      case '1m':
        startDate = startDate.add(1, 'days').subtract(1, 'month');
        break;
      case '3m':
        startDate = startDate.add(1, 'days').subtract(3, 'months');
        break;
      case '6m':
        startDate = startDate.add(1, 'days').subtract(6, 'months');
        break;
      case '12m':
        startDate = startDate.add(1, 'days').subtract(1, 'year');
        break;
      default:
        break;
    }
    dispatch(redux.range.setRangeError(false));
    dispatch(redux.range.setStartDate(startDate.format('YYYY-MM-DD')));
    dispatch(redux.range.setEndDate(endDate.format('YYYY-MM-DD')));
  };

  return (
    <Button key={item} onClick={handleShortcutClick} sx={sx}>
      {t(item)}
    </Button>
  );
}

export default Shortcut;
