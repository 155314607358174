import { configureStore } from '@reduxjs/toolkit';

import form from './form';
import modal from './modal';
import options from './options';

const redux = {
  form,
  modal,
  options,
  store: configureStore({
    devTools: true,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
      });
    },
    reducer: {
      form: form.reducer,
      modal: modal.reducer,
      options: options.reducer,
    },
  }),
};

export type RootState = ReturnType<typeof redux.store.getState>;

export default redux;
