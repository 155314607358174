import { cleanup, render, screen } from '@testing-library/react';
import i18next from 'i18next';
import * as React from 'react';

import translations from '../../translations';
import Status from '../status';

translations.init();

const value = 'running';

function TestComponent() {
  return <Status value={value} />;
}

describe('status', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the status', () => {
    render(<TestComponent />);
    const span = screen.getByTestId('status');

    expect(span).toBeDefined();
    expect(span.textContent).toStrictEqual(i18next.t(`status.${value}`));
  });
});
