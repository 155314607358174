import { createSlice } from '@reduxjs/toolkit';

type ModalState = {
  isOpen: boolean;
};

const initialState: ModalState = {
  isOpen: false,
};

const slice = createSlice({
  initialState,
  name: 'modal',
  reducers: {
    close: (state) => {
      state.isOpen = false;
    },
    open: (state) => {
      state.isOpen = true;
    },
  },
});

const range = {
  close: slice.actions.close,
  open: slice.actions.open,
  reducer: slice.reducer,
};

export default range;
