import redux from '../redux';

describe('redux', () => {
  test('it should have proper object properties', () => {
    expect(redux).toHaveProperty('modal');
    expect(redux).toHaveProperty('range');
    expect(redux).toHaveProperty('shortcut');
    expect(redux).toHaveProperty('store');
    expect(redux.store).toHaveProperty('getState');
    expect(redux.store).toHaveProperty('dispatch');
  });
});
