import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const LightIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M13.5 5.47C13.5 2.449 11.037 0 8 0 4.962 0 2.5 2.449 2.5 5.47c0 1.478 1.19 3.223 1.19 3.223.197.32.41.882.477 1.253l.204 1.148c.106.595.68 1.077 1.284 1.077h4.69c.603 0 1.178-.482 1.284-1.077l.204-1.148c.067-.372.284-.932.477-1.253 0 0 1.19-1.745 1.19-3.223zm-8.8 7.795c0-.302.242-.547.546-.547h5.508a.547.547 0 0 1 0 1.094H5.246a.547.547 0 0 1-.546-.547zM8.003 16c-1.586 0-2.427-1.076-2.427-1.076-.326-.312-.222-.565.23-.565h4.39c.452 0 .564.264.228.567 0 0-.836 1.074-2.421 1.074z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default LightIcon;
