import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en';
import * as React from 'react';
import { Provider } from 'react-redux';

import MuiXLicense from '../licensing/MuiXLicense';
import redux from './redux';
import translations from './translations';
import BannerManager from './views/banner-manager';
import ExportCsvButton from './views/export-csv-button';
import ExportCsvModal from './views/export-csv-modal';
import HostManager from './views/host-manager';
import SyncManager from './views/sync-manager';

translations.init();

type ExportCSVContainerProps = {
  host: string;
  machineName: string;
  managedMachineUuid: string;
  samplesManagedMachinePath: string;
};

const ExportCsvContainer = (props: ExportCSVContainerProps) => {
  return (
    <>
      <Provider store={redux.store}>
        <LocalizationProvider adapterLocale="en" dateAdapter={AdapterDayjs}>
          <>
            <ExportCsvButton />
            <ExportCsvModal />
            <SyncManager {...props} />
            <BannerManager {...props} />
            <HostManager {...props} />
          </>
        </LocalizationProvider>
      </Provider>
      <MuiXLicense />
    </>
  );
};

export default ExportCsvContainer;
