import React from 'react';
import PropTypes from 'prop-types';

const PowerIcon = ({ width = 16, height = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm-.924 1.686c0-.495.413-.895.924-.895.51 0 .925.4.925.895v7.147c0 .494-.415.893-.925.893s-.924-.399-.924-.893V1.686zM2 7.974c0-2.631 1.632-4.852 4.13-5.55V4.32c-1.455.622-2.276 2.02-2.28 3.654.004 2.208 1.859 4.163 4.15 4.167 2.292-.004 4.145-1.96 4.15-4.167-.002-1.633-.812-3.032-2.267-3.654V2.424C12.38 3.124 14 5.342 14 7.974c0 3.193-2.686 5.95-6 5.95s-5.998-2.757-6-5.95z"
      />
    </svg>
  );
};

PowerIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default PowerIcon;
