import React from 'react';
import PropTypes from 'prop-types';

const EmailIcon = ({ width = 14, height = 12 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3335 0.666687H1.66683C0.933496 0.666687 0.340163 1.26669 0.340163 2.00002L0.333496 10C0.333496 10.7334 0.933496 11.3334 1.66683 11.3334H12.3335C13.0668 11.3334 13.6668 10.7334 13.6668 10V2.00002C13.6668 1.26669 13.0668 0.666687 12.3335 0.666687ZM12.0668 3.50002L7.3535 6.44669C7.14016 6.58002 6.86016 6.58002 6.64683 6.44669L1.9335 3.50002C1.76683 3.39335 1.66683 3.21335 1.66683 3.02002C1.66683 2.57335 2.1535 2.30669 2.5335 2.54002L7.00016 5.33335L11.4668 2.54002C11.8468 2.30669 12.3335 2.57335 12.3335 3.02002C12.3335 3.21335 12.2335 3.39335 12.0668 3.50002Z"
        fill="white"
      />
    </svg>
  );
};

EmailIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default EmailIcon;
