import axios from 'axios';

import redux from '../../index';

describe('get paginated reports', () => {
  it('should return an array of paginated reports', () => {
    vi.spyOn(axios, 'post').mockImplementation(async () => ({
      data: {
        results: [{}],
        totalCount: 1,
      },
    }));

    redux.store.dispatch(redux.api.getPaginatedReports());

    expect(axios.post).toHaveBeenCalledOnce();
  });

  it('should fail the request', () => {
    vi.spyOn(axios, 'post').mockRejectedValueOnce(async () => ({}));

    redux.store.dispatch(redux.api.getPaginatedReports());

    expect(axios.post).toHaveBeenCalledOnce();
  });
});
