import { configureStore } from '@reduxjs/toolkit';

import guestApp from './guest-app';

const redux = {
  guestApp,
  store: configureStore({
    devTools: true,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
      });
    },
    reducer: {
      guestApp: guestApp.reducer,
    },
  }),
};

export type RootState = ReturnType<typeof redux.store.getState>;

export default redux;
