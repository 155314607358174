import { fireEvent, render, screen } from '@testing-library/react';
import React from 'react';
import { describe, expect, it, vi } from 'vitest';

import AlertDialog, { AlertDialogProps } from './AlertDialog';

describe('MinimumSelectionDialog', () => {
  const setup = (props: AlertDialogProps) => {
    render(<AlertDialog {...props} />);
  };

  it('renders the dialog when open is true', () => {
    setup({ message: 'Test message', onClose: vi.fn(), open: true });
    expect(screen.getByText('Test message')).toBeInTheDocument();
  });

  it('does not render the dialog when open is false', () => {
    setup({ message: 'Test message', onClose: vi.fn(), open: false });
    expect(screen.queryByText('Test message')).not.toBeInTheDocument();
  });

  it('renders the correct message', () => {
    setup({
      message: 'Minimum one selection is required',
      onClose: vi.fn(),
      open: true,
    });
    expect(
      screen.getByText('Minimum one selection is required'),
    ).toBeInTheDocument();
  });

  it('calls onClose when the close button is clicked', () => {
    const onCloseMock = vi.fn();
    setup({ message: 'Test message', onClose: onCloseMock, open: true });

    fireEvent.click(screen.getByTestId('alert-dialog-close-button'));
    expect(onCloseMock).toHaveBeenCalled();
  });

  it('includes a custom data-testid if provided', () => {
    setup({
      dataTestId: 'custom-dialog',
      message: 'Test message',
      onClose: vi.fn(),
      open: true,
    });
    expect(screen.getByTestId('custom-dialog')).toBeInTheDocument();
  });
});
