import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import i18next from 'i18next';

import constants from '../../../constants';
import redux, { RootState } from '../../index';

type RequestExportResponse = {
  fileName: string;
};

const requestExport = createAsyncThunk(
  'api/request-export',
  async (_data, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    let response: AxiosResponse<RequestExportResponse, string>;

    try {
      response = await axios.post(
        `${state.api.host}${constants.endpoints.requestExport}`,
        {
          managedMachineUuids: [state.form.managedMachineUuid],
          periodDateFrom: state.range.startDate,
          periodDateTo: state.range.endDate,
          sendEmail: state.options.sendEmail,
          windowFunction: state.options.windowFunction,
          windowSize: state.options.windowSize,
        },
        {
          withCredentials: true,
        },
      );
    } catch (e: unknown) {
      const machineName = state.banner.machineName
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');

      const fileName = `${machineName}_${state.range.startDate}_${state.range.endDate}.csv`;
      const errorMessage = i18next.t('request-export-error-message', {
        'report-name': fileName,
      });
      thunkAPI.dispatch(redux.banner.setSeverity('alert'));
      thunkAPI.dispatch(redux.banner.setContent(errorMessage));
      thunkAPI.dispatch(redux.banner.show());

      return thunkAPI.rejectWithValue(errorMessage);
    }

    thunkAPI.dispatch(redux.banner.setSeverity('success'));
    thunkAPI.dispatch(
      redux.banner.setContent(
        i18next.t('request-export-success-message', {
          'report-name': response.data.fileName,
        }),
      ),
    );
    thunkAPI.dispatch(redux.banner.show());
    return response.data;
  },
);

export default requestExport;
