import * as React from 'react';
import { useSelector } from 'react-redux';

import constants from '../../constants';
import { RootState } from '../../redux';
import Icons from '../icons';

type DownloadButtonProps = {
  active: boolean;
  id: string;
};

function DownloadButton({ active, id }: Readonly<DownloadButtonProps>) {
  const host = useSelector((state: RootState) => state.api.host);

  if (!active) {
    return <Icons.Download fill="#2F637980" />;
  }

  return (
    <a
      href={`${host}${constants.endpoints.getPreSignedUrl.replace(':id', id)}`}
      rel="noreferrer"
      target="_blank"
    >
      <Icons.Download fill="#2F6379" />
    </a>
  );
}

export default DownloadButton;
