import { HttpResponse, http } from 'msw';

import { Credentials } from '../authTypes';

export const handlers = [
  http.post('/users/sign_in', async ({ request }) => {
    const {
      user: { email },
    } = (await request.json()) as {
      app: string;
      user: Credentials;
    };
    return new HttpResponse(
      JSON.stringify({
        email,
        id: 1,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
          location: '/dashboard',
        },
      },
    );
  }),
  http.get('/sso/login_path', ({ request }) => {
    const url = new URL(request.url);
    const email = url.searchParams.get('email');

    // Return SSO path for certain emails
    if (email === 'sso@example.com') {
      return HttpResponse.json({ sso_path: '/sso/path/example' });
    }

    // Return null for other emails
    return HttpResponse.json({ sso_path: null });
  }),
];
