import dayjs from 'dayjs';

import data from '../data';

describe('columns', () => {
  test('date range value getter', () => {
    const now = dayjs();

    expect(typeof data.columns[1].valueGetter).toEqual('function');

    if (typeof data.columns[1].valueGetter === 'function') {
      const value = data.columns[1].valueGetter(now, {
        dateEnd: now,
        dateStart: now,
      });

      expect(value).toStrictEqual(
        `${now.format('MMMM D, YYYY')} - ${now.format('MMMM D, YYYY')}`,
      );
    }
  });
});
