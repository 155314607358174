import { GridSortItem } from '@mui/x-data-grid-pro';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import i18next from 'i18next';

import redux from '../../../../export-csv/redux';
import constants from '../../../constants';
import { ExportListResponse } from '../../../types/export-api';
import { SearchParams } from '../../../types/pagination';
import utils from '../../../utils';
import data, { DataRecord } from '../../data';
import { RootState } from '../../index';

dayjs.extend(utc);
dayjs.extend(tz);

const getPaginatedReports = createAsyncThunk(
  'api/get-paginated-reports',
  async (_data, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    const searchParams: SearchParams<DataRecord> = {
      page: state.data.paginationModel.page + 1,
      perPage: state.data.paginationModel.pageSize,
      sortBy: state.data.sortModel.map((entry: GridSortItem) => ({
        column: utils.translateColumnName(entry.field) as keyof DataRecord,
        sortAscending: entry.sort === 'asc',
      })),
    };

    let response: AxiosResponse<ExportListResponse>;

    try {
      response = await axios.post(
        `${state.api.host}${constants.endpoints.getPaginatedReports}`,
        searchParams,
        {
          withCredentials: true,
        },
      );
    } catch (e: unknown) {
      const errorMessage = i18next.t('get-paginated-reports-error-message');

      thunkAPI.dispatch(redux.banner.setSeverity('alert'));
      thunkAPI.dispatch(redux.banner.setContent(errorMessage));
      thunkAPI.dispatch(redux.banner.show());

      return thunkAPI.rejectWithValue(errorMessage);
    }

    thunkAPI.dispatch(data.setRowCount(response.data.totalCount));

    if (response.data.results) {
      thunkAPI.dispatch(
        data.setRecords(
          response.data.results.map((record) => ({
            createdAt: dayjs
              .utc(record.created_at)
              .tz(state.data.userTimeZone)
              .format('ddd, MMM D, YYYY hA'),
            dateEnd: record.export_data.period_date_to,
            dateStart: record.export_data.period_date_from,
            id: record.uuid,
            name: record.export_data.name,
            status: record.status,
            updatedAt: record.updated_at,
            userFullName: record.export_data.user_full_name,
          })),
        ),
      );
    }

    return response.data;
  },
);

export default getPaginatedReports;
