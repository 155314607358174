import redux from '../redux';

describe('redux slice: modal', () => {
  test('it should support the close method', () => {
    expect(redux.store.dispatch(redux.modal.close())).toStrictEqual({
      payload: undefined,
      type: 'modal/close',
    });
  });

  test('it should support the open method', () => {
    expect(redux.store.dispatch(redux.modal.open())).toStrictEqual({
      payload: undefined,
      type: 'modal/open',
    });
  });
});
