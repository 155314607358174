import { createSlice } from '@reduxjs/toolkit';

type ShortcutState = {
  choices: string[];
};

const initialState: ShortcutState = {
  choices: ['yesterday', 'today', '7d', '1m', '3m', '6m', '12m'],
};

const slice = createSlice({
  initialState,
  name: 'shortcut',
  reducers: {
    setChoices: (state, action) => {
      state.choices = action.payload;
    },
  },
});

const shortcut = {
  reducer: slice.reducer,
  setChoices: slice.actions.setChoices,
};

export default shortcut;
