import { createSlice } from '@reduxjs/toolkit';

import { GuestAppState } from './types';

const initialState: GuestAppState = {
  errorMessage: '',
  initError: false,
};

const slice = createSlice({
  initialState,
  name: 'guest-app',
  reducers: {
    startupDone: (state) => {
      state.initError = false;
      state.errorMessage = '';
      const banner = document.getElementsByClassName(
        'alert-box',
      )[0] as HTMLDivElement;

      if (banner) {
        banner.style.display = 'none';
      }
    },
    startupError: (state, action) => {
      state.initError = true;
      state.errorMessage = action.payload;

      const bannerContainer =
        document.getElementsByClassName('alert-box-wrapper')[0];

      let banner = document.getElementsByClassName('alert-box')[0] as
        | HTMLDivElement
        | undefined;

      if (!banner) {
        banner = document.createElement('div');
        banner.className = 'alert-box';
        banner.setAttribute('data-alert', 'true');

        bannerContainer?.appendChild(banner);

        banner.style.display = 'none';
      }

      banner.innerHTML = '';
      const bannerClose = document.createElement('a');
      bannerClose.href = '#';
      bannerClose.className = 'close';
      bannerClose.textContent = 'x';

      const bannerContent = document.createElement('span');
      bannerContent.textContent = action.payload;

      bannerClose.onclick = () => {
        banner.style.display = 'none';
        banner.className = 'alert-box';
        bannerContent.textContent = '';
      };

      banner.appendChild(bannerContent);
      banner.appendChild(bannerClose);
      banner.className = `alert-box alert`;
      banner.style.display = 'flex';
      banner.style.marginTop = '60px';
    },
  },
});

const guestApp = {
  reducer: slice.reducer,
  startupDone: slice.actions.startupDone,
  startupError: slice.actions.startupError,
};

export default guestApp;
