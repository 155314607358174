import { Autocomplete, TextField } from '@mui/material';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { CUSTOMER_LIST } from './CopyMachinesDialog';

interface CustomerListType {
  id: number;
  name: string;
}

const SelectCustomer = ({ setErrorMessage, setSelectedCustomer, t }) => {
  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(CUSTOMER_LIST, {
          headers: {
            Accept: 'application/json',
          },
        });
        setCustomerList(
          response.data.map((item: CustomerListType) => {
            return { id: item.id, label: `${item.name} (${item.id})` };
          }),
        );
      } catch (error) {
        setErrorMessage({ message: error.message, severity: 'error' });
      }
    };
    fetchData();
  }, []);

  return (
    <Autocomplete
      onChange={(event, value) => setSelectedCustomer(value)}
      options={customerList}
      renderInput={(params) => (
        <TextField {...params} label={t('toCustomer')} />
      )}
      sx={{ paddingTop: '6px' }}
    />
  );
};

SelectCustomer.propTypes = {
  setErrorMessage: PropTypes.func.isRequired,
  setSelectedCustomer: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default SelectCustomer;
