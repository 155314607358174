import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const CloseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M17 7L7 17M7 7L17 17"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </SvgIcon>
);

export default CloseIcon;
