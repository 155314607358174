import redux from '../redux';

describe('redux slice: range', () => {
  test('it should support the set start date method', () => {
    expect(
      redux.store.dispatch(redux.range.setStartDate('DD/MM/YYYY')),
    ).toStrictEqual({
      payload: 'DD/MM/YYYY',
      type: 'range/setStartDate',
    });
  });

  test('it should support the set end date method', () => {
    expect(
      redux.store.dispatch(redux.range.setEndDate('DD/MM/YYYY')),
    ).toStrictEqual({
      payload: 'DD/MM/YYYY',
      type: 'range/setEndDate',
    });
  });
});
