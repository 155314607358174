import { AlertRule, AlertType, SensorTypeSetting } from '@interfaces/index';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import updateHiddenField from '../../utils/updateHiddenField';

const initialState: AlertRule = {
  alertOnFirstOutOfRange: false,
  editable: true,
  enabled: true,
  machineSubmodelTypes: [],
  machineTypes: [],
  name: '',
  sensorTypeSettings: [],
  sensorTypes: [],
  useIndividualMachines: false,
};

const sensorSettingsHiddenFieldId = 'js-react-sensor-settings';
const sensorTypesHiddenFieldId = 'js-react-sensor-types';

export const alertRuleSlice = createSlice({
  initialState,
  name: 'alertRule',
  reducers: {
    setAlertRule: (state, action: PayloadAction<AlertRule>) => {
      const { sensorTypeSettings, sensorTypes } = action.payload;
      updateHiddenField(sensorSettingsHiddenFieldId, sensorTypeSettings);
      updateHiddenField(sensorTypesHiddenFieldId, sensorTypes);
      return action.payload;
    },
    setAlertType: (state, action: PayloadAction<AlertType>) => {
      state.alertType = action.payload;
    },
    setSensorTypeSettings: (
      state,
      action: PayloadAction<SensorTypeSetting[]>,
    ) => {
      state.sensorTypeSettings = action.payload;
      updateHiddenField(sensorSettingsHiddenFieldId, state.sensorTypeSettings);
    },
    setSensorTypes: (state, action: PayloadAction<string[]>) => {
      state.sensorTypes = action.payload;
      updateHiddenField(sensorTypesHiddenFieldId, state.sensorTypes);
    },
    updateSensorTypeSetting: (
      state,
      action: PayloadAction<{
        key: keyof SensorTypeSetting;
        typeCode: string;
        value: SensorTypeSetting[keyof SensorTypeSetting];
      }>,
    ) => {
      const { key, typeCode, value } = action.payload;
      const setting = state.sensorTypeSettings.find(
        (setting) => setting.typeCode === typeCode,
      );
      if (setting) {
        (setting[key] as typeof value) = value;
        updateHiddenField(
          sensorSettingsHiddenFieldId,
          state.sensorTypeSettings,
        );
      }
    },
  },
});

export const {
  setAlertRule,
  setAlertType,
  setSensorTypeSettings,
  setSensorTypes,
  updateSensorTypeSetting,
} = alertRuleSlice.actions;

export default alertRuleSlice.reducer;
