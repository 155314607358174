import { Alert, Box, BoxProps } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeNotificationMessage } from '../../features/notifications';
import { RootState } from '../login/store';

const NotificationMessages: React.FC<BoxProps> = (props) => {
  const { ...boxProps } = props;
  const dispatch = useDispatch();
  const messages = useSelector(
    (state: RootState) => state.notification.messages,
  );

  useEffect(() => {
    const timers = messages.map((message) =>
      setTimeout(() => {
        dispatch(removeNotificationMessage(message.id));
      }, 5000),
    );

    // Clean up timers when component unmounts or messages change
    return () => {
      timers.forEach(clearTimeout);
    };
  }, [messages, dispatch]);

  const handleClose = (id: string) => {
    dispatch(removeNotificationMessage(id));
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      {...boxProps}
      data-testid="notification-messages"
    >
      {messages.map((message) => (
        <Alert
          data-testid={`notification-${message.id}`}
          key={message.id}
          onClose={() => handleClose(message.id)}
          severity={message.severity}
          sx={{ mt: 3, width: '100%' }}
        >
          {message.message}
        </Alert>
      ))}
    </Box>
  );
};

export default NotificationMessages;
