import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux';

function WindowSize() {
  const { t } = useTranslation();
  const windowSize = useSelector(
    (state: RootState) => state.options.windowSize,
  );

  let output: string;

  switch (windowSize) {
    case '1s':
      output = t('window-size-all');
      break;
    case '15m':
      output = t('window-size-15m');
      break;
    case '1h':
      output = t('window-size-1h');
      break;
    case '1d':
      output = t('window-size-1d');
      break;
    case '1m':
    default:
      output = t('window-size-1m');
      break;
  }

  return <span>{output}</span>;
}

export default WindowSize;
