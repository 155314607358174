import { encodeSearchParams } from '@elemental/pagination-package';
import { GridSortItem } from '@mui/x-data-grid-pro';

import { DataRecord } from '../redux/data';
import { DataLoadProps, SearchParams } from '../types/pagination';

/**
 * converts data load options to encoded querystring and replace it in URL
 */
const dataLoadToRoute = ({ paginationModel, sortModel }: DataLoadProps) => {
  const searchParams: SearchParams<DataRecord> = {
    page: paginationModel.page,
    perPage: paginationModel.pageSize,
    sortBy: sortModel.map((entry: GridSortItem) => ({
      column: entry.field as keyof DataRecord,
      sortAscending: entry.sort === 'asc',
    })),
  };

  const queryParams = new URLSearchParams(window.location.search);

  queryParams.delete('dataLoad');

  const newUrl = `${
    window.location.pathname
  }?${queryParams.toString()}&dataLoad=${encodeSearchParams(searchParams)}`;

  window.history.replaceState({}, '', newUrl);
};

export default dataLoadToRoute;
