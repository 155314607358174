import { Button } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import redux, { RootState } from '../../../redux';

type SaveButtonProps = {
  setAnchorEl: (anchorEl: HTMLDivElement | null) => void;
};

const sx = () => ({
  '&:hover': {
    backgroundColor: '#1C5BFE',
  },
  backgroundColor: '#1C5BFE',
  borderRadius: '8px',
  color: '#FFFFFF',
  fontFamily: 'Avenir Next',
  fontSize: 14,
  fontWeight: 600,
  height: 35,
  textTransform: 'none',
  width: 126,
});

function SaveButton({ setAnchorEl }: Readonly<SaveButtonProps>) {
  const { t } = useTranslation();
  const windowSize = useSelector((state: RootState) => state.form.windowSize);
  const windowFunction = useSelector(
    (state: RootState) => state.form.windowFunction,
  );
  const dispatch = useDispatch();

  const handleSave = () => {
    const event = new CustomEvent('update-sample-rate', {
      detail: {
        windowFunction,
        windowSize,
      },
    });
    window.dispatchEvent(event);
    dispatch(redux.options.setWindowSize(windowSize));
    dispatch(redux.options.setWindowFunction(windowFunction));
    setAnchorEl(null);
  };

  return (
    <Button onClick={handleSave} sx={sx}>
      {t('save')}
    </Button>
  );
}

export default SaveButton;
