import { faker } from '@faker-js/faker';

import utils from '../utils';

describe('utils: get json from url', () => {
  test('return value', () => {
    const uuid = faker.string.uuid();
    const word = faker.word.words(1);
    expect(utils.getJsonFromUrl(`uuid=${uuid}&word=${word}`)).toStrictEqual({
      uuid,
      word,
    });
  });

  test('return value with nested params', () => {
    const uuid = faker.string.uuid();
    const word = faker.word.words(1);
    expect(
      utils.getJsonFromUrl(`uuid=${uuid}&word%5Bproperty%5D=${word}`),
    ).toStrictEqual({
      uuid,
      ['word[property]']: word,
    });
  });
});
