import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import 'dayjs/locale/en';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import MuiXLicense from '../licensing/MuiXLicense';
import redux from './redux';
import translations from './translations';
import TabContent from './views/tab-content';

translations.init();

type ReportsFilesAndExportsContainerProps = {
  host: string;
  tab: string;
  timezone: string;
};

type TabChangedEvent = {
  detail: {
    activeTab?: string;
  };
} & CustomEvent;

const ReportsFilesAndExportsContainer = (
  props: ReportsFilesAndExportsContainerProps,
) => {
  const queryString = new URLSearchParams(window.location.search);
  const [tabActive, setTabActive] = useState(
    queryString.get('tab') === props.tab,
  );
  const tabChangedCallback = (event: TabChangedEvent) => {
    setTabActive(event.detail.activeTab === props.tab);
  };

  //set active tab depending on its rendering
  useEffect(() => {
    const tab = document.querySelector(`#${props.tab}`);
    tab.parentElement.addEventListener('tab-changed', tabChangedCallback);

    return () => {
      tab.parentElement.removeEventListener('tab-changed', tabChangedCallback);
    };
  }, []);

  // fix main content scrollbar
  useEffect(() => {
    const mainContent = document.getElementsByClassName(
      'main-content',
    )[0] as HTMLDivElement;

    const reportsTabsContent = document.getElementsByClassName(
      'reports-tabs-content',
    )[0] as HTMLDivElement;

    if (mainContent) {
      if (tabActive) {
        mainContent.style.overflow = 'hidden';
      } else {
        mainContent.style.overflow = 'auto';
      }
    }

    if (reportsTabsContent) {
      if (tabActive) {
        reportsTabsContent.style.overflow = 'hidden';
      } else {
        reportsTabsContent.style.overflow = 'auto';
      }
    }
  }, [tabActive]);

  return (
    <>
      <Provider store={redux.store}>
        <LocalizationProvider adapterLocale="en" dateAdapter={AdapterDayjs}>
          <TabContent active={tabActive} {...props} />
        </LocalizationProvider>
      </Provider>
      <MuiXLicense />
    </>
  );
};

export default ReportsFilesAndExportsContainer;
