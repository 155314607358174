import { Controller } from 'stimulus';

export default class extends Controller {
	static targets = ['filtersText'];

	connect() {
		console.log('alert_report/filters_text_controller connect');
		this.element[this.identifier] = this;
	}

	// TODO This needs to be backend call to do I18n
	update(alertRuleNames) {
		if (alertRuleNames.size == 0) {
			this.filtersTextTarget.innerHTML = '';
		} else if (alertRuleNames.size < 3) {
			this.filtersTextTarget.innerHTML = `This report will be applied to machines in the ${this.wrap(Array.from(alertRuleNames)).join(' and ')} alert rules.`;
		} else {
			// >= 3
			let a = Array.from(alertRuleNames);
			console.log(a, a.length);
			let last = this.wrap(a.slice(-1))[0];
			console.log('last', last);
			let firstn = this.wrap(a.slice(0, a.length - 1));
			console.log('firstn', firstn);
			let s = [firstn.join(', '), last].join(' and ');
			console.log(s);
			this.filtersTextTarget.innerHTML = `This report will be applied to machines in the ${s} alert rules.`;
		}
	}

	wrap(alertRuleNames) {
		return alertRuleNames.map(
			(alertRuleName) => `<span class="alert-rule">${alertRuleName}</span>`,
		);
	}
}
