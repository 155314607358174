import { createSlice } from '@reduxjs/toolkit';

import { User } from '../../interfaces';
import { authApiSlice } from './authApiSlice';

interface AuthState {
  user: User | null;
}

const initialState: AuthState = {
  user: null,
};

const authSlice = createSlice({
  extraReducers: (builder) => {
    builder.addMatcher(
      authApiSlice.endpoints.loginUser.matchFulfilled,
      (state, action) => {
        state.user = action.payload.user;
      },
    );
  },
  initialState,
  name: 'auth',
  reducers: {},
});

export default authSlice.reducer;
