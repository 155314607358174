const space = [
  '0px', // 0
  '4px', // 1
  '8px', // 2
  '12px', // 3
  '16px', // 4
  '20px', // 5
  '24px', // 6
  '32px', // 7
  '40px', // 8
  '48px', // 9
  '64px', // 10
  '80px', // 11
  '120px', // 12
  '160px', // 13
  '200px', // 14
];

export default space;
