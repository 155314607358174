import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import redux from '../../redux';

function ExportCsvButton() {
  const { t } = useTranslation();
  const dispatch: typeof redux.store.dispatch = useDispatch();

  const handleModalOpen = () => {
    dispatch(redux.modal.open());
  };

  return (
    <button
      className="btn btn-blue btn-small link-export"
      onClick={handleModalOpen}
    >
      {t('export-csv')}
    </button>
  );
}

export default ExportCsvButton;
