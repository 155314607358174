import { Controller } from 'stimulus';

export default class extends Controller {
	static targets = ['link'];

	connect() {
		this.element[this.identifier] = this;
	}

	update(selectedOptions) {
		const alert_rule_ids = selectedOptions.map((e) => e.id).join(',');
		const paramString = new URLSearchParams({
			alert_rule_ids: alert_rule_ids,
		}).toString();
		const url = `${Routes.individual_machines_link_alert_reports_path()}?${paramString}`;
		fetch(url)
			.then((response) => response.json())
			.then((json) => {
				this.linkTarget.innerHTML = json.text;
				this.linkTarget.href = json.link;
			});
	}
}
