import { Box, Divider, Stack } from '@mui/material';
import * as React from 'react';

import CancelButton from './cancel-button';
import SaveButton from './save-button';
import WindowFunctionRadioGroup from './window-function-radio-group';
import WindowSizeRadioGroup from './window-size-radio-group';

type PopoverContentProps = {
  setAnchorEl: (anchorEl: HTMLDivElement | null) => void;
  windowSize: string;
};

const contentSx = () => ({
  padding: '21px 20px',
  width: 454,
});

const dividerSx = () => ({
  color: '#F2F4F8',
});

function PopoverContent({
  setAnchorEl,
  windowSize,
}: Readonly<PopoverContentProps>) {
  return (
    <Box sx={contentSx}>
      <WindowSizeRadioGroup />
      <Box my="20px">
        <Divider sx={dividerSx} />
      </Box>
      {windowSize !== '1s' && (
        <>
          <WindowFunctionRadioGroup />
          <Box my="20px">
            <Divider sx={dividerSx} />
          </Box>
        </>
      )}
      <Stack direction="row" justifyContent="space-between">
        <CancelButton setAnchorEl={setAnchorEl} />
        <SaveButton setAnchorEl={setAnchorEl} />
      </Stack>
    </Box>
  );
}

export default PopoverContent;
