import { faker } from '@faker-js/faker';
import { cleanup, fireEvent, render, screen } from '@testing-library/react';
import i18next from 'i18next';
import * as React from 'react';

import ExportCsvContainer from './index';
import redux from './redux';
import translations from './translations';

translations.init();

function TestComponent() {
  return (
    <ExportCsvContainer
      host={faker.internet.url()}
      machineName={faker.word.words(3)}
      managedMachineUuid={faker.string.uuid()}
      samplesManagedMachinePath={faker.internet.url()}
    />
  );
}

describe('export csv container', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the export csv container', () => {
    render(<TestComponent />);
    const button = screen.getByText(i18next.t('export-csv'));
    expect(button).toBeDefined();
    const spy = vi.spyOn(redux.modal, 'open');
    fireEvent.click(button);
    expect(spy).toHaveBeenCalledTimes(1);
  });
});
