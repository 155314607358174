import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import constants from '../../../constants';
import asyncThunks from '../async-thunks';
import { ExportState } from '../types';

const getPaginatedReports = (builder: ActionReducerMapBuilder<ExportState>) => {
  builder.addCase(
    asyncThunks.getPaginatedReports.pending,
    (state: ExportState) => {
      state.isLoading = true;
      state.isError = false;
      state.endpoint = constants.endpoints.getPaginatedReports;
    },
  );
  builder.addCase(
    asyncThunks.getPaginatedReports.fulfilled,
    (state: ExportState) => {
      state.isLoading = false;
      state.isError = false;
    },
  );
  builder.addCase(
    asyncThunks.getPaginatedReports.rejected,
    (state: ExportState) => {
      state.isLoading = false;
      state.isError = true;
    },
  );
};

export default getPaginatedReports;
