import Channel0OutputIcon from './Channel0OutputIcon';
import Co2Icon from './Co2Icon';
import ConnectionIcon from './ConnectionIcon';
import GatewayCellIcon from './GatewayCellIcon';
import GatewayConnectionIcon from './GatewayConnectionIcon';
import HumidityIcon from './HumidityIcon';
import LightIcon from './LightIcon';
import O2Icon from './O2Icon';
import PressureIcon from './PressureIcon';
import SpeedIcon from './SpeedIcon';
import TemperatureIcon from './TemperatureIcon';

const sensorTypeIcons = {
  channel_0_output: Channel0OutputIcon,
  co2: Co2Icon,
  co2_sp: Co2Icon,
  connection: ConnectionIcon,
  gateway_cell: GatewayCellIcon,
  gateway_connection: GatewayConnectionIcon,
  humidity: HumidityIcon,
  humidity_sp: HumidityIcon,
  light: LightIcon,
  o2: O2Icon,
  o2_sp: O2Icon,
  pressure: PressureIcon,
  speed: SpeedIcon,
  speed_sp: SpeedIcon,
  temperature: TemperatureIcon,
  temperature_sp: TemperatureIcon,
};

export type SensorTypeKey = keyof typeof sensorTypeIcons;

export default sensorTypeIcons;
