import { cleanup, render, screen } from '@testing-library/react';
import * as React from 'react';

import translations from '../../translations';
import Previous from './previous';

translations.init();

function TestComponent() {
  return <Previous />;
}

describe('previous icon', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the previous icon', () => {
    render(<TestComponent />);
    const svg = screen.getByTestId('previous');

    expect(svg).toBeDefined();
    expect(svg.firstChild.nodeName.toLowerCase()).toBe('path');
  });
});
