import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'react-rails-form-helpers';

const SelectWithOptions = ({ name, options, defaultValue }) => {
  return (
    <Select
      name={name}
      className="select inline-select"
      defaultValue={defaultValue}
    >
      {options.map(([option, value]) => (
        <option value={value} key={value}>
          {option}
        </option>
      ))}
    </Select>
  );
};

SelectWithOptions.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default SelectWithOptions;
