import { apiSlice } from '../../api/apiSlice';
import { User } from '../../interfaces';
import { Credentials, LoginResponse, SSOCheckResponse } from './authTypes';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    checkSSOPath: builder.query<SSOCheckResponse, { email: string }>({
      query: ({ email }) => ({
        method: 'GET',
        params: { email },
        url: '/sso/login_path',
      }),
    }),
    loginUser: builder.mutation<LoginResponse, Credentials>({
      query: (credentials) => ({
        body: {
          app: 'dashboard',
          user: {
            ...credentials,
            remember_me: 0,
          },
        },
        method: 'POST',
        url: '/users/sign_in',
      }),
      transformResponse: (response: User, meta) => {
        return {
          redirectUrl: meta.response.headers.get('location') || null,
          user: response,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLazyCheckSSOPathQuery, useLoginUserMutation } = authApiSlice;
