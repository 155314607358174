import { SensorTypeSetting } from '@interfaces/sensorTypeSetting';
import { useSelector } from 'react-redux';

import { ALERT_DELAY_RANGES, AlertDelayRangeKey } from '../constants';
import { RootState } from '../store';

const getMaxDelayForSetting = (setting: SensorTypeSetting): number => {
  return (
    ALERT_DELAY_RANGES[setting.typeCode as AlertDelayRangeKey]?.minutes?.max ||
    ALERT_DELAY_RANGES.default.minutes.max
  );
};

const getMinDelayForSetting = (setting: SensorTypeSetting): number => {
  return (
    ALERT_DELAY_RANGES[setting.typeCode as AlertDelayRangeKey]?.minutes?.min ||
    ALERT_DELAY_RANGES.default.minutes.min
  );
};

const useSetDelayField = (setting: SensorTypeSetting) => {
  const alertOnFirstOutOfRange = useSelector(
    (state: RootState) => state.alertRule.alertOnFirstOutOfRange,
  );
  const showSetDelay = !alertOnFirstOutOfRange;

  const maxDelay = getMaxDelayForSetting(setting);
  const minDelay = getMinDelayForSetting(setting);

  return { maxDelay, minDelay, showSetDelay };
};

export default useSetDelayField;
