import { SensorTypeSetting } from '@interfaces/sensorTypeSetting';
import { Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { ALERT_SENSOR_TYPE_CODES } from '../constants';
import { RootState } from '../store';
import BatterySensorTypeSettingForm from './BatterySensorTypeSettingForm';
import BooleanSensorTypeSettingForm from './BooleanSensorTypeSettingForm';
import SensorTypeSettingForm from './SensorTypeSettingForm';
import ThresholdSensorTypeSettingForm from './ThresholdSensorTypeSettingForm';

const SensorTypeSettingsGroup: React.FC = () => {
  const existingSensorTypeSettings = useSelector(
    (state: RootState) => state.alertRule.sensorTypeSettings,
  );

  const renderSensorTypeForm = (sensorTypeSetting: SensorTypeSetting) => {
    const { typeCode } = sensorTypeSetting;

    if (typeCode === ALERT_SENSOR_TYPE_CODES.BATTERY) {
      return <BatterySensorTypeSettingForm setting={sensorTypeSetting} />;
    } else if (ALERT_SENSOR_TYPE_CODES.BOOLEAN.includes(typeCode)) {
      return <BooleanSensorTypeSettingForm setting={sensorTypeSetting} />;
    } else if (ALERT_SENSOR_TYPE_CODES.ALL_MINUS_THRESHOLD.includes(typeCode)) {
      return <SensorTypeSettingForm setting={sensorTypeSetting} />;
    } else {
      return <ThresholdSensorTypeSettingForm setting={sensorTypeSetting} />;
    }
  };

  return (
    <Grid
      color={'#4A4848'}
      container
      data-testid="sensor-type-settings-group"
      gap={3}
      marginTop={3}
      spacing={2}
    >
      {existingSensorTypeSettings.map((sensorTypeSetting) => {
        return (
          <Grid
            className={'form-alert-setting'}
            item
            key={sensorTypeSetting.typeCode}
            xs={12}
          >
            {renderSensorTypeForm(sensorTypeSetting)}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SensorTypeSettingsGroup;
