import React from 'react';
import PropTypes from 'prop-types';

const AccountIcon = ({ width = 16, height = 16, color = '#1C5BFE' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.79857 4C3.79857 1.79 5.67873 0 8.00006 0C10.3214 0 12.2015 1.79 12.2015 4C12.2015 6.21 10.3214 8 8.00006 8C5.67873 8 3.79857 6.21 3.79857 4ZM15.9077 14.7551C14.972 12.7433 12.6113 9 8.00006 9C3.38878 9 1.02814 12.7433 0.092397 14.7551C-0.190035 15.3624 0.270872 16 0.940577 16H15.0595C15.7292 16 16.1901 15.3624 15.9077 14.7551Z"
        fill={color}
      />
    </svg>
  );
};

AccountIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default AccountIcon;
