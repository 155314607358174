import { Box, Typography } from '@mui/material';
import React from 'react';

import { ErrorIcon } from '../../../components/icons/other';
import { colors } from '../../colors';
import space from '../../space';

const CustomFormHelperText: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      alignItems="center"
      color={colors['red-450']}
      display="flex"
      mt={'8px'}
    >
      <ErrorIcon
        style={{
          marginRight: space[1],
        }}
      />
      <Typography variant="text-xxs">{children}</Typography>
    </Box>
  );
};

export default CustomFormHelperText;
