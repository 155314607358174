import { configureStore } from '@reduxjs/toolkit';

import api from './api';
import banner from './banner';
import form from './form';
import modal from './modal';
import options from './options';
import range from './range';
import shortcut from './shortcut';

const redux = {
  api,
  banner,
  form,
  modal,
  options,
  range,
  shortcut,
  store: configureStore({
    devTools: true,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
      });
    },
    reducer: {
      api: api.reducer,
      banner: banner.reducer,
      form: form.reducer,
      modal: modal.reducer,
      options: options.reducer,
      range: range.reducer,
      shortcut: shortcut.reducer,
    },
  }),
};

export type RootState = ReturnType<typeof redux.store.getState>;

export default redux;
