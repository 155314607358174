import { render, screen, waitFor } from '@testing-library/react';
import React from 'react';
import { MemoryRouter } from 'react-router-dom';
import { describe, expect, test, vi } from 'vitest';

import { Routes as RoutesEnum } from '../routes';
import AppRoutes from './AppRoutes';

vi.mock('./login/LoginPage', () => ({
  __esModule: true,
  default: () => <div>LoginPage</div>,
}));

describe('AppRoutes', () => {
  test('renders LoginPage for SIGN_IN route', () => {
    render(
      <MemoryRouter initialEntries={[RoutesEnum.SIGN_IN]}>
        <AppRoutes />
      </MemoryRouter>,
    );

    expect(screen.getByText('LoginPage')).toBeInTheDocument();
  });

  test('renders LoginPage for USERS_SIGN_IN route', () => {
    render(
      <MemoryRouter initialEntries={[RoutesEnum.USERS_SIGN_IN]}>
        <AppRoutes />
      </MemoryRouter>,
    );

    expect(screen.getByText('LoginPage')).toBeInTheDocument();
  });

  test('redirects to current path for unmatched routes', async () => {
    const testPath = '/unmatched-route';
    delete window.location;
    window.location = { href: '', pathname: testPath } as Location;

    render(
      <MemoryRouter initialEntries={[testPath]}>
        <AppRoutes />
      </MemoryRouter>,
    );

    await waitFor(() => {
      expect(window.location.href).toBe(testPath);
    });
  });
});
