import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import redux from '../../redux';
import utils from '../../utils';

type DateRangeSyncManagerProps = {
  samplesManagedMachinePath: string;
};

function SyncManager({ samplesManagedMachinePath }: DateRangeSyncManagerProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    const split = samplesManagedMachinePath.split('?');
    const queryString = split[1];
    const searchParams = utils.getJsonFromUrl(queryString);

    const defaultWindowSize = 'all';
    const defaultWindowFunction = 'first';
    const windowSize =
      searchParams['managed_machine[window_size]']?.length > 0
        ? searchParams['managed_machine[window_size]']
        : defaultWindowSize;
    const windowFunction =
      searchParams['managed_machine[window_function]']?.length > 0
        ? searchParams['managed_machine[window_function]']
        : defaultWindowFunction;
    dispatch(redux.options.setWindowSize(windowSize));
    dispatch(redux.form.setWindowSize(windowSize));
    dispatch(redux.options.setWindowFunction(windowFunction));
    dispatch(redux.form.setWindowFunction(windowFunction));
  }, [samplesManagedMachinePath]);

  const handleUpdateCsvLink = (e) => {
    if (e.detail.windowSize?.length > 0) {
      dispatch(redux.options.setWindowSize(e.detail.windowSize));
      dispatch(redux.form.setWindowSize(e.detail.windowSize));
    }

    if (e.detail.windowFunction?.length > 0) {
      dispatch(redux.options.setWindowFunction(e.detail.windowFunction));
      dispatch(redux.form.setWindowFunction(e.detail.windowFunction));
    }
  };

  useEffect(() => {
    window.addEventListener('update-csv-link', handleUpdateCsvLink);
    return () => {
      window.removeEventListener('update-csv-link', handleUpdateCsvLink);
    };
  }, [handleUpdateCsvLink]);

  return null;
}

export default SyncManager;
