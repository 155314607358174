import { Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const sx = {
  color: '#1C5BFE',
  fontFamily: 'Avenir Next',
  fontSize: '13px',
  fontWeight: 700,
  lineHeight: '17.76px',
  marginBottom: '18px',
  marginLeft: '20px',
  marginTop: '-20px',
};

function Title() {
  const { t } = useTranslation();
  return <Typography sx={sx}>{t('files-and-exports-uppercase')}</Typography>;
}

export default Title;
