import { Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const sx = () => ({
  fontFamily: 'Avenir Next',
  fontSize: 14,
  fontWeight: 600,
});

function Title() {
  const { t } = useTranslation();
  return <Typography sx={sx}>{t('select-export-date')}</Typography>;
}

export default Title;
