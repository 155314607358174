import { decodeSearchParams } from '@elemental/pagination-package';

import { DataRecord } from '../redux/data';
import { DataLoadProps } from '../types/pagination';

/**
 * converts encoded querystring to data load options
 */
const routeToDataLoad = (initialDataLoad: DataLoadProps): DataLoadProps => {
  const queryParams = new URLSearchParams(window.location.search);

  const dataLoad = queryParams.get('dataLoad');

  if (!dataLoad) {
    return initialDataLoad;
  }

  const decodedProps = decodeSearchParams<DataRecord>(dataLoad);

  return {
    paginationModel: {
      page: decodedProps.page ?? initialDataLoad.paginationModel.page,
      pageSize: decodedProps.perPage ?? initialDataLoad.paginationModel.page,
    },
    sortModel: decodedProps.sortBy
      ? decodedProps.sortBy.map((entry) => ({
          field: entry.column,
          sort: entry.sortAscending === true ? 'asc' : 'desc',
        }))
      : initialDataLoad.sortModel,
  };
};

export default routeToDataLoad;
