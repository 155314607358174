import { Button } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import redux from '../../redux';

const sx = () => ({
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  backgroundColor: '#FFFFFF',
  border: '1px solid #1A19191A',
  borderRadius: '8px',
  color: '#1C5BFE',
  fontFamily: 'Avenir Next',
  fontSize: 14,
  fontWeight: 600,
  height: 35,
  textTransform: 'none',
  width: 126,
});

function CancelButton() {
  const { t } = useTranslation();
  const dispatch: typeof redux.store.dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(redux.modal.close());
  };

  return (
    <Button onClick={handleModalClose} sx={sx}>
      {t('cancel')}
    </Button>
  );
}

export default CancelButton;
