import { EnhancedStore, configureStore } from '@reduxjs/toolkit';
import { act, fireEvent, render, screen } from '@testing-library/react';
import React from 'react';
import { Provider } from 'react-redux';
import { describe, expect, test, vi } from 'vitest';

import notificationReducer, {
  AddNotificationMessagePayload,
  NotificationState,
  addNotificationMessage,
} from '../../features/notifications/notificationSlice';
import NotificationMessages from './NotificationMessages';

describe('NotificationMessages', () => {
  let store: EnhancedStore<{ notification: NotificationState }>;

  beforeAll(() => {
    vi.useFakeTimers();
  });

  beforeEach(() => {
    store = configureStore({
      reducer: {
        notification: notificationReducer,
      },
    });
  });

  afterAll(() => {
    vi.clearAllTimers();
    vi.useRealTimers(); // Restore real timers
  });

  const renderWithProviders = (ui: React.ReactElement) => {
    return render(<Provider store={store}>{ui}</Provider>);
  };

  test('renders notifications', () => {
    const message: AddNotificationMessagePayload = {
      message: 'Test message',
      severity: 'info',
    };
    store.dispatch(addNotificationMessage(message));

    renderWithProviders(<NotificationMessages />);

    expect(screen.getByTestId('notification-messages')).toBeInTheDocument();
    expect(screen.getByText('Test message')).toBeInTheDocument();
  });

  test('removes notification after timeout', async () => {
    const message: AddNotificationMessagePayload = {
      message: 'Test message',
      severity: 'info',
    };
    store.dispatch(addNotificationMessage(message));

    renderWithProviders(<NotificationMessages />);

    act(() => {
      vi.runAllTimers(); // Fast-forward timers
    });

    expect(screen.queryByText('Test message')).not.toBeInTheDocument();
  });

  test('removes notification when close button is clicked', async () => {
    const message: AddNotificationMessagePayload = {
      message: 'Test message',
      severity: 'info',
    };
    store.dispatch(addNotificationMessage(message));

    renderWithProviders(<NotificationMessages />);

    const closeButton = screen.getByRole('button', { name: /close/i });
    fireEvent.click(closeButton);

    expect(screen.queryByText('Test message')).not.toBeInTheDocument();
  });

  test('cleans up timers on unmount', async () => {
    const message1: AddNotificationMessagePayload = {
      message: 'Test message 1',
      severity: 'info',
    };
    const message2: AddNotificationMessagePayload = {
      message: 'Test message 2',
      severity: 'error',
    };
    store.dispatch(addNotificationMessage(message1));
    store.dispatch(addNotificationMessage(message2));

    const { unmount } = renderWithProviders(<NotificationMessages />);

    act(() => {
      unmount();
    });

    // Ensure the state still has the messages since the component is unmounted
    const state = store.getState() as { notification: NotificationState };
    expect(state.notification.messages).toHaveLength(2);
  });
});
