import { PaletteOptions } from '@mui/material';

import { colors } from './colors';

export const palette: PaletteOptions = {
  error: {
    contrastText: '#FFF',
    dark: colors['red-700'],
    main: colors['red-450'],
  },
  info: {
    contrastText: '#FFF',
    dark: colors['blue-700'],
    main: colors['blue-500'],
  },
  primary: {
    contrastText: '#FFF',
    dark: colors['blue-700'],
    main: colors['blue-500'],
  },
  success: {
    contrastText: '#FFF',
    dark: colors['green-500'],
    main: colors['green-450'],
  },
  warning: {
    contrastText: '#FFF',
    dark: colors['yellow-400'],
    main: colors['yellow-300'],
  },
};
