import * as React from 'react';

function Download(
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      color="#1A191980"
      data-testid="download"
      height={14}
      width={14}
    >
      <path
        clipRule="evenodd"
        d="M12.746 5.156L14 6.4L8 12.356L2 6.4L3.254 5.156L7.104 8.977V0H8.896V8.977L12.746 5.156ZM3.075 16V14.223H12.925V16H3.075Z"
        fill={props.fill}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Download;
