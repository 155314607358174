import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const TemperatureIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M9.329 1.27C9.329.57 8.732 0 8.002 0S6.676.571 6.676 1.27v9.285c-.991.469-1.674 1.444-1.674 2.573 0 1.587 1.344 2.872 3 2.872 1.658 0 3-1.286 3-2.872 0-1.128-.682-2.103-1.673-2.572V1.27z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default TemperatureIcon;
