import { Controller } from 'stimulus';

export default class extends Controller {
	static targets = [
		'dateRangeSelector',
		'dateRangeSelectorWrapper',
		'dayOfReport',
		'dayOfWeekSelector',
		'dailySelector',
		'form',
		'frequency',
		'monthVerbiageSelector',
		'ondemandButtons',
		'onText',
		'preview',
		'runButton',
		'runNowDateFrom',
		'runNowDateTo',
		'saveButton',
		'scheduledButtons',
		'status',
		'validatable',
	];

	connect() {
		this.element[this.identifier] = this;
		window.frequencyController = this;
		this.selectPeriod({ target: { value: this.frequencyTarget.value } });
		this.setupDatePicker();
	}

	setupDatePicker() {
		if (!this.dateRangeSelectorTarget) {
			return;
		}

		$(this.dateRangeSelectorTarget).dateRangePicker({
			autoClose: true,
			endDate: new Date(),
			showShortcuts: true,
			customShortcuts: [
				{
					name: 'last day',
					dates: () => {
						const startDate = moment().endOf('day').add(-1, 'days').toDate();
						const endDate = moment().endOf('day').toDate();
						return [startDate, endDate];
					},
				},
				{
					name: 'last week',
					dates: () => {
						const startDate = moment().endOf('day').add(-7, 'days').toDate();
						const endDate = moment().endOf('day').toDate();
						return [startDate, endDate];
					},
				},
				{
					name: 'last month',
					dates: () => {
						const startDate = moment().endOf('day').add(-30, 'days').toDate();
						const endDate = moment().endOf('day').toDate();
						return [startDate, endDate];
					},
				},
			],
			getValue: () => {
				const periodDateFrom = this.runNowDateFromTarget.value;
				const periodDateTo = this.runNowDateToTarget.value;

				if (periodDateFrom && periodDateTo) {
					return `${periodDateFrom} to ${periodDateTo}`;
				}

				return '';
			},
			setValue: (dateRange, dateFrom, dateTo) => {
				this.dateRangeSelectorTarget.value = dateRange;
				this.runNowDateFromTarget.value = dateFrom;
				this.runNowDateToTarget.value = dateTo;
				this.validateForm();
			},
		});
	}

	get dayOfReportOptions() {
		return JSON.parse(this.data.get('day-of-report-options'));
	}

	set dayOfReportOptions(options) {
		this.data.set(
			'day-of-report-options',
			JSON.stringify(options.map(({ value }) => value)),
		);
		this.dayOfReportTarget.innerHTML = options.reduce(
			(htmlString, { value, text }) =>
				htmlString + this.generateDayOfReportOptionString({ value, text }),
			'',
		);
		this.setDayOfReportVisibility(options.length > 0);
	}

	selectPeriod(e) {
		const period = e.target.value;

		this.ondemandButtonsTarget.classList.add('hidden');
		this.scheduledButtonsTarget.classList.add('hidden');
		this.dateRangeSelectorWrapperTarget.classList.add('hidden');
		this.dayOfWeekSelectorTarget.classList.add('hidden');
		this.monthVerbiageSelectorTarget.classList.add('hidden');
		this.dailySelectorTarget.classList.add('hidden');
		switch (period) {
			case 'now':
				this.ondemandButtonsTarget.classList.remove('hidden');
				this.dateRangeSelectorWrapperTarget.classList.remove('hidden');
				break;
			case 'daily':
				this.scheduledButtonsTarget.classList.remove('hidden');
				this.dailySelectorTarget.classList.remove('hidden');
				break;
			case 'weekly':
				this.scheduledButtonsTarget.classList.remove('hidden');
				this.dayOfWeekSelectorTarget.classList.remove('hidden');
				this.dailySelectorTarget.classList.remove('hidden');
				break;
			case 'monthly':
				this.scheduledButtonsTarget.classList.remove('hidden');
				this.monthVerbiageSelectorTarget.classList.remove('hidden');
				this.dailySelectorTarget.classList.remove('hidden');
				break;
		}
	}

	generateDayOfReportOptionString({ value, text }) {
		return `<option value='${value}'>${text}</option>`;
	}

	setDayOfReportVisibility(visible) {
		if (visible) {
			this.dayOfReportTarget.style.display = '';
			this.onTextTarget.style.display = '';
		} else {
			this.dayOfReportTarget.style.display = 'none';
			this.onTextTarget.style.display = 'none';
		}
	}

	validateForm() {
		if (!this.dateRangeSelectorTarget.value) {
			this.setMapValue('validation', 'frequency-fields', false);
			this.checkEnableOnDemandButtons();
			return false;
		}
		this.setMapValue('validation', 'frequency-fields', true);
		this.checkEnableOnDemandButtons();
		return true;
	}

	reportValidation(component, valid) {
		this.setMapValue('validation', component, valid);
		this.checkEnableOnDemandButtons();
	}

	checkEnableOnDemandButtons() {
		let map = JSON.parse(this.data.get('validation'));

		// TODO I think this should eventually go away and we should check button states for other frequencies
		if (this.frequencyTarget.value !== 'now') return;

		const isValid = Object.values(map).every((e) => e);

		const onDemandButtons = [this.runButtonTarget, this.saveButtonTarget];
		if (isValid) {
			onDemandButtons.forEach((button) => {
				if (button.hasAttribute('disabled')) {
					button.removeAttribute('disabled');
				}
			});
		} else {
			onDemandButtons.forEach((button) => {
				button.setAttribute('disabled', 'disabled');
			});
		}
	}

	savePublished() {
		this.statusTarget.value = 'published';
	}

	submitRunNow(e) {
		e.preventDefault();
		this.previewTarget.value = 'true';
		const form = $(this.formTarget).serializeObject();
		const method = (
			form._method === undefined ? this.formTarget.method : form._method
		).toUpperCase();

		// TODO Move this to a utility method?
		const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
		fetch(this.formTarget.action, {
			method: method,
			headers: {
				'X-CSRF-Token': csrfToken,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify(form),
		})
			.then((response) => response.json())
			.then((json) => {
				if (json.success === true) {
					window.open(json.run_url, '_blank');
					window.location.href = json.redirect_url;
				}
			});
	}

	// TODO This should be in common code
	setMapValue(dataName, key, value) {
		let map = JSON.parse(this.data.get(dataName));
		if (map === null) {
			map = {};
		}
		map[key] = value;
		this.data.set(dataName, JSON.stringify(map));
	}
}
