import { Controller } from 'stimulus';

export default class extends Controller {
	static targets = ['edit', 'form', 'submitButton'];

	toggleModal(e) {
		e.preventDefault();
		$.ajax({
			cache: false,
			url: Routes.edit_customer_tag_path(e.currentTarget.dataset.id),
			success: (data) => {
				$('#modal-window').html(data.html).modal();
			},
		});
	}

	updateFormSubmit(e) {
		e.preventDefault();
		let formBody = $(this.formTarget).serializeObject();

		$.ajax({
			cache: false,
			type: 'put',
			data: formBody,
			url: e.target.dataset.url,
			success: (data) => {
				$('#modal-window .close-modal').click();
				$('#customer_tags-table_wrapper').html(data.html);
				new DataTables('#customer_tags-table');
			},
		});
	}
}
