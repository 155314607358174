import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export interface NotificationMessage {
  id: string;
  message: string;
  severity: 'error' | 'info' | 'success' | 'warning';
}

export interface NotificationState {
  messages: NotificationMessage[];
}

export interface AddNotificationMessagePayload
  extends Omit<NotificationMessage, 'id'> {}

const initialState: NotificationState = {
  messages: [],
};

const notificationSlice = createSlice({
  initialState,
  name: 'notification',
  reducers: {
    addNotificationMessage: (
      state,
      action: PayloadAction<AddNotificationMessagePayload>,
    ) => {
      const id = uuidv4();
      state.messages.push({ id, ...action.payload });
    },
    removeNotificationMessage: (state, action: PayloadAction<string>) => {
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload,
      );
    },
  },
});

export const { addNotificationMessage, removeNotificationMessage } =
  notificationSlice.actions;

export default notificationSlice.reducer;
