import { Controller } from 'stimulus';

export default class extends Controller {
	save(e) {
		e.preventDefault();
		e.stopPropagation();
		const url = e.currentTarget.href;
		const $button = $(e.currentTarget);
		const previousHtml = $button.html();
		$button.html(
			`<span>
				<i class='fa fa-spinner fa-pulse fa-fw'/>
				${I18n.t('general.saving')}
			</span>`,
		);

		const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
		fetch(url, {
			method: 'PATCH',
			headers: {
				'X-CSRF-Token': csrfToken,
			},
		})
			.then((response) => response.json())
			.then((json) => {
				$button.html(previousHtml);
				window.flash(json.message);
			});
	}
}
