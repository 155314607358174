import { cleanup, render, screen } from '@testing-library/react';
import * as React from 'react';
import { Provider } from 'react-redux';

import redux from '../../../redux';
import translations from '../../../translations';
import WindowFunction from './window-function';

function TestComponent() {
  return (
    <Provider store={redux.store}>
      <WindowFunction />
    </Provider>
  );
}

describe('window function', () => {
  beforeAll(() => {
    translations.init();
  });

  afterEach(() => {
    cleanup();
  });

  test('window function value set to maximum', () => {
    redux.store.dispatch(redux.options.setWindowFunction('max'));
    render(<TestComponent />);
    const element = screen.getByText('Display Maximum Value');
    expect(element).toBeDefined();
    expect(element.textContent).toBe('Display Maximum Value');
  });

  test('window function value set to last', () => {
    redux.store.dispatch(redux.options.setWindowFunction('last'));
    render(<TestComponent />);
    const element = screen.getByText('Display Last Value');
    expect(element).toBeDefined();
    expect(element.textContent).toBe('Display Last Value');
  });

  test('window function value set to average', () => {
    redux.store.dispatch(redux.options.setWindowFunction('mean'));
    render(<TestComponent />);
    const element = screen.getByText('Display Average Value');
    expect(element).toBeDefined();
    expect(element.textContent).toBe('Display Average Value');
  });

  test('window function value set to minimum', () => {
    redux.store.dispatch(redux.options.setWindowFunction('min'));
    render(<TestComponent />);
    const element = screen.getByText('Display Minimum Value');
    expect(element).toBeDefined();
    expect(element.textContent).toBe('Display Minimum Value');
  });

  test('window function value set to first', () => {
    redux.store.dispatch(redux.options.setWindowFunction('first'));
    render(<TestComponent />);
    const element = screen.getByText('Display First Value');
    expect(element).toBeDefined();
    expect(element.textContent).toBe('Display First Value');
  });
});
