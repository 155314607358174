import React from 'react';
import PropTypes from 'prop-types';

const SmsIcon = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C12.418 0 16 2.91 16 6.502C16 8.909 14.39 11.01 12 12.134L8 16V13.003L7.736 13C3.44 12.887 0 10.02 0 6.502C0 2.91 3.582 0 8 0ZM4 5.501C3.448 5.501 3 5.949 3 6.501C3 7.054 3.448 7.501 4 7.501C4.552 7.501 5 7.054 5 6.501C5 5.949 4.552 5.501 4 5.501ZM8 5.501C7.448 5.501 7 5.949 7 6.501C7 7.054 7.448 7.501 8 7.501C8.552 7.501 9 7.054 9 6.501C9 5.949 8.552 5.501 8 5.501ZM12 5.501C11.448 5.501 11 5.949 11 6.501C11 7.054 11.448 7.501 12 7.501C12.552 7.501 13 7.054 13 6.501C13 5.949 12.552 5.501 12 5.501Z"
        fill="white"
      />
    </svg>
  );
};

SmsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SmsIcon;
