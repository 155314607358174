import dataLoadToRoute from './data-load-to-route';

describe('utils', () => {
  test('it should call window.history.replaceState', () => {
    const spy = vi.spyOn(window.history, 'replaceState').mockReturnValue();
    expect(() =>
      dataLoadToRoute({
        paginationModel: {
          page: 1,
          pageSize: 200,
        },
        sortModel: [
          {
            field: 'hello',
            sort: 'asc',
          },
        ],
      }),
    ).not.toThrow();
    expect(spy).toHaveBeenCalledTimes(1);
  });
});
