import * as React from 'react';

function Previous(
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      color="#1A191980"
      data-testid="previous"
      height={14}
      width={14}
    >
      <path
        d="M17 12L13.3536 15.6464C13.1583 15.8417 13.1583 16.1583 13.3536 16.3536L17 20"
        stroke="#A1A5AD"
        strokeDasharray="0.2 0.2"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default Previous;
