import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import asyncThunks from '../async-thunks';
import { BannerState } from '../types';

const setContent = (builder: ActionReducerMapBuilder<BannerState>) => {
  builder.addCase(
    asyncThunks.setContent.fulfilled,
    (state: BannerState, action) => {
      state.content = action.payload.content;
    },
  );
};

export default setContent;
