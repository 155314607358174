import { encodeSearchParams } from '@elemental/pagination-package';
import { faker } from '@faker-js/faker';

import { DataLoadProps, SearchParams } from '../types/pagination';
import routeToDataLoad from './route-to-data-load';

const column1 = faker.string.uuid();
const column2 = faker.string.uuid();

const initialParams: DataLoadProps = {
  paginationModel: {
    page: 0,
    pageSize: 100,
  },
  sortModel: [
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ],
};

const mockSearchParams: SearchParams<Record<string, string>> = {
  filters: [
    {
      column: column1,
      op: 'eq',
      value: faker.date.anytime().toISOString(),
    },
  ],
  page: faker.number.int(),
  perPage: faker.number.int(),
  projection: [column2, column1],
  sortBy: [
    {
      column: column2,
      sortAscending: faker.datatype.boolean(),
    },
  ],
};

describe('route to data load', () => {
  test('test with only initial params', () => {
    expect(routeToDataLoad(initialParams)).toStrictEqual(initialParams);
  });

  test('test with mocked search params', () => {
    const queryParams = new URLSearchParams(window.location.search);

    queryParams.delete('dataLoad');

    const newUrl = `${
      window.location.pathname
    }?${queryParams.toString()}&dataLoad=${encodeSearchParams(
      mockSearchParams,
    )}`;

    window.history.replaceState({}, '', newUrl);

    expect(routeToDataLoad(initialParams)).toStrictEqual({
      paginationModel: {
        page: mockSearchParams.page,
        pageSize: mockSearchParams.perPage,
      },
      sortModel: [
        {
          field: mockSearchParams.sortBy?.[0].column,
          sort: mockSearchParams.sortBy?.[0].sortAscending ? 'asc' : 'desc',
        },
      ],
    });
  });
});
