import i18next from 'i18next';

import translations from '../translations';

describe('translations', () => {
  test('it should apply translations middleware', () => {
    const spy = vi.spyOn(i18next, 'use');
    translations.init();
    expect(spy).toHaveBeenCalledTimes(2);
  });

  test('it should init translations', () => {
    const spy = vi.spyOn(i18next, 'init');
    translations.init();
    expect(spy).toHaveBeenCalledTimes(1);
  });
});
