import { faker } from '@faker-js/faker';
import {
  cleanup,
  queryByTestId,
  render,
  screen,
  waitForElementToBeRemoved,
} from '@testing-library/react';
import * as React from 'react';

import MFEGuestIframe from './index';
import redux from './redux';
import { DashboardWindow } from './types';
import utils from './utils';

function TestComponent() {
  return (
    <MFEGuestIframe
      appName={faker.word.noun()}
      appURL={faker.internet.url()}
      currentCustomerGroup={{
        customer_id: faker.number.int({ min: 1 }),
        name: faker.word.noun(),
        slug: faker.word.noun(),
        uuid: faker.string.uuid(),
      }}
    />
  );
}

describe('mfe guest iframe startup', () => {
  afterEach(() => {
    cleanup();
  });

  test('startup done', () => {
    const spy = vi.fn();
    (window as DashboardWindow).groupSwitcher = {
      setDropdownBackdrop: spy,
    };
    vi.spyOn(utils.webAppMessaging.adapter, 'loadGuestApp').mockResolvedValue(
      {} as never,
    );

    render(<TestComponent />);

    const iframe = screen.getAllByTestId('guest-app-iframe');
    expect(iframe[0]).toBeDefined();

    expect(spy).toHaveBeenCalledTimes(1);

    expect(redux.store.getState().guestApp.initError).toBeFalsy();
  });

  test('startup error', async () => {
    const spy = vi.fn();
    (window as DashboardWindow).groupSwitcher = {
      setDropdownBackdrop: spy,
    };
    const errorMessage = faker.lorem.sentence();
    vi.spyOn(utils.webAppMessaging.adapter, 'loadGuestApp').mockRejectedValue(
      new Error(errorMessage),
    );
    const dom = render(<TestComponent />);

    expect(spy).toHaveBeenCalledTimes(1);
    await waitForElementToBeRemoved(
      queryByTestId(dom.container, 'guest-app-iframe'),
    );
    expect(redux.store.getState().guestApp.initError).toBeTruthy();
    expect(redux.store.getState().guestApp.errorMessage).toContain(
      errorMessage,
    );
  });
});
