import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const GatewayCellIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M11.969 21a2.99 2.99 0 0 1-2.98-3c0-1.657 1.334-3 2.98-3a2.99 2.99 0 0 1 2.98 3c0 1.657-1.334 3-2.98 3zM23.68 8.725c.51.65.398 1.594-.248 2.106-.647.513-1.583.4-2.093-.25A11.867 11.867 0 0 0 11.97 6c-3.671 0-7.065 1.68-9.315 4.51a1.483 1.483 0 0 1-2.095.235 1.507 1.507 0 0 1-.232-2.108C3.137 5.1 7.383 3 11.969 3c4.625 0 8.904 2.137 11.711 5.725zm-5.199 3.108a1.507 1.507 0 0 1-.064 2.12c-.6.568-1.542.54-2.106-.064A5.92 5.92 0 0 0 11.969 12a5.92 5.92 0 0 0-4.307 1.852 1.483 1.483 0 0 1-2.107.047 1.507 1.507 0 0 1-.046-2.121A8.89 8.89 0 0 1 11.969 9a8.89 8.89 0 0 1 6.512 2.833z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default GatewayCellIcon;
