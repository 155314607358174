import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Routes as RoutesEnum } from '../routes';
import LoginPage from './login/LoginPage';

const RedirectToRails = () => {
  const location = useLocation();

  useEffect(() => {
    window.location.href = location.pathname + location.search;
  }, [location]);

  return null;
};

const AppRoutes: React.FC = () => (
  <Routes>
    <Route element={<LoginPage />} path={RoutesEnum.SIGN_IN} />
    <Route element={<LoginPage />} path={RoutesEnum.USERS_SIGN_IN} />
    <Route element={<RedirectToRails />} path="*" /> {/* Catch-all route */}
  </Routes>
);

export default AppRoutes;
