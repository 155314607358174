import * as React from 'react';

function Next(
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) {
  return (
    <svg
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      color="#1A191980"
      data-testid="next"
      height={14}
      width={14}
    >
      <path
        d="M14.5 20L18.1464 16.3536C18.3417 16.1583 18.3417 15.8417 18.1464 15.6464L14.5 12"
        stroke="#444C5B"
        strokeDasharray="0.2 0.2"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default Next;
