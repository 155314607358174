import { Button } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type CancelButtonProps = {
  setAnchorEl: (anchorEl: HTMLDivElement | null) => void;
};

const sx = () => ({
  '&:hover': {
    backgroundColor: '#FFFFFF',
  },
  backgroundColor: '#FFFFFF',
  border: '1px solid #1A19191A',
  borderRadius: '8px',
  color: '#1C5BFE',
  fontFamily: 'Avenir Next',
  fontSize: 14,
  fontWeight: 600,
  height: 35,
  textTransform: 'none',
  width: 126,
});

function CancelButton({ setAnchorEl }: Readonly<CancelButtonProps>) {
  const { t } = useTranslation();

  const handleCancel = () => {
    setAnchorEl(null);
  };

  return (
    <Button onClick={handleCancel} sx={sx}>
      {t('cancel')}
    </Button>
  );
}

export default CancelButton;
