import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const HumidityIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        d="M12.725 7.734l.003-.007L8.03 0 3.274 7.821h.01c-.497.851-.784 1.728-.784 2.755C2.5 13.586 4.963 16 8 16s5.5-2.452 5.5-5.463c0-1.02-.283-1.987-.776-2.803"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default HumidityIcon;
