import { configureStore } from '@reduxjs/toolkit';

import {
  alertRuleFormReducer,
  alertRuleReducer,
} from '../../features/alertRules';

// TODO this file should be moved to upper levels once we have the whole page
// fully converted to React/Redux
const store = configureStore({
  reducer: {
    alertRule: alertRuleReducer,
    alertRuleForm: alertRuleFormReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
