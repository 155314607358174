import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const InfoIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <circle
      cx="12"
      cy="12"
      fill="none"
      r="8"
      stroke="currentColor"
      strokeWidth="1.5"
      transform="rotate(-180 12 12)"
    />
    <path
      clipRule="evenodd"
      d="M12 10.2454C12.5523 10.2454 13 10.6931 13 11.2454V15.7498C13 16.302 12.5523 16.7498 12 16.7498C11.4477 16.7498 11 16.302 11 15.7498V11.2454C11 10.6931 11.4477 10.2454 12 10.2454Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <circle
      cx="12"
      cy="8.24536"
      fill="currentColor"
      r="1"
      transform="rotate(-180 12 8.24536)"
    />
  </SvgIcon>
);

export default InfoIcon;
