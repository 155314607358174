import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const O2Icon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M3.3 11.124a3.529 3.529 0 0 1 2.794-3.759 3.929 3.929 0 0 1 2.216.177 6.752 6.752 0 0 1 12.58 1.992A5.622 5.622 0 0 1 24 14.566 6.433 6.433 0 0 1 17.567 21H4.825A4.825 4.825 0 0 1 0 16.175a5.516 5.516 0 0 1 3.3-5.051zm12.346 3.8c0-.02-.004-.062-.004-.095 0-.349.246-.873.652-.873.381 0 .66.282.66.623 0 .419-.32.747-.611 1.042l-1.99 2.037v.913h4.127v-1.166h-2.15v-.062l1.055-1.112c.447-.47 1.009-.955 1.009-1.802 0-.921-.812-1.622-2.072-1.622-1.435 0-2.054 1.167-2.054 2.035v.083h1.378zm-2.146-1.48v-.331c0-.572-.073-1.087-.22-1.545a3.476 3.476 0 0 0-.617-1.167 2.679 2.679 0 0 0-.948-.736 2.853 2.853 0 0 0-1.215-.256c-.442 0-.847.085-1.215.256-.367.17-.683.416-.948.736-.264.32-.47.71-.617 1.167-.147.458-.22.973-.22 1.545v.33c0 .575.074 1.09.223 1.547.148.456.355.843.621 1.16.266.317.583.56.951.73.368.171.773.256 1.215.256.442 0 .846-.085 1.214-.255.368-.17.683-.414.946-.731.263-.317.467-.704.612-1.16.145-.456.218-.972.218-1.547zm-1.5-.329v.306c0 .706-.128 1.244-.384 1.615-.257.37-.625.555-1.107.555-.24 0-.454-.047-.64-.141a1.268 1.268 0 0 1-.473-.417 2.057 2.057 0 0 1-.294-.68A3.833 3.833 0 0 1 9 13.421v-.306c0-.706.13-1.243.39-1.61.259-.367.629-.55 1.11-.55.481 0 .851.183 1.11.55.26.367.39.904.39 1.61z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default O2Icon;
