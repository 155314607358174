import { faker } from '@faker-js/faker';
import { cleanup, render } from '@testing-library/react';
import dayjs from 'dayjs';
import * as React from 'react';
import { Provider } from 'react-redux';

import redux from '../../redux';
import SyncManager from './index';

const dateFrom = dayjs().subtract(1, 'days').format('YYYY-MM-DD');
const dateTo = dayjs().subtract(1, 'days').format('YYYY-MM-DD');
const windowSize = '1h';
const windowFunction = 'mean';

function TestComponent() {
  return (
    <Provider store={redux.store}>
      <SyncManager
        samplesManagedMachinePath={`${faker.internet.url()}/?managed_machine[period_date_from]=${dateFrom}`}
      />
    </Provider>
  );
}

describe('date range sync manager', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the date range sync manager and receive the event', () => {
    render(<TestComponent />);

    const spyOnOptionsSetWindowSize = vi.spyOn(redux.options, 'setWindowSize');
    const spyOnFormSetWindowSize = vi.spyOn(redux.form, 'setWindowSize');
    const spyOnOptionsSetWindowFunction = vi.spyOn(
      redux.options,
      'setWindowFunction',
    );
    const spyOnFormSetWindowFunction = vi.spyOn(
      redux.form,
      'setWindowFunction',
    );

    const event = new CustomEvent('update-csv-link', {
      detail: {
        dateFrom,
        dateTo,
        windowFunction,
        windowSize,
      },
    });
    window.dispatchEvent(event);

    expect(spyOnOptionsSetWindowSize).toHaveBeenCalledTimes(1);
    expect(spyOnFormSetWindowSize).toHaveBeenCalledTimes(1);
    expect(spyOnOptionsSetWindowFunction).toHaveBeenCalledTimes(1);
    expect(spyOnFormSetWindowFunction).toHaveBeenCalledTimes(1);
    expect(redux.store.getState().options.windowSize).toStrictEqual(windowSize);
    expect(redux.store.getState().form.windowSize).toStrictEqual(windowSize);
    expect(redux.store.getState().options.windowFunction).toStrictEqual(
      windowFunction,
    );
    expect(redux.store.getState().form.windowFunction).toStrictEqual(
      windowFunction,
    );
  });
});
