import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const WarningIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12 9.28013V12.8349M12 16.3896H12.0088M10.7858 4.74048L3.57337 17.3657C3.17332 18.066 2.9733 18.4161 3.00286 18.7035C3.02865 18.9541 3.15823 19.1819 3.35935 19.3301C3.58994 19.5 3.98916 19.5 4.7876 19.5H19.2124C20.0108 19.5 20.4101 19.5 20.6406 19.3301C20.8418 19.1819 20.9714 18.9541 20.9971 18.7035C21.0267 18.4161 20.8267 18.066 20.4266 17.3657L13.2142 4.74048C12.8156 4.04271 12.6163 3.69383 12.3563 3.57666C12.1295 3.47445 11.8705 3.47445 11.6437 3.57666C11.3837 3.69383 11.1844 4.04272 10.7858 4.74048Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </SvgIcon>
);

export default WarningIcon;
