import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import translations from '../../../translations';
import CopyMachinesDialogContent from './CopyMachinesDialogContent';
import { checkMachineCounts } from './checkMachineCounts';
import { handleCopyMachines } from './handleCopyMachines';

export const CUSTOMER_LIST = '/customer_list';
export const CUSTOMER_MANAGED_MACHINES = (customerId: number) =>
  `/customers/${customerId}/managed_machines`;
export const MACHINE_CUSTOMER_COUNTS = '/customers/machine_customer_counts';

translations.init();

const CopyMachinesDialog = ({ handleClose, machineIds, show }) => {
  const { t } = useTranslation('partners');

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [newMachines, setNewMachines] = useState([]);
  const [copyDone, setCopyDone] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);

  useMemo(async () => {
    const dupes = await checkMachineCounts(copyDone, machineIds);
    if (dupes > 0) {
      setWarningMessage({
        message: t('warning', { count: dupes }),
        severity: 'warning',
      });
    } else {
      setWarningMessage(null);
    }
  }, [machineIds, copyDone]);

  const dialogHandleClose = () => {
    setSelectedCustomer(null);
    setNewMachines([]);
    setCopyDone(false);
    setErrorMessage(null);
    handleClose();
  };

  return (
    <Dialog
      data-testid="dialog"
      fullWidth={true}
      maxWidth={'md'}
      onClose={dialogHandleClose}
      open={show}
    >
      <DialogTitle>
        <span className={'title'}>{t('copyMachines')}</span>
      </DialogTitle>
      <DialogContent>
        <CopyMachinesDialogContent
          copyDone={copyDone}
          errorMessage={errorMessage}
          machineIds={machineIds}
          newMachines={newMachines}
          selectedCustomer={selectedCustomer}
          setErrorMessage={setErrorMessage}
          setSelectedCustomer={setSelectedCustomer}
          t={t}
        />
        {warningMessage && (
          <Alert severity={warningMessage.severity} sx={{ mt: 3 }}>
            {warningMessage.message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
        }}
      >
        {copyDone ? (
          <Button onClick={dialogHandleClose}>OK</Button>
        ) : (
          <>
            <Button onClick={dialogHandleClose}>Cancel</Button>
            <Button
              disabled={!selectedCustomer}
              onClick={() =>
                handleCopyMachines(
                  selectedCustomer,
                  machineIds,
                  setNewMachines,
                  setErrorMessage,
                  setSelectedCustomer,
                  setCopyDone,
                )
              }
            >
              Copy
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

CopyMachinesDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  machineIds: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
};

export default CopyMachinesDialog;
