import { Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const sx = () => ({
  color: '#444C5B',
  fontFamily: 'Avenir Next',
  fontSize: 14,
  fontWeight: 600,
  letterSpacing: 0.15000000596046448,
  lineHeight: '24px',
  textTransform: 'none',
});

function ShortcutsTitle() {
  const { t } = useTranslation();
  return <Typography sx={sx}>{t('shortcuts')}</Typography>;
}

export default ShortcutsTitle;
