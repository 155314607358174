import * as React from 'react';
import { forwardRef } from 'react';

type IframeProps = {
  source: string;
};

const GuestIframe = forwardRef(
  (props: IframeProps, ref: React.ForwardedRef<HTMLIFrameElement>) => (
    <iframe
      data-testid="guest-app-iframe"
      id="guest-app"
      ref={ref}
      style={{
        border: 0,
        display: !props.source ? 'none' : 'block',
      }}
      title="guest-app"
    />
  ),
);

GuestIframe.displayName = 'GuestIframe';

export default GuestIframe;
