import {
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { createSlice } from '@reduxjs/toolkit';

import columns from '../data/columns';

export type DataRecord = {
  createdAt: string;
  dateEnd: string;
  dateStart: string;
  id: string;
  name: string;
  status: string;
  updatedAt: string;
  userFullName: string;
};

type DataState = {
  columns: GridColDef[];
  paginationModel: GridPaginationModel;
  records: DataRecord[];
  rowCount: number;
  sortModel: GridSortModel;
  userTimeZone: string;
};

const initialState: DataState = {
  columns,
  paginationModel: {
    page: 0,
    pageSize: 100,
  },
  records: [],
  rowCount: 0,
  sortModel: [
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ],
  userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

const slice = createSlice({
  initialState,
  name: 'data',
  reducers: {
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    setPaginationModel: (state, action) => {
      state.paginationModel = action.payload;
    },
    setRecords: (state, action) => {
      state.records = action.payload;
    },
    setRowCount: (state, action) => {
      state.rowCount = action.payload;
    },
    setSortModel: (state, action) => {
      state.sortModel = action.payload;
    },
    setUserTimezone: (state, action) => {
      state.userTimeZone = action.payload;
    },
  },
});

const data = {
  reducer: slice.reducer,
  setColumns: slice.actions.setColumns,
  setPaginationModel: slice.actions.setPaginationModel,
  setRecords: slice.actions.setRecords,
  setRowCount: slice.actions.setRowCount,
  setSortModel: slice.actions.setSortModel,
  setUserTimeZone: slice.actions.setUserTimezone,
};

export default data;
