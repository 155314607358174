import { Box, Modal, Stack } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux';
import CancelButton from './cancel-button';
import ControlledDateRangePicker from './controlled-date-range-picker';
import EmailOption from './email-option';
import ExportButton from './export-button';
import SampleRateDropdown from './sample-rate-dropdown';
import Shortcut from './shortcut';
import ShortcutsTitle from './shortcuts-title';
import Title from './title';
import ValidationMessage from './validation-message';

const sx = {
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  left: '50%',
  p: '20px',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
};

function ExportCsvModal() {
  const modalOpen = useSelector((state: RootState) => state.modal.isOpen);
  const shortcuts = useSelector((state: RootState) => state.shortcut.choices);

  return (
    <Modal
      aria-describedby="modal-modal-description"
      aria-labelledby="modal-modal-title"
      data-testid="export-csv-modal"
      open={modalOpen}
    >
      <Box sx={sx}>
        <Stack direction="column" spacing="20px">
          <Title />
          <ControlledDateRangePicker />
          <Stack direction="row" gap="20px">
            <ShortcutsTitle />
            {shortcuts.map((item) => (
              <Shortcut item={item} key={item} />
            ))}
          </Stack>
          <SampleRateDropdown />
          <EmailOption />
          <ValidationMessage />
          <Stack direction="row" justifyContent="space-between">
            <CancelButton />
            <ExportButton />
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

export default ExportCsvModal;
