import { faker } from '@faker-js/faker';
import { Factory } from 'fishery';

import {
  ALERT_DELAY_RANGES,
  ALERT_SENSOR_TYPE_CODES,
} from '../components/alerts/constants';
import {
  DATA_TYPE_BOOLEAN,
  SensorDataType,
  SensorTypeSetting,
} from '../interfaces';

class SensorTypeSettingFactory extends Factory<SensorTypeSetting> {
  battery() {
    return this.params({
      dataType: 'integer',
      name: 'Battery',
      repeatInterval: faker.number.int({
        max: 15,
        min: 1,
      }),
      typeCode: ALERT_SENSOR_TYPE_CODES.BATTERY,
      unit: '%',
    });
  }

  boolean() {
    return this.params({
      booleanValue: faker.datatype.boolean(),
      dataType: DATA_TYPE_BOOLEAN,
      name: 'Boolean Sensor',
      setDelay: faker.number.int({
        max:
          ALERT_DELAY_RANGES['dry_contact_closed'].minutes.max * 60 +
          ALERT_DELAY_RANGES['dry_contact_closed'].seconds.max,
        min:
          ALERT_DELAY_RANGES['dry_contact_closed'].minutes.min * 60 +
          ALERT_DELAY_RANGES['dry_contact_closed'].seconds.min,
      }),
      typeCode: 'dry_contact_closed',
      unit: 'Status',
    });
  }

  gatewayConnection() {
    return this.params({
      dataType: undefined,
      name: 'Gateway Connection',
      setDelay: faker.number.int({
        max: ALERT_DELAY_RANGES['gateway_connection'].minutes.max,
        min: ALERT_DELAY_RANGES['gateway_connection'].minutes.min,
      }),
      typeCode: 'gateway_connection',
      unit: undefined,
    });
  }

  humidity() {
    return this.params({
      name: 'Humidity',
      setDelay: faker.number.int({
        max: ALERT_DELAY_RANGES['humidity'].minutes.max,
        min: ALERT_DELAY_RANGES['humidity'].minutes.min,
      }),
      typeCode: 'humidity',
      unit: '%RH',
    });
  }
}

// By default it's a temperature sensor type
const sensorTypeSettingFactory = SensorTypeSettingFactory.define(() => ({
  dataType: 'float' as SensorDataType,
  higherLimit: faker.number.float({ max: 100, min: 50 }),
  id: faker.number.int(),
  lowerLimit: faker.number.float({ max: 50, min: 0 }),
  name: 'Temperature',
  setDelay: faker.number.int({
    max: ALERT_DELAY_RANGES['temperature'].minutes.max,
    min: ALERT_DELAY_RANGES['temperature'].minutes.min,
  }),
  typeCode: 'temperature',
  unit: 'ºC',
}));

export default sensorTypeSettingFactory;
