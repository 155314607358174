import { Components } from '@mui/material';

import { colors } from '../colors';
import { lightMode } from '../mode';
import { shadows } from '../shadows';
import space from '../space';
import typography from '../typography';

export const MuiTextField: Components['MuiTextField'] = {
  styleOverrides: {
    root: {
      '& .MuiFormLabel-root': {
        zIndex: 1,
      },
      '& .MuiInput-underline': {
        '&:after': {
          borderBottomColor: colors['blue-500'],
          borderWidth: 1,
          transition: 'initial',
          webkitTransition: 'initial',
        },
        '&:before': {
          borderBottom: `1px solid ${colors['gray-400']} !important`,
        },
        '&:hover': {
          '&:before': {
            borderBottom: `1px solid ${colors['gray-400']} !important`,
          },
        },
      },
      '& .MuiInputAdornment-root': {
        height: '34px',
        paddingBottom: '0',
        paddingTop: '2px',
      },
      '& .MuiInputBase-multiline': {
        '& fieldset': {
          height: 'unset !important',
        },
        height: 'unset !important',
        maxHeight: 'unset !important',
      },
      '& .MuiInputBase-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: colors['darkBlue-200'],
          borderRadius: 6,
          borderWidth: 1,
          shadow: shadows[0],
          top: '0',
        },
        '& fieldset': {
          '& legend': {
            display: 'none',
          },
        },
        background: colors.white,
        borderColor: colors['darkBlue-200'],
        cursor: 'text',
        letterSpacing: 'normal',
        marginTop: space[2],
        maxWidth: '100%',
        ...typography['text-s-medium'],
        '& .MuiInput-input': {
          height: '20px',
          paddingBottom: space[3],
          paddingTop: space[3],
        },
      },
      '& .MuiInputBase-root.Mui-error fieldset': {
        borderColor: lightMode['input-error-text-color'],
      },
      '& .MuiInputBase-root.Mui-error.MuiInput-underline:before': {
        borderColor: `${lightMode['input-error-text-color']} !important`,
      },
      '& .MuiInputLabel-outlined': {
        '&.Mui-focused': {
          color: 'rgb(68, 76, 91) !important',
        },
      },
      '& .MuiInputLabel-root': {
        color: colors['gray-700'],
        ...typography['text-xxs-medium-caps'],
      },
      '& .MuiInputLabel-shrink': {
        '&.Mui-focused': {
          color: colors['gray-450'],
        },
        color: colors['gray-450'],
        ...typography['text-xxs-medium-caps'],
        letterSpacing: '0.16em',
      },
      '& .MuiInputLabel-standard': {
        cursor: 'text',
        letterSpacing: '0.16em',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colors['darkBlue-200'],
          borderRadius: '6px',
          borderWidth: 1,
        },
        '& input': {
          marginTop: '6px',
          paddingBottom: '6px',
          paddingTop: '6px',
        },
        '&.Mui-focused fieldset': {
          borderColor: colors['blue-500'],
          borderRadius: '6px',
          borderWidth: 1,
        },
        '&.Mui-focused label': {
          color: 'black',
        },
        '&:hover fieldset': {
          borderColor: colors['darkBlue-200'],
          borderRadius: '6px',
          borderWidth: 1,
        },
      },
      '& .MuiOutlinedInput-root input': {
        borderRadius: '5px',
        marginTop: '0px !important',
      },
    },
  },
};
