import { cleanup, fireEvent, render, screen } from '@testing-library/react';
import * as React from 'react';
import { Provider } from 'react-redux';

import redux from '../../../redux';
import translations from '../../../translations';
import WindowFunctionRadioGroup from './window-function-radio-group';

translations.init();

function TestComponent() {
  return (
    <Provider store={redux.store}>
      <WindowFunctionRadioGroup />
    </Provider>
  );
}

describe('window function radio group', () => {
  afterEach(() => {
    cleanup();
  });

  test('window function radio group should be displayed with the proper values and respond to click events', () => {
    // render the component
    render(<TestComponent />);

    // get reference to the radio button
    const button: HTMLInputElement = screen.getByRole('radio', {
      name: 'Average',
    });

    // ensure that the reference exists
    expect(button).toBeDefined();

    // emulate user action
    fireEvent.click(button);

    // run tests
    expect(redux.store.getState().form.windowFunction).toStrictEqual('mean');
  });
});
