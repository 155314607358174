import * as React from 'react';

type LogoProps = {
  collapsedLogoUrl: string;
  logoUrl: string;
  menuCollapsed: boolean;
};

const Logo = ({ collapsedLogoUrl, logoUrl, menuCollapsed }: LogoProps) => (
  <>
    {menuCollapsed && <img alt={'collapsed logo'} src={collapsedLogoUrl} />}
    {!menuCollapsed && <img alt={'logo'} src={logoUrl} />}
  </>
);

export default Logo;
