import { SensorTypeSetting } from '@interfaces/sensorTypeSetting';
import { fireEvent, render, screen, waitFor } from '@testing-library/react';
import React from 'react';
import { Provider } from 'react-redux';
import { beforeAll, beforeEach, describe, expect, it, vi } from 'vitest';

import alertRuleFactory from '../../../factories/alertRuleFactory';
import sensorTypeSettingFactory from '../../../factories/sensorTypeSettingFactory';
import {
  setSensorTypes,
  setValidSensorTypeSettings,
} from '../../../features/alertRules';
import translations from '../../../translations';
import store from '../store';
import SensorTypesSelection from './SensorTypesSelection';

describe('SensorTypesSelection', () => {
  let validSensorTypeSettings: SensorTypeSetting[];

  beforeAll(() => {
    translations.init();
  });

  beforeEach(() => {
    const mockAlertRule = alertRuleFactory.build();

    validSensorTypeSettings = [
      ...mockAlertRule.sensorTypeSettings,
      sensorTypeSettingFactory.boolean().build(),
      sensorTypeSettingFactory.gatewayConnection().build(),
      sensorTypeSettingFactory.battery().build(),
    ];

    // Dispatch mock data to the store
    store.dispatch(setValidSensorTypeSettings(validSensorTypeSettings));
  });

  afterEach(() => {
    // Reset the Redux store
    store.dispatch(setSensorTypes([]));
  });

  const setup = () => {
    render(
      <Provider store={store}>
        <SensorTypesSelection />
      </Provider>,
    );
  };

  it('renders the toggle button group', () => {
    setup();
    expect(screen.getByTestId('sensor-type-toggle-group')).toBeInTheDocument();
  });

  it('renders all valid sensor type buttons', () => {
    setup();
    validSensorTypeSettings.forEach((setting) => {
      const button = screen.getByTestId(
        `sensor-type-button-${setting.typeCode}`,
      );
      expect(button).toBeInTheDocument();
      expect(button).toHaveTextContent(setting.name);
    });
  });

  it('selects a sensor type when clicked', () => {
    setup();
    const button = screen.getByTestId(
      `sensor-type-button-${validSensorTypeSettings[1].typeCode}`,
    );
    fireEvent.click(button);

    const selectedSensorTypes = store.getState().alertRule.sensorTypes;
    expect(selectedSensorTypes).toContain(validSensorTypeSettings[1].typeCode);
  });

  it('opens the alert dialog when trying to deselect the last sensor type', async () => {
    setup();
    const selectedSensorType = store.getState().alertRule.sensorTypes[0];
    const button = screen.getByTestId(
      `sensor-type-button-${selectedSensorType}`,
    );
    fireEvent.click(button);

    await waitFor(() => {
      expect(
        screen.getByTestId('sensor-type-alert-dialog'),
      ).toBeInTheDocument();
    });

    expect(
      screen.getByText('At least one sensor type must be selected.'),
    ).toBeInTheDocument();
  });

  it('closes the alert dialog when handleDialogClose is called', async () => {
    setup();
    const button = screen.getByTestId(
      `sensor-type-button-${validSensorTypeSettings[0].typeCode}`,
    );
    fireEvent.click(button);

    const alertDialog = screen.getByTestId('sensor-type-alert-dialog');
    const closeButton = screen.getByTestId('alert-dialog-close-button');

    fireEvent.click(closeButton);

    await waitFor(() => {
      expect(alertDialog).not.toBeInTheDocument();
    });
  });

  it('dispatches setSensorTypeSettings and setSensorTypes actions on sensor type change', () => {
    const setSensorTypeSettingsSpy = vi.spyOn(store, 'dispatch');
    const setSensorTypesSpy = vi.spyOn(store, 'dispatch');

    setup();

    const button = screen.getByTestId(
      `sensor-type-button-${validSensorTypeSettings[1].typeCode}`,
    );
    fireEvent.click(button);

    expect(setSensorTypeSettingsSpy).toHaveBeenCalled();
    expect(setSensorTypesSpy).toHaveBeenCalled();
  });

  it('defaults to the first sensor type if none are selected', () => {
    store.dispatch(setSensorTypes([]));
    setup();

    const selectedSensorTypes = store.getState().alertRule.sensorTypes;
    expect(selectedSensorTypes).toContain(validSensorTypeSettings[0].typeCode);
  });

  it('removes invalid sensor types and defaults to first valid sensor type', () => {
    // Setup with invalid selected sensor types
    store.dispatch(setSensorTypes(['invalid_sensor_type']));
    setup();

    const selectedSensorTypes = store.getState().alertRule.sensorTypes;
    expect(selectedSensorTypes).toContain(validSensorTypeSettings[0].typeCode);
    expect(selectedSensorTypes).not.toContain('invalid_sensor_type');
  });

  it('keeps valid sensor types and removes only invalid ones', () => {
    const validType = validSensorTypeSettings[1].typeCode;
    const invalidType = 'invalid_sensor_type';

    // Setup with a mix of valid and invalid sensor types
    store.dispatch(setSensorTypes([validType, invalidType]));
    setup();

    const selectedSensorTypes = store.getState().alertRule.sensorTypes;
    expect(selectedSensorTypes).toContain(validType);
    expect(selectedSensorTypes).not.toContain(invalidType);
  });
});
