import { Typography } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import redux, { RootState } from '../../redux';

const fieldSeparatorSx = {
  fontFamily: 'Avenir Next',
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '0.15000000596046448px',
  lineHeight: '12px',
  textAlign: 'left',
};

const dateRangeInputStyles = {
  color: 'rgba(0,0,0,0.87)',
  fontFamily: 'Avenir Next',
  fontSize: '16px',
  fontWeight: '500',
};

const dateRangeLabelStyles = {
  fontFamily: 'Avenir Next',
  fontWeight: '500',
};

const FieldSeparator = () => <Typography sx={fieldSeparatorSx}>to</Typography>;

function ControlledDateRangePicker() {
  const { t } = useTranslation();
  const startDate = useSelector((state: RootState) => state.range.startDate);
  const endDate = useSelector((state: RootState) => state.range.endDate);
  const rangeError = useSelector((state: RootState) => state.range.error);

  const dispatch: typeof redux.store.dispatch = useDispatch();

  return (
    <>
      <DateRangePicker
        disableFuture
        format="YYYY-MM-DD"
        localeText={{ end: t('to'), start: t('from') }}
        maxDate={
          startDate ? dayjs(startDate).add(1, 'year').subtract(1, 'days') : null
        }
        onChange={(e) => {
          dispatch(redux.range.setRangeError(false));
          dispatch(
            redux.range.setStartDate(e[0] ? e[0].format('YYYY-MM-DD') : null),
          );
          dispatch(
            redux.range.setEndDate(e[1] ? e[1].format('YYYY-MM-DD') : null),
          );
        }}
        onError={(e) => {
          if (e[0] || e[1]) {
            dispatch(redux.range.setRangeError(true));
          } else {
            dispatch(redux.range.setRangeError(false));
          }
        }}
        slotProps={{
          field: { readOnly: true },
          textField: {
            InputLabelProps: {
              error: rangeError,
              style: dateRangeLabelStyles,
            },
            error: rangeError,
            inputProps: {
              style: dateRangeInputStyles,
            },
            variant: 'standard',
          },
        }}
        slots={{
          fieldSeparator: FieldSeparator,
        }}
        value={[dayjs(startDate), dayjs(endDate)]}
      />
    </>
  );
}

export default ControlledDateRangePicker;
