import icons from '../icons';

describe('icons', () => {
  test('it should have proper object properties', () => {
    expect(icons).toHaveProperty('Download');
    expect(icons).toHaveProperty('Next');
    expect(icons).toHaveProperty('Previous');
    expect(icons).toHaveProperty('SortDown');
    expect(icons).toHaveProperty('SortUp');
    expect(icons).toHaveProperty('Unsorted');
  });
});
