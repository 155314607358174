export const DATA_TYPE_BOOLEAN = 'boolean';

export type SensorDataType =
  | 'float'
  | 'integer'
  | 'string'
  | typeof DATA_TYPE_BOOLEAN;

export interface SensorType {
  dataType?: SensorDataType;
  id: number;
  name: string;
  typeCode: string;
  unit?: string;
}
