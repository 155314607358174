import { Components } from '@mui/material';

import { colors } from '../colors';

const MuiToggleButton: Components['MuiToggleButton'] = {
  defaultProps: {
    disableFocusRipple: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        backgroundColor: colors['blue-500'],
        color: colors['white'],
      },
      '&.Mui-selected:hover': {
        backgroundColor: colors['blue-500'],
        color: colors['white'],
      },
      '&:hover': {
        backgroundColor: '#F2F2F2',
        color: 'rgba(0, 0, 0, 0.5)',
      },
      backgroundColor: '#F2F2F2',
      border: 0,
      borderRadius: '8px !important',
      color: 'rgba(0, 0, 0, 0.5)',
      fontSize: '13px',
      fontWeight: '700',
      marginLeft: '0 !important',
      padding: '10px 20px',
    },
  },
};

export default MuiToggleButton;
