import { faker } from '@faker-js/faker';
import { cleanup, render, screen } from '@testing-library/react';
import i18next from 'i18next';
import * as React from 'react';
import { expect } from 'vitest';

import ExportCsvContainer from './index';
import translations from './translations';

translations.init();

function TestComponent() {
  return (
    <ExportCsvContainer samplesManagedMachinePath={faker.internet.url()} />
  );
}

describe('sample rate selector', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the sample rate selector', () => {
    render(<TestComponent />);
    const element: HTMLSpanElement = screen.getByText(
      i18next.t('window-function-display-first-value'),
    );
    expect(element).toBeDefined();
    expect(element.textContent).toStrictEqual('Display First Value');
  });
});
