import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const ErrorIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon data-testid="ErrorIcon" {...props} viewBox="0 0 16 17">
    <path
      d="M8 8.62606V5.62313M8 10.6231V10.6331M14.6601 8.11812C14.6601 11.7991 11.6761 14.7831 7.99508 14.7831C4.3141 14.7831 1.33008 11.7991 1.33008 8.11812C1.33008 4.43715 4.3141 1.45312 7.99508 1.45312C11.6761 1.45312 14.6601 4.43715 14.6601 8.11812Z"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </SvgIcon>
);

export default ErrorIcon;
