import { configureStore } from '@reduxjs/toolkit';

import api from './api';
import banner from './banner';
import data from './data';

const redux = {
  api,
  banner,
  data,
  store: configureStore({
    devTools: true,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
      });
    },
    reducer: {
      api: api.reducer,
      banner: banner.reducer,
      data: data.reducer,
    },
  }),
};

export type RootState = ReturnType<typeof redux.store.getState>;

export default redux;
