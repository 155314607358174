import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(
  '32bc5befa67988062d358284539de27eTz04ODk1OCxFPTE3NDU0MjIxNDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

function MuiXLicense() {
  return null;
}

export default MuiXLicense;
