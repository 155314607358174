import { faker } from '@faker-js/faker';
import { cleanup, render, screen } from '@testing-library/react';
import i18next from 'i18next';
import * as React from 'react';

import ExportCsvContainer from './index';
import translations from './translations';

translations.init();

type TestComponentProps = {
  tab: string;
};

function TestComponent({ tab }: Readonly<TestComponentProps>) {
  return (
    <div>
      <div id={tab}>
        <ExportCsvContainer
          host="http://localhost"
          tab={tab}
          timezone={faker.location.timeZone()}
        />
      </div>
    </div>
  );
}

describe('export csv container', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the reports files-and-exports container', () => {
    render(<TestComponent tab="file-and-exports-section" />);
    const button = screen.getByText(i18next.t('files-and-exports-uppercase'));
    expect(button).toBeDefined();
  });

  test('should render the reports files-and-exports title even when on another tab', () => {
    render(<TestComponent tab="another-tab" />);
    const button = screen.getByText(i18next.t('files-and-exports-uppercase'));
    expect(button).toBeDefined();
  });
});
