import { Controller } from 'stimulus';

export default class extends Controller {
	static targets = [
		'filtersText',
		'frequency',
		'frequencyController',
		'id',
		'individualMachines',
		'sensorData',
		'sensorDataRow',
	];

	connect() {
		new Selects();
		// TODO Are we possibly going to need a callback of some sort for this?
		new Collapse();

		// TODO Switch to Chosen?
		// We need a little bit of a hack since js-select2 does not fire native events
		// and so we can't just attach data-target= to the select.
		$('.js-select2').on('select2:select', (e) => {
			this.selectAlertRule($(e.target).select2('data'), e.params.data);
		});
		$('.js-select2').on('select2:unselect', (e) => {
			this.unselectAlertRule($(e.target).select2('data'), e.params.data);
		});

		// All the sensor checkbox elements, key is sensor name
		this.sensorCheckBoxesMap = this.sensorDataTargets.reduce((map, item) => {
			map.set(item.attributes['data-sensor'].value, item);
			return map;
		}, new Map());

		Array.from(this.getVisibleSensors()).map((sensorName) => {
			this.showSensor(sensorName);
		});
		Array.from(this.getSelectedSensors()).map((sensorName) => {
			this.selectSensor(sensorName);
		});

		const urlParams = new URLSearchParams(window.location.search);
		const preview = urlParams.get('preview');
		if (preview === 'true') {
			this.openPreview(urlParams);
		}
	}

	// actions

	selectAlertRule(selectedOptions, selection) {
		const sensorNames =
			selection.element.attributes['data-sensors'].value.split(',');

		sensorNames.map((sensorName) => {
			if (!this.getVisibleSensors().has(sensorName)) {
				this.showSensor(sensorName);
				this.selectSensor(sensorName);
				this.addToVisibleSensors(sensorName);
			}
		});

		this.updateFiltersText(selectedOptions);
		this.updateIndividualMachines(selectedOptions);
		this.validateForm();
	}

	unselectAlertRule(selectedOptions, unselection) {
		let sensorNamesFromAllSelectedRules = new Set(
			selectedOptions
				.map((e) => e.element.attributes['data-sensors'].value)
				.join(',')
				.split(','),
		);

		const sensorNames =
			unselection.element.attributes['data-sensors'].value.split(',');
		sensorNames.map((sensorName) => {
			if (!this.stillSelected(sensorName, sensorNamesFromAllSelectedRules)) {
				let sensorCheckBox = this.sensorCheckBoxesMap.get(sensorName);
				sensorCheckBox.checked = false;
				sensorCheckBox.parentElement.classList.remove('show');
				this.removeFromVisibleSensors(sensorName);
			}
		});
		if (this.missingSensorData()) {
			this.sensorDataRowTarget.classList.add('hidden');
		}
		this.updateFiltersText(selectedOptions);
		this.updateIndividualMachines(selectedOptions);
		this.validateForm();
	}

	// support methods

	updateFiltersText(selectedOptions) {
		const selectedAlertRuleNames = new Set(
			selectedOptions.map((e) => e.element.label),
		);
		this.filtersTextTarget['alert-report--filters-text'].update(
			selectedAlertRuleNames,
		);
	}

	updateIndividualMachines(selectedOptions) {
		this.individualMachinesTarget['alert-report--individual-machines'].update(
			selectedOptions,
		);
	}

	stillSelected(sensorName, sensorNamesFromAllSelectedRules) {
		return sensorNamesFromAllSelectedRules.has(sensorName);
	}

	getVisibleSensors() {
		return new Set(JSON.parse(this.data.get('visible-sensors')));
	}

	getSelectedSensors() {
		return new Set(JSON.parse(this.data.get('selected-sensors')));
	}

	addToVisibleSensors(sensorName) {
		return this.setVisibleSensors(this.getVisibleSensors().add(sensorName));
	}

	removeFromVisibleSensors(sensorName) {
		let visibleSensors = this.getVisibleSensors();
		visibleSensors.delete(sensorName);
		this.setVisibleSensors(visibleSensors);
	}

	setVisibleSensors(visibleSensorsSet) {
		this.data.set('visible-sensors', JSON.stringify(visibleSensorsSet));
	}

	showSensor(sensorName) {
		this.sensorDataRowTarget.classList.remove('hidden');
		let sensorCheckBox = this.sensorCheckBoxesMap.get(sensorName);
		sensorCheckBox.parentElement.classList.add('show');
	}

	selectSensor(sensorName) {
		const sensorCheckBox = this.sensorCheckBoxesMap.get(sensorName);
		sensorCheckBox.checked = true;
	}

	missingSensorData() {
		return $('.js-sensor-data-checkbox-wrapper :checkbox:checked').length === 0;
	}

	validateForm() {
		const missingSensorData = this.missingSensorData();
		const missingReportOptions =
			$('.js-report-options-checkbox-wrapper :checkbox:checked').length === 0;

		if (missingSensorData || missingReportOptions) {
			this.frequencyControllerTarget['report--frequency'].reportValidation(
				'report-fields',
				false,
			);
			return false;
		}

		// Validate required fields
		for (let requiredField of $(':input.required')) {
			if (!requiredField.checkValidity()) {
				this.frequencyControllerTarget['report--frequency'].reportValidation(
					'report-fields',
					false,
				);
				return false;
			}
		}

		this.frequencyControllerTarget['report--frequency'].reportValidation(
			'report-fields',
			true,
		);
		return true;
	}
}
