import { createAsyncThunk } from '@reduxjs/toolkit';

const setContent = createAsyncThunk(
  'banner/set-content',
  async (data: string) => {
    const banner = document.getElementsByClassName('alert-box')[0];

    if (banner) {
      banner.children[0].textContent = data;
    }

    return data;
  },
);

export default setContent;
