import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from '@mui/material';
import AccountIcon from '../../icons/AccountIcon';
import RecipientsTable from './RecipientsTable';

const recipientsTableStyles = {
  rows: {
    highlightOnHoverStyle: {
      cursor: 'pointer',
      outlineStyle: 'none',
    },
  },
};

const RecipientsDialog = ({
  customerGroupName,
  selectedRecipients,
  recipientOptions,
  setSelectedRecipients,
}) => {
  const [open, setOpen] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [selectedRows, setSelectedRows] = useState(selectedRecipients);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedRows(selectedRecipients);
    setOpen(false);
  };

  const saveSelectedRecipients = () => {
    setSelectedRecipients(selectedRows);
    setOpen(false);
  };

  const filteredRows = recipientOptions.filter((item) => {
    return (
      (item.name &&
        item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.contact &&
        item.contact.toLowerCase().includes(filterText.toLowerCase()))
    );
  });

  const SearchInputMemo = useMemo(() => {
    const handleFilterChange = (e) => {
      setFilterText(e.target.value);
    };

    return (
      <input
        type="search"
        className={'search-input'}
        value={filterText}
        onChange={handleFilterChange}
      />
    );
  }, [filterText]);

  const rowSelectCriteria = (row) => {
    const matchingOption = selectedRows.find((option) => {
      return option.id === row.id;
    });
    return !!matchingOption;
  };

  const onRowClicked = (row) => {
    const index = selectedRows.findIndex(
      (recipient) => recipient.id === row.id
    );
    if (index > -1) {
      selectedRows.splice(index, 1);
      setSelectedRows([...selectedRows]);
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const shouldDisableSaveButton = selectedRows.length < 1;

  return (
    <>
      <div className={'recipient_actions'} onClick={handleClickOpen}>
        <AccountIcon />
        <Link color="inherit">
          {I18n.t(
            'alert_rules.form.escalation_settings.recipients_dialog.add_remove_recipients'
          )}
        </Link>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle>
          <span className={'title'}>
            {customerGroupName + ' - ' + I18n.t('alert_rules.form.escalation_settings.recipients_dialog.users')}
          </span>
          {SearchInputMemo}
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 0,
          }}
        >
          <RecipientsTable
            data={filteredRows}
            customStyles={recipientsTableStyles}
            selectableRowSelected={rowSelectCriteria}
            selectableRowsHighlight
            onRowClicked={onRowClicked}
            fixedHeader
            fixedHeaderScrollHeight={'500px'}
            sortable={true}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Button onClick={handleClose}>
            {I18n.t(
              'alert_rules.form.escalation_settings.recipients_dialog.actions.cancel'
            )}
          </Button>
          <Button
            onClick={saveSelectedRecipients}
            disabled={shouldDisableSaveButton}
          >
            {I18n.t(
              'alert_rules.form.escalation_settings.recipients_dialog.actions.save'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

RecipientsDialog.propTypes = {
  customerGroupName: PropTypes.string.isRequired,
  recipientOptions: PropTypes.array.isRequired,
  selectedRecipients: PropTypes.array.isRequired,
  setSelectedRecipients: PropTypes.func.isRequired,
};

export default RecipientsDialog;
