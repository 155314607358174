import * as React from 'react';
import { Provider } from 'react-redux';

import Host from './host';
import redux from './redux';
import { HostProps } from './types';

const MfeGuestIframe = (props: HostProps) => {
  return (
    <Provider store={redux.store}>
      <Host {...props} />
    </Provider>
  );
};

export default MfeGuestIframe;
