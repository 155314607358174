import { CssBaseline, Grid, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider, useDispatch } from 'react-redux';

import {
  ValidBooleanValue,
  setAlertRule,
  setValidBooleanValues,
  setValidSensorTypeSettings,
} from '../../features/alertRules';
import { AlertRule, SensorTypeSetting } from '../../interfaces';
import { colors } from '../../theme/colors';
import emLegacyTheme from '../../theme/emLegacyTheme';
import translations from '../../translations';
import SensorTypeSettingsGroup from './sensor_setting/SensorTypeSettingsGroup';
import SensorTypesSelection from './sensor_setting/SensorTypesSelection';
import store from './store';

translations.init();

interface SensorSettingsContainerProps {
  alertRule: AlertRule;
  validBooleanValues: ValidBooleanValue[];
  validSensorTypeSettings: SensorTypeSetting[];
}

const SensorSettings: FC<SensorSettingsContainerProps> = ({
  alertRule,
  validBooleanValues,
  validSensorTypeSettings,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('alertRuleSensorTypes');

  useEffect(() => {
    const handleAlertRuleFormLoaded = (
      _event: Event,
      formAlertRule: AlertRule,
      formValidSensorTypeSettings: SensorTypeSetting[],
      formValidBooleanValues: ValidBooleanValue[],
    ) => {
      if (formAlertRule) {
        dispatch(setAlertRule(formAlertRule));
      }
      if (
        formValidSensorTypeSettings &&
        formValidSensorTypeSettings.length > 0
      ) {
        dispatch(setValidSensorTypeSettings(formValidSensorTypeSettings));
      }
      if (formValidBooleanValues && formValidBooleanValues.length > 0) {
        dispatch(setValidBooleanValues(formValidBooleanValues));
      }
    };

    $(document).on('alertRuleFormLoaded', handleAlertRuleFormLoaded);

    // Cleanup the event listener on component unmount
    return () => {
      $(document).off('alertRuleFormLoaded', handleAlertRuleFormLoaded);
    };
  }, [dispatch]);

  useEffect(() => {
    if (alertRule) {
      dispatch(setAlertRule(alertRule));
    }
  }, [alertRule, dispatch]);

  useEffect(() => {
    if (validSensorTypeSettings) {
      dispatch(setValidSensorTypeSettings(validSensorTypeSettings));
    }
  }, [validSensorTypeSettings, dispatch]);

  useEffect(() => {
    if (validBooleanValues) {
      dispatch(setValidBooleanValues(validBooleanValues));
    }
  }, [validBooleanValues, dispatch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography
          color={colors['blue-500']}
          fontSize="13px"
          style={{
            padding: '20px 0px',
            paddingBottom: '10px',
          }}
          variant="text-xs-bold-caps"
        >
          {t('title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <SensorTypesSelection />
      </Grid>
      <Grid item xs={12}>
        <SensorTypeSettingsGroup />
      </Grid>
    </Grid>
  );
};

const SensorSettingsContainer: FC<SensorSettingsContainerProps> = (props) => (
  <Provider store={store}>
    <ThemeProvider theme={emLegacyTheme}>
      <CssBaseline />
      <SensorSettings {...props} />
    </ThemeProvider>
  </Provider>
);

export default SensorSettingsContainer;
