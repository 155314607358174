import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import redux from '../../redux';
import utils from '../../utils';

type DateRangeSyncManagerProps = {
  managedMachineUuid: string;
  samplesManagedMachinePath: string;
};

function SyncManager({
  managedMachineUuid,
  samplesManagedMachinePath,
}: DateRangeSyncManagerProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(redux.form.setManagedMachineUuid(managedMachineUuid));
  }, [managedMachineUuid]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    const split = samplesManagedMachinePath.split('?');
    const queryString = split[1];
    const searchParams = utils.getJsonFromUrl(queryString);

    const defaultStartDate = dayjs()
      .endOf('day')
      .add(-1, 'days')
      .format('YYYY-MM-DD');
    const defaultEndDate = dayjs().endOf('day').format('YYYY-MM-DD');
    const startDate =
      searchParams['managed_machine[period_date_from]']?.length > 0
        ? searchParams['managed_machine[period_date_from]']
        : defaultStartDate;
    const endDate =
      searchParams['managed_machine[period_date_to]']?.length > 0
        ? searchParams['managed_machine[period_date_to]']
        : defaultEndDate;
    dispatch(redux.range.setStartDate(startDate));
    dispatch(redux.range.setEndDate(endDate));

    const defaultWindowSize = 'all';
    const defaultWindowFunction = 'first';
    const windowSize =
      searchParams['managed_machine[window_size]']?.length > 0
        ? searchParams['managed_machine[window_size]']
        : defaultWindowSize;
    const windowFunction =
      searchParams['managed_machine[window_function]']?.length > 0
        ? searchParams['managed_machine[window_function]']
        : defaultWindowFunction;
    dispatch(redux.options.setWindowSize(windowSize));
    dispatch(redux.form.setWindowSize(windowSize));
    dispatch(redux.options.setWindowFunction(windowFunction));
    dispatch(redux.form.setWindowFunction(windowFunction));
  }, [samplesManagedMachinePath]);

  // eslint-disable-next-line complexity
  const handleUpdateCsvLink = (e) => {
    if (e.detail.dateFrom?.length > 0) {
      dispatch(redux.range.setStartDate(e.detail.dateFrom));
    }

    if (e.detail.dateTo?.length > 0) {
      dispatch(redux.range.setEndDate(e.detail.dateTo));
    }

    if (e.detail.windowSize?.length > 0) {
      dispatch(redux.options.setWindowSize(e.detail.windowSize));
      dispatch(redux.form.setWindowSize(e.detail.windowSize));
    }

    if (e.detail.windowFunction?.length > 0) {
      dispatch(redux.options.setWindowFunction(e.detail.windowFunction));
      dispatch(redux.form.setWindowFunction(e.detail.windowFunction));
    }
  };

  useEffect(() => {
    window.addEventListener('update-csv-link', handleUpdateCsvLink);
    return () => {
      window.removeEventListener('update-csv-link', handleUpdateCsvLink);
    };
  }, [handleUpdateCsvLink]);

  return null;
}

export default SyncManager;
