import React from 'react';
import PropTypes from 'prop-types';

const VoiceCallIcon = ({ width = 12, height = 12 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.1734 8.16667L2.86673 7.97333C3.2734 7.92667 3.6734 8.06667 3.96007 8.35333L5.18673 9.58C7.0734 8.62 8.62006 7.08 9.58006 5.18667L8.34673 3.95333C8.06007 3.66667 7.92007 3.26667 7.96673 2.86L8.16007 1.18C8.24007 0.506667 8.80673 0 9.48673 0H10.6401C11.3934 0 12.0201 0.626667 11.9734 1.38C11.6201 7.07333 7.06673 11.62 1.38007 11.9733C0.626732 12.02 6.50303e-05 11.3933 6.50303e-05 10.64V9.48667C-0.00660164 8.81333 0.500065 8.24667 1.1734 8.16667Z"
        fill="white"
      />
    </svg>
  );
};

VoiceCallIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default VoiceCallIcon;
