import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const HidePasswordIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5356 10.4645C14.7677 10.6967 14.9519 10.9723 15.0775 11.2756C15.2032 11.5789 15.2678 11.904 15.2678 12.2323C15.2678 12.5606 15.2031 12.8857 15.0775 13.189C14.9518 13.4923 14.7677 13.7679 14.5355 14.0001C14.3034 14.2322 14.0278 14.4164 13.7245 14.542C13.4211 14.6676 13.0961 14.7323 12.7678 14.7323C12.4394 14.7323 12.1144 14.6676 11.811 14.5419C11.5077 14.4163 11.2321 14.2322 11 14"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M12 6C8.04003 6 5.01115 8.27449 2.4755 11.3974C2.19014 11.7488 2.19009 12.2511 2.47544 12.6025C3.18711 13.479 3.93763 14.2887 4.73669 15M7.74043 17.0348C9.03446 17.6495 10.4455 18 12 18C15.96 18 18.9889 15.7255 21.5245 12.6026C21.8099 12.2512 21.8099 11.7489 21.5245 11.3974C20.0991 9.64187 18.5178 8.15443 16.7029 7.18844"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        d="M20.1219 3.11547L3.00005 20.9996"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default HidePasswordIcon;
