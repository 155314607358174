import { cleanup, render, screen } from '@testing-library/react';
import * as React from 'react';
import { Provider } from 'react-redux';

import redux from '../../redux';
import translations from '../../translations';
import WindowSize from './window-size';

function TestComponent() {
  return (
    <Provider store={redux.store}>
      <WindowSize />
    </Provider>
  );
}

describe('window size', () => {
  beforeAll(() => {
    translations.init();
  });

  afterEach(() => {
    cleanup();
  });

  test('window size value set to all', () => {
    redux.store.dispatch(redux.options.setWindowSize('1s'));
    render(<TestComponent />);
    const element = screen.getByText('All');
    expect(element).toBeDefined();
    expect(element.textContent).toBe('All');
  });

  test('window size value set to 1 minute', () => {
    redux.store.dispatch(redux.options.setWindowSize('1m'));
    render(<TestComponent />);
    const element = screen.getByText('1 Minute');
    expect(element).toBeDefined();
    expect(element.textContent).toBe('1 Minute');
  });

  test('window size value set to 15 minutes', () => {
    redux.store.dispatch(redux.options.setWindowSize('15m'));
    render(<TestComponent />);
    const element = screen.getByText('15 Minutes');
    expect(element).toBeDefined();
    expect(element.textContent).toBe('15 Minutes');
  });

  test('window size value set to 1 hour', () => {
    redux.store.dispatch(redux.options.setWindowSize('1h'));
    render(<TestComponent />);
    const element = screen.getByText('1 Hour');
    expect(element).toBeDefined();
    expect(element.textContent).toBe('1 Hour');
  });

  test('window size value set to 1 day', () => {
    redux.store.dispatch(redux.options.setWindowSize('1d'));
    render(<TestComponent />);
    const element = screen.getByText('1 Day');
    expect(element).toBeDefined();
    expect(element.textContent).toBe('1 Day');
  });
});
