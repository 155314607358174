import { render } from '@testing-library/react';
import axios from 'axios';
import React, { act } from 'react';
import { describe, test, vi } from 'vitest';

import translations from '../../../translations';
import CopyMachinesDialog from './CopyMachinesDialog';

vi.mock('axios');

describe('CopyMachinesDialog', () => {
  beforeAll(() => {
    translations.init();
  });

  beforeEach(() => {
    vi.clearAllMocks();
  });

  const handleClose = vi.fn();
  const machineIds = [1, 2, 3];
  const show = true;

  const renderComponent = () =>
    render(
      <CopyMachinesDialog
        handleClose={handleClose}
        machineIds={machineIds}
        show={show}
      />,
    );

  test('renders dialog', () => {
    vi.spyOn(axios, 'post').mockImplementation(async () => ({
      data: {
        managed_machines_created: machineIds,
      },
    }));
    vi.spyOn(axios, 'get').mockImplementation(async () => ({
      data: {
        machine_ids: machineIds,
      },
    }));
    act(() => {
      renderComponent();
    });
  });
});
