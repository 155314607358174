import { render } from '@testing-library/react';
import React from 'react';
import { describe, test, vi } from 'vitest';

import CopyMachinesDialogContent from './CopyMachinesDialogContent';

describe('CopyMachinesDialogContent', () => {
  beforeEach(() => {
    vi.clearAllMocks();
  });

  const machineIds = [1, 2, 3];
  const copyDone = false;
  const errorMessage = {};
  const newMachines = [];
  const selectedCustomer = null;
  const setErrorMessage = vi.fn();
  const setSelectedCustomer = vi.fn();
  const t = vi.fn();

  const renderComponent = () =>
    render(
      <CopyMachinesDialogContent
        copyDone={copyDone}
        errorMessage={errorMessage}
        machineIds={machineIds}
        newMachines={newMachines}
        selectedCustomer={selectedCustomer}
        setErrorMessage={setErrorMessage}
        setSelectedCustomer={setSelectedCustomer}
        t={t}
      />,
    );

  test('renders dialog', () => {
    renderComponent();
  });
});
