import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { colors } from '../../theme/colors';
import { HidePasswordIcon, ShowPasswordIcon } from '../icons/other';
import { Input } from './styles';

const PasswordInput: React.FC<TextFieldProps> = ({ ...props }) => {
  const { t } = useTranslation('login');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <Input
      id="password"
      label={t('password')}
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              data-testid="toggle-password-visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              style={{ padding: 0 }}
            >
              {showPassword ? (
                <HidePasswordIcon
                  style={{
                    color: colors['gray-700'],
                    height: '16px',
                    width: '16px',
                  }}
                />
              ) : (
                <ShowPasswordIcon
                  style={{
                    color: colors['gray-700'],
                    height: '16px',
                    width: '16px',
                  }}
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      autoComplete="current-password"
      type={showPassword ? 'text' : 'password'}
      variant="standard"
    />
  );
};

export default PasswordInput;
