import { SensorTypeSetting } from '@interfaces/sensorTypeSetting';
import { fireEvent, render, screen } from '@testing-library/react';
import React from 'react';
import { Provider } from 'react-redux';
import { beforeAll, beforeEach, describe, expect, it } from 'vitest';

import alertRuleFactory from '../../../factories/alertRuleFactory';
import { setAlertRule } from '../../../features/alertRules';
import translations from '../../../translations';
import store from '../store';
import SensorTypeSettingForm from './SensorTypeSettingForm';

describe('SensorTypeSettingForm', () => {
  let mockSensorTypeSetting: SensorTypeSetting;

  beforeAll(() => {
    translations.init();
  });

  beforeEach(() => {
    // Create a mock sensor type setting for a threshold type
    const mockAlertRule = alertRuleFactory.build();
    mockSensorTypeSetting = mockAlertRule.sensorTypeSettings[0];

    // Dispatch a mock alert rule to the store
    store.dispatch(setAlertRule(mockAlertRule));
  });

  const setup = (overrides = {}) => {
    const props = {
      setting: mockSensorTypeSetting,
      ...overrides,
    };

    render(
      <Provider store={store}>
        <SensorTypeSettingForm {...props} />
      </Provider>,
    );

    return props;
  };

  it('renders the if condition text with the correct i18n key', () => {
    setup();
    expect(screen.getByText(/if/i)).toBeInTheDocument();
    expect(screen.getByText(mockSensorTypeSetting.name)).toBeInTheDocument();
  });

  it('does not render the delay field if showSetDelay is false', () => {
    const mockAlertRule = alertRuleFactory.build({
      alertOnFirstOutOfRange: true,
    });
    mockSensorTypeSetting = mockAlertRule.sensorTypeSettings[0];

    // Dispatch a mock alert rule to the store
    store.dispatch(setAlertRule(mockAlertRule));

    setup();
    expect(
      screen.queryByTestId(`set-delay-field-${mockSensorTypeSetting.typeCode}`),
    ).not.toBeInTheDocument();
  });

  it('renders the delay field if showSetDelay is true', () => {
    setup();
    expect(
      screen.getByTestId(`set-delay-field-${mockSensorTypeSetting.typeCode}`),
    ).toBeInTheDocument();
  });

  it('calls handleValueChange when updating the delay field', () => {
    setup();
    const delayInput = screen.getByTestId(
      `set-delay-field-${mockSensorTypeSetting.typeCode}`,
    );
    fireEvent.change(delayInput, { target: { value: '5' } });

    // Ensure the store is updated correctly
    const updatedState = store.getState().alertRule.sensorTypeSettings[0];
    expect(updatedState.setDelay).toBe(5);
  });

  it('renders the correct i18n key based on the typeCode when showSetDelay is true', () => {
    const customSetting = {
      ...mockSensorTypeSetting,
      typeCode: 'gateway_power',
    };
    setup({ setting: customSetting });

    expect(
      screen.getByText(/changes to battery for more than/i),
    ).toBeInTheDocument();
  });

  it('renders the correct i18n key based on the typeCode when showSetDelay is false', () => {
    const mockAlertRule = alertRuleFactory.build({
      alertOnFirstOutOfRange: true,
    });
    const customSetting = {
      ...mockAlertRule.sensorTypeSettings[0],
      typeCode: 'gateway_power',
    };

    // Dispatch a mock alert rule to the store
    store.dispatch(setAlertRule(mockAlertRule));

    setup({ setting: customSetting });

    expect(screen.getByText(/changes to battery/i)).toBeInTheDocument();
    expect(
      screen.queryByText(/changes to battery for more than/i),
    ).not.toBeInTheDocument();
  });

  it('renders the generic if condition text when the typeCode is not in typeCodeMapping', () => {
    const customSetting = {
      ...mockSensorTypeSetting,
      typeCode: 'non_mapped_code',
    };
    setup({ setting: customSetting });

    expect(screen.getByText(/if/i)).toBeInTheDocument();
    expect(screen.queryByText(/changes to battery/i)).not.toBeInTheDocument();
    expect(screen.queryByText(/if disconnected/i)).not.toBeInTheDocument();
  });
});
