import { cleanup, fireEvent, render, screen } from '@testing-library/react';
import dayjs from 'dayjs';
import * as React from 'react';
import { Provider } from 'react-redux';

import redux from '../../redux';
import Shortcut from './shortcut';

type TestComponentProps = {
  item: string;
};

function TestComponent({ item }: Readonly<TestComponentProps>) {
  return (
    <Provider store={redux.store}>
      <Shortcut item={item} />
    </Provider>
  );
}

describe('shortcut', () => {
  afterEach(() => {
    cleanup();
  });

  test('item yesterday', () => {
    const item = 'yesterday';
    render(<TestComponent item={item} />);
    const button = screen.getByText(item);
    expect(button).toBeDefined();
    const spy1 = vi.spyOn(redux.range, 'setStartDate');
    const spy2 = vi.spyOn(redux.range, 'setEndDate');
    fireEvent.click(button);
    expect(spy1).toHaveBeenCalledTimes(1);
    expect(spy2).toHaveBeenCalledTimes(1);
    const startDate = dayjs().startOf('day').subtract(1, 'days');
    expect(redux.store.getState().range.startDate).toStrictEqual(
      startDate.format('YYYY-MM-DD'),
    );
    const endDate = dayjs().endOf('day').subtract(1, 'days');
    expect(redux.store.getState().range.endDate).toStrictEqual(
      endDate.format('YYYY-MM-DD'),
    );
  });

  test('item today', () => {
    const item = 'today';
    render(<TestComponent item={item} />);
    const button = screen.getByText(item);
    expect(button).toBeDefined();
    const spy1 = vi.spyOn(redux.range, 'setStartDate');
    const spy2 = vi.spyOn(redux.range, 'setEndDate');
    fireEvent.click(button);
    expect(spy1).toHaveBeenCalledTimes(1);
    expect(spy2).toHaveBeenCalledTimes(1);
    const startDate = dayjs().startOf('day');
    expect(redux.store.getState().range.startDate).toStrictEqual(
      startDate.format('YYYY-MM-DD'),
    );
    const endDate = dayjs().endOf('day');
    expect(redux.store.getState().range.endDate).toStrictEqual(
      endDate.format('YYYY-MM-DD'),
    );
  });

  test('item 7d', () => {
    const item = '7d';
    render(<TestComponent item={item} />);
    const button = screen.getByText(item);
    expect(button).toBeDefined();
    const spy1 = vi.spyOn(redux.range, 'setStartDate');
    const spy2 = vi.spyOn(redux.range, 'setEndDate');
    fireEvent.click(button);
    expect(spy1).toHaveBeenCalledTimes(1);
    expect(spy2).toHaveBeenCalledTimes(1);
    const startDate = dayjs().startOf('day').add(1, 'days').subtract(1, 'week');
    expect(redux.store.getState().range.startDate).toStrictEqual(
      startDate.format('YYYY-MM-DD'),
    );
    const endDate = dayjs().endOf('day');
    expect(redux.store.getState().range.endDate).toStrictEqual(
      endDate.format('YYYY-MM-DD'),
    );
  });

  test('item 1m', () => {
    const item = '1m';
    render(<TestComponent item={item} />);
    const button = screen.getByText(item);
    expect(button).toBeDefined();
    const spy1 = vi.spyOn(redux.range, 'setStartDate');
    const spy2 = vi.spyOn(redux.range, 'setEndDate');
    fireEvent.click(button);
    expect(spy1).toHaveBeenCalledTimes(1);
    expect(spy2).toHaveBeenCalledTimes(1);
    const startDate = dayjs()
      .startOf('day')
      .add(1, 'days')
      .subtract(1, 'month');
    expect(redux.store.getState().range.startDate).toStrictEqual(
      startDate.format('YYYY-MM-DD'),
    );
    const endDate = dayjs().endOf('day');
    expect(redux.store.getState().range.endDate).toStrictEqual(
      endDate.format('YYYY-MM-DD'),
    );
  });

  test('item 3m', () => {
    const item = '3m';
    render(<TestComponent item={item} />);
    const button = screen.getByText(item);
    expect(button).toBeDefined();
    const spy1 = vi.spyOn(redux.range, 'setStartDate');
    const spy2 = vi.spyOn(redux.range, 'setEndDate');
    fireEvent.click(button);
    expect(spy1).toHaveBeenCalledTimes(1);
    expect(spy2).toHaveBeenCalledTimes(1);
    const startDate = dayjs()
      .startOf('day')
      .add(1, 'days')
      .subtract(3, 'months');
    expect(redux.store.getState().range.startDate).toStrictEqual(
      startDate.format('YYYY-MM-DD'),
    );
    const endDate = dayjs().endOf('day');
    expect(redux.store.getState().range.endDate).toStrictEqual(
      endDate.format('YYYY-MM-DD'),
    );
  });

  test('item 6m', () => {
    const item = '6m';
    render(<TestComponent item={item} />);
    const button = screen.getByText(item);
    expect(button).toBeDefined();
    const spy1 = vi.spyOn(redux.range, 'setStartDate');
    const spy2 = vi.spyOn(redux.range, 'setEndDate');
    fireEvent.click(button);
    expect(spy1).toHaveBeenCalledTimes(1);
    expect(spy2).toHaveBeenCalledTimes(1);
    const startDate = dayjs()
      .startOf('day')
      .add(1, 'days')
      .subtract(6, 'months');
    expect(redux.store.getState().range.startDate).toStrictEqual(
      startDate.format('YYYY-MM-DD'),
    );
    const endDate = dayjs().endOf('day');
    expect(redux.store.getState().range.endDate).toStrictEqual(
      endDate.format('YYYY-MM-DD'),
    );
  });

  test('item 12m', () => {
    const item = '12m';
    render(<TestComponent item={item} />);
    const button = screen.getByText(item);
    expect(button).toBeDefined();
    const spy1 = vi.spyOn(redux.range, 'setStartDate');
    const spy2 = vi.spyOn(redux.range, 'setEndDate');
    fireEvent.click(button);
    expect(spy1).toHaveBeenCalledTimes(1);
    expect(spy2).toHaveBeenCalledTimes(1);
    const startDate = dayjs().startOf('day').add(1, 'days').subtract(1, 'year');
    expect(redux.store.getState().range.startDate).toStrictEqual(
      startDate.format('YYYY-MM-DD'),
    );
    const endDate = dayjs().endOf('day');
    expect(redux.store.getState().range.endDate).toStrictEqual(
      endDate.format('YYYY-MM-DD'),
    );
  });
});
