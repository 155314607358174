import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import asyncThunks from '../async-thunks';
import { ExportState } from '../types';

const requestExport = (builder: ActionReducerMapBuilder<ExportState>) => {
  builder.addCase(asyncThunks.requestExport.pending, (state: ExportState) => {
    state.isLoading = true;
    state.isError = false;
  });
  builder.addCase(asyncThunks.requestExport.fulfilled, (state: ExportState) => {
    state.isLoading = false;
    state.isError = false;
  });
  builder.addCase(asyncThunks.requestExport.rejected, (state: ExportState) => {
    state.isLoading = false;
    state.isError = true;
  });
};

export default requestExport;
