import React from 'react';
import PropTypes from 'prop-types';

const ThresholdIcon = ({ width = 16, height = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M15.001 15.708c-.39.39-1.024.39-1.415 0L10.94 13.06l-1.732 1.732A.707.707 0 0 1 8 14.29V9c0-.553.448-1 1-1h5.291a.708.708 0 0 1 .505 1.203l-1.735 1.736 2.648 2.647c.389.39.389 1.024 0 1.415l-.708.707zM1.71 8.001a.708.708 0 0 1-.504-1.205L2.941 5.06.292 2.414A1.002 1.002 0 0 1 .292 1l.709-.708a1 1 0 0 1 1.414 0L5.06 2.94l1.732-1.732a.708.708 0 0 1 1.208.5V7c0 .552-.448 1-1 1H1.708z"
      />
    </svg>
  );
};

ThresholdIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ThresholdIcon;
