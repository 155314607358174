import merge from 'deepmerge';
import React from 'react';
import DataTable, {
  TableColumn,
  TableStyles,
  createTheme,
} from 'react-data-table-component';

createTheme(
  'elemental',
  {
    background: {
      default: '#FFFFFF',
    },
    highlightOnHover: {
      default: '#1C5BFE',
      text: '#FFFFFF',
    },
    selected: {
      default: 'rgba(28, 91, 254, 0.5)',
      text: '#FFFFFF',
    },
    striped: {
      default: '#F9F9F9',
    },
    text: {
      primary: '#444C5B',
    },
  },
  'default',
);

const baseStyles: TableStyles = {
  head: {
    style: {
      color: '#1A1919',
      fontSize: '13px',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
  },
  headRow: {
    style: {
      backgroundColor: 'rgba(28, 91, 254, 0.1)',
      borderBottomStyle: 'none',
      minHeight: '45px',
    },
  },
  rows: {
    style: {
      '&:not(:last-of-type)': {
        borderBottomStyle: 'none',
      },
      fontSize: '14px',
      fontWeight: 500,
    },
  },
};

interface BaseTableProps<T> {
  columns: TableColumn<T>[];
  customStyles?: object;
  data: T[];
  defaultSortFieldId?: null | number;
  fixedHeader?: boolean;
  fixedHeaderScrollHeight?: string;
  onRowClicked?: (row: T) => void;
  selectableRowSelected?: (row: T) => boolean;
  selectableRowsHighlight?: boolean;
}

const BaseTable = <T,>({
  columns,
  customStyles = {},
  data,
  defaultSortFieldId = null,
  fixedHeader = false,
  fixedHeaderScrollHeight = '',
  onRowClicked = () => {},
  selectableRowSelected = () => false,
  selectableRowsHighlight = false,
}: BaseTableProps<T>) => {
  return (
    <DataTable
      columns={columns}
      customStyles={merge(baseStyles, customStyles)}
      data={data}
      defaultSortFieldId={defaultSortFieldId}
      fixedHeader={fixedHeader}
      fixedHeaderScrollHeight={fixedHeaderScrollHeight}
      highlightOnHover
      onRowClicked={onRowClicked}
      responsive
      selectableRowSelected={selectableRowSelected}
      selectableRowsHighlight={selectableRowsHighlight}
      striped
      theme={'elemental'}
    />
  );
};

export default BaseTable;
