import { describe, expect, it } from 'vitest';

import { ValidateEmail } from './Validators';

describe('ValidateEmail', () => {
  it('should return true for a valid email address', () => {
    const result = ValidateEmail('test@example.com');
    expect(result).toBe(true);
  });

  it('should return false for an invalid email address format', () => {
    const result = ValidateEmail('invalid-email');
    expect(result).toBe(false);
  });

  it('should return false for an empty email string', () => {
    const result = ValidateEmail('');
    expect(result).toBe(false);
  });

  it('should return false for null email', () => {
    const result = ValidateEmail(null);
    expect(result).toBe(false);
  });

  it('should return false for undefined email', () => {
    const result = ValidateEmail(undefined);
    expect(result).toBe(false);
  });
});
