import { faker } from '@faker-js/faker';
import { Factory } from 'fishery';

import { AlertRule } from '../interfaces';
import sensorTypeSettingFactory from './sensorTypeSettingFactory';

class AlertRuleFactory extends Factory<AlertRule> {
  batteryAlert() {
    return this.params({
      sensorTypeSettings: [sensorTypeSettingFactory.battery().build()],
      sensorTypes: ['battery'],
    });
  }

  booleanAlert() {
    return this.params({
      machineTypes: ['element_b_01'],
      sensorTypeSettings: [sensorTypeSettingFactory.boolean().build()],
      sensorTypes: ['dry_contact_closed'],
    });
  }

  gatewayConnectionAlert() {
    return this.params({
      machineTypes: ['gateway_tablet'],
      sensorTypeSettings: [
        sensorTypeSettingFactory.gatewayConnection().build(),
      ],
      sensorTypes: ['gateway_connection'],
    });
  }
}

// By default this is a temperature alert rule
const alertRuleFactory = AlertRuleFactory.define(() => ({
  alertOnFirstOutOfRange: false,
  editable: true,
  enabled: true,
  machineSubmodelTypes: [],
  machineTypes: ['element_t'],
  name: `Alert Rule ${faker.number.int({ max: 1000, min: 1 })}`,
  sensorTypeSettings: [sensorTypeSettingFactory.build()],
  sensorTypes: ['temperature'],
  useIndividualMachines: false,
}));

export default alertRuleFactory;
