import { cleanup, fireEvent, render, screen } from '@testing-library/react';
import * as React from 'react';
import { Provider } from 'react-redux';

import redux from '../../../../redux';
import translations from '../../../../translations';
import CancelButton from './cancel-button';

translations.init();

type TestComponentProps = {
  setAnchorEl: (anchorEl: HTMLDivElement | null) => void;
};

function TestComponent({ setAnchorEl }: Readonly<TestComponentProps>) {
  return (
    <Provider store={redux.store}>
      <CancelButton setAnchorEl={setAnchorEl} />
    </Provider>
  );
}

describe('cancel button', () => {
  afterEach(() => {
    cleanup();
  });

  test('cancel button should close the popover upon button click', () => {
    const setAnchorEl = vi.fn();
    render(<TestComponent setAnchorEl={setAnchorEl} />);
    const button = screen.getByText('Cancel');
    expect(button).toBeDefined();
    fireEvent.click(button);
    expect(setAnchorEl).toHaveBeenCalledTimes(1);
  });
});
