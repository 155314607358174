import { render } from '@testing-library/react';
import React from 'react';
import { describe, test, vi } from 'vitest';

import CopyMachinesContainer from './CopyMachinesContainer';

describe('CopyMachinesContainer', () => {
  beforeEach(() => {
    vi.clearAllMocks();
  });

  const renderComponent = () => render(<CopyMachinesContainer />);

  test('renders dialog', () => {
    renderComponent();
  });
});
