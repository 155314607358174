import * as React from 'react';

type CollapseMenuButtonProps = {
  setCollapsed: () => void;
  showCollapseDrawer: boolean;
};

const CollapseMenuButton = ({
  setCollapsed,
  showCollapseDrawer,
}: CollapseMenuButtonProps) =>
  showCollapseDrawer && (
    <div
      className={'collapse-menu-btn'}
      onClick={() => {
        setCollapsed();
      }}
    ></div>
  );

export default CollapseMenuButton;
