import { cleanup, render, screen } from '@testing-library/react';
import * as React from 'react';

import translations from '../../translations';
import SortDown from './sort-down';

translations.init();

function TestComponent() {
  return <SortDown />;
}

describe('sort down icon', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the sort down icon', () => {
    render(<TestComponent />);
    const svg = screen.getByTestId('sort-down');

    expect(svg).toBeDefined();
    expect(svg.firstChild.nodeName.toLowerCase()).toBe('path');
  });
});
