import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import translations from '../../../translations';
import CopyMachinesDialog from './CopyMachinesDialog';

translations.init();

const CopyMachinesContainer: React.FC = () => {
  const { t } = useTranslation('partners');

  const [disabled, setDisabled] = useState(true);
  const [machineIds, setMachineIds] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const handleCustomEvent = (event) => {
      setDisabled(event.detail.disabled);
      setMachineIds(event.detail.machineIds);
    };
    window.addEventListener('SelectedMachinesChanged', handleCustomEvent);
    return () => {
      window.removeEventListener('SelectedMachinesChanged', handleCustomEvent);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <>
      <button
        className="btn btn-blue btn-medium"
        disabled={disabled}
        onClick={openModal}
      >
        {t('copy')}
      </button>
      <CopyMachinesDialog
        handleClose={closeModal}
        machineIds={machineIds}
        show={showModal}
      />
    </>
  );
};

export default CopyMachinesContainer;
