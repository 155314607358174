import { Components } from '@mui/material';
import React from 'react';

import {
  CloseIcon,
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from '../../components/icons/notificationMessages';
import { colors } from '../colors';
import space from '../space';
import typography from '../typography';

const MuiAlert: Components['MuiAlert'] = {
  defaultProps: {
    iconMapping: {
      error: <ErrorIcon />,
      info: <InfoIcon />,
      success: <SuccessIcon />,
      warning: <WarningIcon />,
    },
    slotProps: {
      closeButton: {
        sx: {
          padding: 0,
        },
      },
      closeIcon: {
        style: {
          height: '24px',
          width: '24px',
        },
      },
    },
    slots: {
      closeIcon: CloseIcon,
    },
  },
  styleOverrides: {
    root: {
      '& .MuiAlert-action': {
        marginRight: 0,
        padding: `0 0 0 ${space[3]}`,
      },
      '& .MuiAlert-icon': {
        '& svg': {
          height: '20px',
          width: '20px',
        },
        marginRight: space[2],
        padding: 0,
      },
      '& .MuiAlert-message': {
        padding: 0,
        ...typography['text-xs-medium'],
      },
      '& .MuiTypography-root': {
        marginBottom: 0,
      },
      '&.MuiAlert-filled': {
        color: colors.white,
      },
      '&.MuiAlert-filled .MuiAlert-action .MuiButton-root:hover div span': {
        color: colors.black,
      },
      '&.MuiAlert-filled .MuiButton-text span': {
        color: colors.white,
      },
      '&.MuiAlert-filledError': {
        borderLeft: `4px solid ${colors['red-700']}`,
      },
      '&.MuiAlert-filledInfo': {
        borderLeft: `4px solid ${colors['blue-700']}`,
      },
      '&.MuiAlert-filledSuccess': {
        borderLeft: `4px solid ${colors['green-500']}`,
      },
      '&.MuiAlert-filledWarning': {
        borderLeft: `4px solid ${colors['yellow-450']}`,
      },
      '&.MuiAlert-standard': {
        backgroundColor: colors.white,
        color: colors['gray-700'],
      },
      '&.MuiAlert-standard .MuiButton-text:first-of-type span': {
        color: colors['gray-700'],
      },
      '&.MuiAlert-standardError': {
        borderLeft: `4px solid ${colors['red-400']}`,
      },
      '&.MuiAlert-standardError .MuiButton-text span': {
        color: colors['red-400'],
      },
      '&.MuiAlert-standardInfo': {
        borderLeft: `4px solid ${colors['blue-500']}`,
      },
      '&.MuiAlert-standardInfo .MuiButton-text span': {
        color: colors['blue-500'],
      },
      '&.MuiAlert-standardSuccess': {
        borderLeft: `4px solid ${colors['green-450']}`,
      },
      '&.MuiAlert-standardSuccess .MuiButton-text span': {
        color: colors['green-500'],
      },
      '&.MuiAlert-standardWarning': {
        borderLeft: `4px solid ${colors['yellow-300']}`,
      },
      '&.MuiAlert-standardWarning .MuiButton-text span': {
        color: colors['yellow-450'],
      },
      '&.MuiPaper-elevation .MuiButton-text:first-of-type:not(:only-child) span.MuiTouchRipple-root':
        {
          borderRadius: '0',
          borderRight: `1px solid ${colors['darkBlue-100']}`,
        },
      '&.MuiPaper-elevation .MuiButton-text:first-of-type:only-child span.MuiTouchRipple-root':
        {
          borderLeft: `1px solid ${colors['darkBlue-100']}`,
          borderRadius: '0',
        },
      alignItems: 'center',
      borderLeft: `4px solid ${colors['green-700']}`,
      borderRadius: '6px',
      boxShadow: '0px 2px 11px 1px rgba(0, 0, 0, 0.15)',
      padding: '16px 12px',
    },
  },
};

export default MuiAlert;
