import React, { MouseEvent, ReactNode, useState } from 'react';

interface CheckBoxGroupOptionProps {
  addToSelectedOptions: (name: string) => void;
  checked: boolean;
  children: ReactNode;
  name: string;
  removeFromSelectedOptions: (name: string) => boolean;
}

const CheckBoxGroupOption: React.FC<CheckBoxGroupOptionProps> = ({
  addToSelectedOptions,
  checked,
  children,
  name,
  removeFromSelectedOptions,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    if (isChecked) {
      const success = removeFromSelectedOptions(name);
      if (success) setIsChecked(!isChecked);
    } else {
      addToSelectedOptions(name);
      setIsChecked(!isChecked);
    }
  };

  return (
    <span className={'checkbox'} onClick={handleClick}>
      <label className={`collection_check_boxes ${isChecked ? 'checked' : ''}`}>
        {children}
      </label>
    </span>
  );
};

export default CheckBoxGroupOption;
