import { render, screen } from '@testing-library/react';
import React from 'react';
import { beforeAll, describe, expect, it } from 'vitest';

import translations from '../../../translations';
import IfConditionText from './IfConditionText';

describe('IfConditionText', () => {
  beforeAll(() => {
    translations.init();
  });

  const setup = () => {
    const props = {
      i18nKey: 'messages.generic.if',
      values: { alert_type: 'Temperature' },
    };

    render(<IfConditionText {...props} />);
    return props;
  };

  it('renders the translation text correctly', () => {
    setup();
    const textElement = screen.getByText('Temperature');
    expect(textElement).toBeInTheDocument();
  });

  it('renders the Typography component with the correct variant', () => {
    setup();
    const typographyElement = screen.getByText('If');
    expect(typographyElement).toHaveClass('MuiTypography-root');
    expect(typographyElement).toHaveClass('MuiTypography-text-xs');
  });
});
