import { cleanup, render, screen } from '@testing-library/react';
import * as React from 'react';
import { Provider } from 'react-redux';

import redux from '../../redux';
import translations from '../../translations';
import Table from '../table';

translations.init();

function TestComponent() {
  return (
    <Provider store={redux.store}>
      <Table active={true} />
    </Provider>
  );
}

describe('table', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the table', async () => {
    render(<TestComponent />);

    await new Promise((resolve) => setTimeout(resolve, 100));

    redux.store.dispatch(
      redux.data.setSortModel([
        {
          field: 'createdAt',
          sort: 'asc',
        },
      ]),
    );

    const div = screen.getByTestId('container');

    expect(div).toBeDefined();
    expect(div.style.width).toStrictEqual('100%');
    expect(redux.store.getState().data.sortModel[0].sort).toStrictEqual('asc');
  });
});
