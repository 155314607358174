import { faker } from '@faker-js/faker';
import { cleanup, render, screen } from '@testing-library/react';
import * as React from 'react';
import { Provider } from 'react-redux';

import redux from '../../redux';
import translations from '../../translations';
import DownloadButton from '../download-button';

translations.init();

const id = faker.string.uuid();

type TestComponentProps = {
  active: boolean;
};

function TestComponent({ active }: Readonly<TestComponentProps>) {
  return (
    <Provider store={redux.store}>
      <DownloadButton active={active} id={id} />
    </Provider>
  );
}

describe('download button', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the active download icon', () => {
    render(<TestComponent active />);
    const svg = screen.getByTestId('download');

    expect(svg).toBeDefined();
    expect(svg.firstChild.nodeName.toLowerCase()).toBe('path');
  });

  test('should render the inactive download icon', () => {
    render(<TestComponent active={false} />);
    const svg = screen.getByTestId('download');

    expect(svg).toBeDefined();
    expect(svg.firstChild.nodeName.toLowerCase()).toBe('path');
  });
});
