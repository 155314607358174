/**
 * translate table column names to API fields
 */
const translateColumnName = (columnName: string): string => {
  switch (columnName) {
    case 'createdAt':
      return 'created_at';
    case 'status':
      return 'status';
    default:
      return columnName;
  }
};

export default translateColumnName;
