import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

const CustomIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 21">
      <path
        d="M20.4902552,3.5737724 C18.2231624,1.26934071 15.2090141,0 12.0028207,0 C8.79662721,0 5.78247888,1.26934071 3.51586623,3.5737724 C1.24877346,5.87771607 0,8.94150729 0,12.2005066 C0,15.4595059 1.24877346,18.5232972 3.51586623,20.8277288 L3.68582617,21 L5.38302501,19.2748484 L5.21306507,19.1025772 C3.39967892,17.2588367 2.40056413,14.8079989 2.40056413,12.2005066 C2.40056413,9.59350236 3.39967892,7.14266459 5.21306507,5.29892403 C7.02693133,3.45567149 9.43805794,2.44010132 12.0028207,2.44010132 C13.302006,2.44010132 14.5603817,2.70265622 15.723215,3.19897283 C15.6915275,3.21800562 15.6622406,3.24094258 15.6406355,3.27363993 C15.612789,3.31707374 12.8444584,7.57309846 11.421404,9.82677605 C10.8107005,9.95170923 10.2912184,10.3577421 10.0175541,10.9301899 C9.53504073,11.9379517 9.94889799,13.1570263 10.9408111,13.6474867 C11.2216771,13.7865725 11.5188669,13.8519672 11.8122159,13.8519672 C12.5539902,13.8519672 13.267918,13.4308057 13.6135992,12.7085357 C13.8877436,12.1360879 13.881022,11.4699403 13.6011163,10.9048128 C14.4672398,8.3758918 16.0525724,3.53473078 16.0688962,3.48544073 C16.0818592,3.44639911 16.080899,3.40686947 16.0746575,3.36831587 C17.0699314,3.84315958 17.9922282,4.48490623 18.7930564,5.29892403 C22.5369762,9.10450605 22.5369762,15.2965072 18.7930564,19.1025772 L18.6230964,19.2748484 L20.3202953,21 L20.4902552,20.8277288 C25.1699149,16.0705073 25.1699149,8.33050591 20.4902552,3.5737724"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default CustomIcon;
