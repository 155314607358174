import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux';

function WindowFunction() {
  const { t } = useTranslation();
  const windowFunction = useSelector(
    (state: RootState) => state.options.windowFunction,
  );

  let output: string;

  switch (windowFunction) {
    case 'max':
      output = t('window-function-display-maximum-value');
      break;
    case 'last':
      output = t('window-function-display-last-value');
      break;
    case 'mean':
      output = t('window-function-display-average-value');
      break;
    case 'min':
      output = t('window-function-display-minimum-value');
      break;
    case 'first':
    default:
      output = t('window-function-display-first-value');
      break;
  }

  return <span>{output}</span>;
}

export default WindowFunction;
