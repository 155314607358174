import React from 'react';
import PropTypes from 'prop-types';

const AppIcon = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46336 1.75003H1.5C0.947715 1.75003 0.5 2.19775 0.5 2.75003V14.5C0.5 15.0523 0.947715 15.5 1.5 15.5H13.25C13.8023 15.5 14.25 15.0523 14.25 14.5V6.53658C13.859 6.67477 13.4383 6.74997 13 6.74997C10.9289 6.74997 9.25 5.07103 9.25 2.99997C9.25 2.5617 9.32518 2.14099 9.46336 1.75003ZM10.8344 1.75003C10.6217 2.11774 10.5 2.54464 10.5 2.99997C10.5 4.38068 11.6193 5.49997 13 5.49997C14.1948 5.49997 15.1939 4.6618 15.4412 3.54137C15.4618 3.44796 15.4772 3.35259 15.4871 3.25558C15.4956 3.17153 15.5 3.08626 15.5 2.99997C15.5 2.82738 15.4825 2.65887 15.4492 2.49613C15.2161 1.35694 14.2081 0.5 13 0.5C12.0746 0.5 11.2667 1.00276 10.8344 1.75003H10.8344Z"
        fill="white"
      />
    </svg>
  );
};

AppIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default AppIcon;
